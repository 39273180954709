import React, { Component } from 'react'
import '../../styles/abouts/ImgMember.css'
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Image } from 'react-bootstrap'
class AddImgMemberAbout extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            description: '',
            alt_avatar: '',
            imgURL: '',
            selectedFile: null,
        }
    }
    validated() {
        if (!this.state.name || !this.state.description ||
            !this.state.alt_avatar || !this.state.selectedFile) {
            return false
        } else {
            alert('Thêm thành viên thành công')
            return true
        }
    }
    handleSelectedFile(e) {
        // Get user's selected file
        let file = e.target.files[0];
        // Check extension of file
        // To make sure it is an image
        if (file && file.name.match(/.(jpg|jpeg|png)$/gi)) {
            let url = URL.createObjectURL(file);
            this.setState({
                selectedFile: file, imgURL: url
            });
        }
    }
    handleAddMember() {
        // Package all the data
        const data = new FormData();
        data.append('name', this.state.name);
        data.append('description', this.state.description);
        data.append('alt_avatar', this.state.alt_avatar);
        if (this.state.selectedFile) {
            data.append("avatar", this.state.selectedFile, this.state.selectedFile.name);
        }
        if (this.validated()) {
            // Call request to server
            this.props.addMem(data);
            this.handleReset()
        } else {
            alert('Vui lòng nhập đầy đủ các trường!')
        }
    }
    handleReset() {
        this.setState({
            name: '',
            description: '',
            alt_avatar: '',
            imgURL: '',
            selectedFile: null,
        })
    }
    render() {
        return (
            <div>
                <div>
                    <div className="divMember">
                        <button
                            type="button"
                            className="btn btn-success"
                            data-toggle="modal"
                            data-target="#addMember"
                            onClick={() => this.handleReset()}>Thêm</button>
                        <div className="modal fade" id="addMember" role="dialog">
                            <div className="modal-dialog">
                                <div className="modal-content modalAddIntroMember">
                                    <div className="modal-header">
                                        <h4 className="center-modal-title">Thêm nội dung đội ngũ</h4>
                                    </div>
                                    <div className="modal-body">
                                        <div className="bodyAddMember">
                                            <div className="divIntroMember">
                                                <label className="labelIntroMember">Tên Thành Viên:</label>
                                                <div className="divIntrosMember">
                                                    <input className="inputIntroMember"
                                                        value={this.state.name}
                                                        onChange={(e) => {
                                                            this.setState({ name: e.target.value })
                                                        }} placeholder="Nhập tiêu đề" />
                                                </div>
                                            </div>
                                            <div className="divIntroMember">
                                                <label className="labelIntroMember">Thông Tin Thành Viên:</label>
                                                <div className="divIntrosMember">
                                                    <textarea className="textareaIntroMember"
                                                        value={this.state.description}
                                                        onChange={(e) => { this.setState({ description: e.target.value }) }}
                                                        placeholder="Mô tả thành viên" />
                                                </div>
                                            </div>
                                            <div className="divIntroMember">
                                                <div>
                                                    <label className="labelIntroMember"> Ảnh Thành Viên: </label>
                                                </div>
                                                <form className="divIntrosMember" >
                                                    <div className="displayIMG" >
                                                        {this.state.imgURL &&
                                                            <Image
                                                                style={{
                                                                    border: "1px solid #888",
                                                                    marginBottom: 5
                                                                }}
                                                                src={this.state.imgURL} alt="" fluid />
                                                        }
                                                        <div style={{ textAlign: "center" }}>
                                                            <label style={{
                                                                border: "1px solid #ccc",
                                                                display: "inline-block",
                                                                padding: "6px 12px",
                                                                cursor: "pointer",
                                                                borderRadius: 5,
                                                                background: "#008F30",
                                                                color: "white"
                                                            }}>
                                                                <input type="file"
                                                                    style={{ display: "none" }}
                                                                    onChange={(e) => this.handleSelectedFile(e)}
                                                                    onClick={(e) => e.target.value = ""}
                                                                />
                                                                <FontAwesomeIcon
                                                                    className="fa fa-plus-circle"
                                                                    icon={faPlusCircle} />
                                                                {this.state.selectedFile ? " Thay thế ảnh" : " Chọn ảnh"}
                                                            </label>
                                                        </div>
                                                    </div>
                                                </form>
                                                <div className="divAltIntroMember">
                                                    <label className="labelAltIntroMember">Alt:</label>
                                                    <div className="divAltIntrosMember">
                                                        <input type="text" className="inputALTMember"
                                                            value={this.state.alt_avatar}
                                                            onChange={e =>
                                                                this.setState({ alt_avatar: e.target.value })} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="btnIntroMember">
                                                <button type="button" className="btn btn-danger"
                                                    data-dismiss="modal"
                                                    style={{ marginRight: 5 }}
                                                    onClick={() => this.handleReset()}>Huỷ</button>
                                                <button type="button" className="btn btn-success"
                                                    data-dismiss="modal"
                                                    onClick={() => this.handleAddMember()}>Xác nhận</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default AddImgMemberAbout