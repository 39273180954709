import React from 'react'
import UsefulInfor from '../components/UsefulInforComponent/UsefulInforComponent'
import Search from '../components/UsefulInforComponent/searchUseful'
import * as actions from '../actions/UsefulInforActions'
import {connect} from 'react-redux'
import PaginationCommon from '../components/commons/PaginationCommon'

class UsefulInforContainer extends React.Component {
  componentDidMount(){
    this.props.onPaginate(1);
  }
    
  render() {
    let flagSearch = false;
    if (this.props.textSearch !== '') {
      flagSearch = true
    }
    return (
      <div>
        <Search {...this.props}/>
        <UsefulInfor {...this.props}/>
        {/* Separate table and pagination component */}
        <div style={{marginTop: 10}}></div>
        <PaginationCommon activePage={this.props.activePage} totalPage={this.props.totalPage} onPaginate={(data) => {
          if (flagSearch === false) {
            this.props.onPaginate(data)
          } else {
            this.props.searchUsefulInfor(this.props.textSearch, data)
          }
        }}/>   
        

      </div>
    );
  }
}

const mapStateToProps = (state) => {

  return {
    dataContainer: state.usefulInfor.listUsefullInfor,
    activePage: state.usefulInfor.activePageUsefulInfor,
    textSearch: state.usefulInfor.textSearch,
    totalPage: state.usefulInfor.totalPage,
    isLoadingData: state.usefulInfor.isFetching
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    
    onPaginate : (data) => {
      dispatch(actions.getListUsefulInfor(data))
    },
    addUsefulInfor: (dataAdd)=>{
        dispatch(actions.addUsefulInfor(dataAdd))
    },
    editUsefulInfor: (dataEdit)=>{
      dispatch(actions.editUsefulInfor(dataEdit))
    },
    deleteUsefulInfor: (dataDelete)=>{
      dispatch(actions.deleteUsefulInfor(dataDelete))
    },
    searchUsefulInfor: (inputValue, offset) => {
      dispatch(actions.searchUsefulInfor({
        offset: offset,
        text: inputValue
      }))
    },
    refresh: (data) => {
      dispatch(actions.refresh(data))
    }
  };
};


export default connect(mapStateToProps,mapDispatchToProps)(UsefulInforContainer);