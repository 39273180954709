import React from 'react';
import LoginContainer from '../containers/LoginContainer'
class LoginPage extends React.Component {
  render() {
    return (
      <div className="LoginPage">
        <LoginContainer />
      </div>
    );
  }
}

export default LoginPage;