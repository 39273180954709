import React, { Component } from 'react'
import '../../styles/abouts/FAQ.css'

class addTerm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            title: '',
            content: ''
        }
    }
    validated() {
        if (this.state.title === '' || this.state.content === '') {
            return false
        } else {
            alert('Thêm điều khoản thành công!')
            return true
        }
    }
    handleAddTerm() {
        if (this.validated()) {
            this.props.addTerm(this.state)
            this.resetAddTerm()
        } else {
            alert('Thiếu Điều Khoản Hoặc Nội Dung Điều Khoản!!')
        }
    }
    resetAddTerm() {
        this.setState({
            title: '',
            content: ''
        })
    }
    render() {
        return (
            <div>
                <div className="divBtnAddTerm">
                    <button type="button"
                        className="btn btn-success"
                        data-toggle="modal"
                        data-target="#addTerm"
                        onClick={() => this.resetAddTerm()}>Thêm</button>
                    <div className="modal fade" id="addTerm" role="dialog">
                        <div className="modal-dialog">
                            <div className="modal-content modalAddTerms">
                                <div className="modal-header">
                                    <h4 className="center-modal-title">Thêm nội dung điều khoản sử dụng</h4>
                                </div>
                                <div className="modal-body">
                                    <div>
                                        <div className="divAddTerms">
                                            <label className="labelAddTerm">Điều khoản:</label>
                                            <div className="divInputAddTerm">
                                                <input className="inputAddTerm"
                                                    value={this.state.title}
                                                    onChange={(e) => { this.setState({ title: e.target.value }); }}
                                                    placeholder="Nhập điều khoản" />
                                            </div>
                                        </div>
                                        <div className="divAddTerms">
                                            <label className="labelAddTerm">Nội dung điều khoản:</label>
                                            <div className="divInputAddTerm">
                                                <textarea className="textAddTerm"
                                                    value={this.state.content}
                                                    onChange={(e) => this.setState({ content: e.target.value })}
                                                    placeholder="Nhập nội dung điều khoản" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="btnActionAddTerm">
                                        <button type="button"
                                            className="btn btn-danger btnCloseAddTerm"
                                            style={{ marginRight: 5 }}
                                            data-dismiss="modal">Hủy</button>
                                        <button type="button"
                                            className="btn btn-success"
                                            data-dismiss="modal"
                                            onClick={() => this.handleAddTerm()}>Xác nhận</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default addTerm