import React, { Component } from 'react';
import LoadingModal from '../commons/LoadingModal';
import { TOUR_ITEMS_PER_PAGE } from '../../constant';
import { Table } from 'react-bootstrap';
import SearchTourSchedule from './SearchTourSchedule';
import AdditionOrEditionTour from './AdditionOrEditionTour'
import DeletionTour from './DeletionTour'
import CopyTour from './CopyTour'
import '../styles/TourSchedule.css'

export default class TourSchedule extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inputState: ''
        };
    }

    handleSearch(data){
        this.setState({
            inputState: data
        })
        this.props.search(data, 1);
    }

    handleDelete(data){
        let payload = {
            id: data,
            textSearch: this.props.textSearch,
            activePage: this.props.activePage,
            length: this.props.tourSchedule.length,
            totalPage: this.props.totalPage
        }
        this.props.deleteTour(payload);
    }

    handleCopy(data) {
        let payload = {
            id: data,
            textSearch: this.props.textSearch,
            activePage: this.props.activePage,
            length: this.props.tourSchedule.length,
            totalPage: this.props.totalPage
        }
        this.props.copyTour(payload);
    }

    render() {
        let listTour
        if (this.props.tourSchedule) {
            listTour = this.props.tourSchedule.map((item, key) => {
                return (
                    <tr key={item.id} className="wrap-table-data">
                        <td>{key + 1 + TOUR_ITEMS_PER_PAGE * (this.props.activePage - 1)}</td>
                        <td>{item.name}</td>
                        <td>{item.type}</td>
                        <td className="center-actions-td">
                            <div className="actions-parent-div">
                                <CopyTour {...this.props} tour={item} handleCopy={data => this.handleCopy(data)}/>
                                <AdditionOrEditionTour {...this.props} id={item.id} tour={item} />
                                <DeletionTour handleDelete={(data) => {this.handleDelete(data)}} {...this.props} tour={item} />
                            </div>
                        </td>
                    </tr>
                )
            });
        }

        return (
            <div className="wrap-tour-schedule">
                <SearchTourSchedule {...this.props} handleSearch={(data) => {
                    this.handleSearch(data)
                }}/>
                {this.props.isLoadingTour ? <LoadingModal /> :
                    <div>
                        <AdditionOrEditionTour {...this.props} />
                        {this.props.tourSchedule.length > 0 &&
                        <Table striped bordered hover className="fixed-table-tour">
                            <thead>
                                <tr className="table-header-row wrap-header-table">
                                    <th>STT</th>
                                    <th>Tên tour</th>
                                    <th>Thể loại</th>
                                    <th>Thao tác</th>
                                </tr>
                            </thead>
                            <tbody>
                                {listTour}
                            </tbody>
                        </Table>
                        }
                    </div>
                }
            </div>
        )
    }
}