import React, { Component } from "react"
import { connect } from 'react-redux';
import PriceRequestComponent from "../components/PriceRequestComponent/PriceRequestComponent"
import * as actions from "../actions/PriceRequestActions"
import PaginationCommon from "../components/commons/PaginationCommon"

class PriceRequestContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isSearching: false,
            textSearch: ""
        };
    }

    componentDidMount() {
        this.props.onPaginate(1, this.state.isSearching);
    }

    setSearchingStatus(status, textSearch) {
        this.setState({ isSearching: status, textSearch: textSearch });
    }

    render() {
        return (
            <div>
                <PriceRequestComponent setSearchingStatus={(s, txt) => this.setSearchingStatus(s, txt)} {...this.props} />
                <PaginationCommon
                    activePage={this.props.activePage}
                    totalPage={this.props.totalPages}
                    onPaginate={
                        (page) => this.props.onPaginate(page, this.state.isSearching, this.state.textSearch)
                    } />
            </div>
        )
    }
}

const mapStateToProps = (Store) => {
    return {
        listPriceRequest: Store.request.listRequest,
        activePage: Store.request.activePagesRequest,
        totalPages: Store.request.totalPages,
        isLoading: Store.request.isFetching,
    }
}

const mapDispacthToProps = (dispatch) => {
    return {
        onPaginate: (page, isSearching, textSearch) => {
            if (isSearching) { dispatch(actions.SearchRequest({ textSearch, page })); }
            else { dispatch(actions.GetListRequest(page)); }
        },
        deleteRequest: (id) => {
            dispatch(actions.DeleteRequest(id));
        },
        searchRequest: (textSearch, page) => {
            dispatch(actions.SearchRequest({ textSearch, page }));
        }
    }
}
export default connect(mapStateToProps, mapDispacthToProps)(PriceRequestContainer);