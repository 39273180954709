import React, { Component } from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import "../styles/PriceRequest.css"
import LoadingModal from '../commons/LoadingModal'

class ContactComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: "",
            name: "",
            email: "",
            differentContact: "",
            note: "",
            inputText: "",
            tel: ""
        }
    }

    handleDelete(dataDelete) {
        let payload = {
            dataAPI: dataDelete,
            textSearch: this.state.inputText,
            activePage: this.props.activePage,
            length: this.props.dataListContact.length,
            totalPage: this.props.totalPages
        }
        this.props.deleteContact(payload);
    }

    render() {
        let show;
        if (this.props.dataListContact) {
            show = this.props.dataListContact.map((item, key) => {
                let noteP = item.note.split("\n").map((n, idx) => { return (<p key={idx}>{n}</p>) });
                return (
                    <tr className="d-flex" key={key}>
                        <td className="col-sm-2">{item.name}</td>
                        <td className="col-sm-2">{item.email}</td>
                        <td className="col-sm-2">{item.tel}</td>
                        <td className="col-sm-2">{item.differentContact}</td>
                        <td className="col-sm-2">{noteP}</td>
                        <td className="col-sm-2" >
                            <button type="button" className="btn btn-success" data-toggle="modal" data-target={"#choose" + key} style={{ marginRight: 2 }}>Chi tiết</button>
                            <div className="modal fade" id={"choose" + key} role="dialog">
                                <div className="modal-dialog">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h4 className="center-modal-title">Thông tin yêu cầu</h4>
                                        </div>
                                        <div className="modal-body">
                                            <form className="form-horizontal">
                                                <div className="form-group top-content-border">

                                                    <div className="form-group row reset-row-margin-both parent-label-border">
                                                        <label className="col-sm-4 " style={{ fontWeight: "bold" }}>Tên người dùng</label>
                                                        <label className="col-sm-8 ">
                                                            {item.name}
                                                        </label>
                                                    </div>

                                                    <div className="form-group row reset-row-margin-both parent-label-border">
                                                        <label className="col-sm-4 " style={{ fontWeight: "bold" }}>Thư điện tử</label>
                                                        <label className="col-sm-8 ">
                                                            {item.email}
                                                        </label>
                                                    </div>

                                                    <div className="form-group row reset-row-margin-both parent-label-border">
                                                        <label className="col-sm-4 " style={{ fontWeight: "bold" }}>Số điện thoại</label>
                                                        <label className="col-sm-8 ">
                                                            {item.tel}
                                                        </label>
                                                    </div>

                                                    <div className="form-group row reset-row-margin-both parent-label-border">
                                                        <label className="col-sm-4 " style={{ fontWeight: "bold" }}>Liên hệ khác</label>
                                                        <label className="col-sm-8 ">
                                                            {item.differentContact}
                                                        </label>
                                                    </div>

                                                    <div className="form-group row reset-row-margin-both parent-label-border">
                                                        <label className="col-sm-4 " style={{ fontWeight: "bold" }}>Yêu cầu khác</label>
                                                        <label className="col-sm-8 ">
                                                            {noteP}
                                                        </label>
                                                    </div>

                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <button type="button"
                                className="btn btn-danger"
                                data-toggle="modal"
                                data-target={"#delete" + key}
                            >
                                Xóa
                            </button>
                            <div className="modal fade" id={"delete" + key} role="dialog">
                                <div className="modal-dialog">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h4 className="modal-title">Bạn có chắc chắn muốn xóa không ?</h4>
                                        </div>
                                        <div className="modal-footer">
                                            <button type="button"
                                                className="btn btn-danger"
                                                data-dismiss="modal"
                                            >Hủy</button>
                                            <button type="button"
                                                className="btn btn-success"
                                                data-dismiss="modal"
                                                onClick={() => {
                                                    this.handleDelete(item.id);
                                                    this.props.setSearchingStatus(false);
                                                    this.setState({ inputText: "" });
                                                }}
                                            >Xác nhận</button>


                                        </div>
                                    </div>
                                </div></div>
                        </td>
                    </tr>
                )
            })
        }

        return (
            <div>
                <div className="row clearfix reset-row-margin-both">
                    <input
                        className="col-10 col-sm-11"
                        type="text" value={this.state.inputText} placeholder="Tìm kiếm..." name="search"
                        onChange={(e) => {
                            if (!e.target.value) {
                                this.props.setSearchingStatus(false);
                                this.props.onPaginate(1, false);
                            }
                            this.setState({ inputText: e.target.value });
                        }}
                        onKeyPress={(e) => {
                            if (e.key === "Enter" && this.state.inputText) {
                                this.props.searchContacts(this.state.inputText, 1);
                                this.props.setSearchingStatus(true, this.state.inputText);
                            }

                        }} />
                    <button className="btn-search col-2 col-sm-1"
                        onClick={() => {
                            if (this.state.inputText) {
                                this.props.setSearchingStatus(true, this.state.inputText);
                                this.props.searchContacts(this.state.inputText, 1);
                            }
                        }}>
                        <FontAwesomeIcon className="icon-search" icon={faSearch} />
                    </button>
                </div>
                {(!this.props.isLoading && show) ?
                    <table className="table table-striped">
                        <tbody>
                            <tr className="table-dark text-dark d-flex">
                                <th className="name col-sm-2">Tên người dùng</th>
                                <th className="email col-sm-2">Thư điện tử</th>
                                <th className="col-sm-2">Số điện thoại</th>
                                <th className="col-sm-2">Liên hệ khác</th>
                                <th className="col-sm-2">Yêu cầu</th>
                                <th className="col-sm-2">Thao tác</th>
                            </tr>
                            {show}
                        </tbody>

                    </table>
                    : <LoadingModal />}
            </div>
        )
    }
}
export default ContactComponent;