import * as types from '../constant';
const DEFAULT_STATE = {
    listIntro: null,
    listFAQs: null,
    listTerm: null,
    listMember: [],
    dataFetched: false,
    isFetching: false,
    error: false,
    errorMessage: null,
    resIntro: null,
    resFAQ: null,
    resTerms: null,
    resMem: null
}

export default (state = DEFAULT_STATE, action) => {
    switch (action.type) {
        // Các trường họp của Giới Thiệu
        //Get giới thiệu
        case types.GET_TEXT_INTRO_REQUEST:
            return {
                ...state,
                isFetching: true
            }
        case types.GET_TEXT_INTRO_SUCCESS:
            return {
                ...state,
                isFetching: false,
                dataFetched: false,
                error: false,
                errorMessage: null,
                listIntro: action.payload
            }
        case types.GET_TEXT_INTRO_FAILURE:
            return {
                ...state,
                isFetching: false,
                error: true,
                errorMessage: action.payload.errorMessage
            }
        //Update giới thiệu
        case types.UPDATE_TEXT_INTRO_REQUEST:
            return {
                ...state,
                isFetching: true
            }
        case types.UPDATE_TEXT_INTRO_SUCCESS:
            return {
                ...state,
                isFetching: false,
                dataFetched: false,
                error: false,
                errorMessage: null,
                resIntro: action.payload
            }
        case types.UPDATE_TEXT_INTRO_FAILURE:
            return {
                ...state,
                isFetching: false,
                error: true,
                errorMessage: action.payload.errorMessage
            }
        //phần Member
        //Get Member
        case types.GET_MEM_INTRO_REQUEST:
            return {
                ...state,
                isFetching: true
            }
        case types.GET_MEM_INTRO_SUCCESS:
            return {
                ...state,
                isFetching: false,
                dataFetched: false,
                error: false,
                errorMessage: null,
                listMember: action.payload
            }
        case types.GET_MEM_INTRO_FAILURE:
            return {
                ...state,
                isFetching: false,
                error: true,
                errorMessage: action.payload.errorMessage
            }
        //ADD Member
        case types.ADD_MEM_INTRO_REQUEST:
            return {
                ...state,
                isFetching: true
            }
        case types.ADD_MEM_INTRO_SUCCESS:
            return {
                ...state,
                isFetching: false,
                dataFetched: false,
                error: false,
                errorMessage: null,
                resMem: action.payload
            }
        case types.ADD_MEM_INTRO_FAILURE:
            return {
                ...state,
                isFetching: false,
                error: true,
                errorMessage: action.payload.errorMessage
            }
        //UPDATE Member
        case types.UPDATE_MEM_INTRO_REQUEST:
            return {
                ...state,
                isFetching: true
            }
        case types.UPDATE_MEM_INTRO_SUCCESS:
            return {
                ...state,
                isFetching: false,
                dataFetched: false,
                error: false,
                errorMessage: null,
                resMem: action.payload
            }
        case types.UPDATE_MEM_INTRO_FAILURE:
            return {
                ...state,
                isFetching: false,
                error: true,
                errorMessage: action.payload.errorMessage
            }
        //DELETE Member
        case types.DELETE_MEM_INTRO_REQUEST:
            return {
                ...state,
                isFetching: true
            }
        case types.DELETE_MEM_INTRO_SUCCESS:
            return {
                ...state,
                isFetching: false,
                dataFetched: false,
                error: false,
                errorMessage: null,
                resMem: action.payload
            }
        case types.DELETE_MEM_INTRO_FAILURE:
            return {
                ...state,
                isFetching: false,
                error: true,
                errorMessage: action.payload.errorMessage
            }
        // Các trường họp của FAQs
        //Get câu hỏi thường gặp
        case types.GET_FAQ_REQUEST:
            return {
                ...state,
                isFetching: true
            }
        case types.GET_FAQ_SUCCESS:
            return {
                ...state,
                isFetching: false,
                dataFetched: false,
                error: false,
                errorMessage: null,
                listFAQs: action.payload
            }
        case types.GET_FAQ_FAILURE:
            return {
                ...state,
                isFetching: false,
                error: true,
                errorMessage: action.payload.errorMessage
            }
        //ADD câu hỏi thường gặp
        case types.ADD_FAQ_REQUEST:
            return {
                ...state,
                isFetching: true
            }
        case types.ADD_FAQ_SUCCESS:
            return {
                ...state,
                isFetching: false,
                dataFetched: false,
                error: false,
                errorMessage: null,
                resFAQ: action.payload
            }
        case types.ADD_FAQ_FAILURE:
            return {
                ...state,
                isFetching: false,
                error: true,
                errorMessage: action.payload.errorMessage
            }
        //Update câu hỏi thường gặp
        case types.UPDATE_FAQ_REQUEST:
            return {
                ...state,
                isFetching: true
            }
        case types.UPDATE_FAQ_SUCCESS:
            return {
                ...state,
                isFetching: false,
                dataFetched: false,
                error: false,
                errorMessage: null,
                resFAQ: action.payload
            }
        case types.UPDATE_FAQ_FAILURE:
            return {
                ...state,
                isFetching: false,
                error: true,
                errorMessage: action.payload.errorMessage
            }
        // DELETE câu hỏi thường gặp
        case types.DELETE_FAQ_REQUEST:
            return {
                ...state,
                isFetching: true
            }
        case types.DELETE_FAQ_SUCCESS:
            return {
                ...state,
                isFetching: false,
                dataFetched: false,
                error: false,
                errorMessage: null,
                resFAQ: action.payload
            }
        case types.DELETE_FAQ_FAILURE:
            return {
                ...state,
                isFetching: false,
                error: true,
                errorMessage: action.payload.errorMessage
            }
        //Các trường hợp của Terms
        //Get điều khoản
        case types.GET_TERMS_REQUEST:
            return {
                ...state,
                isFetching: true
            }
        case types.GET_TERMS_SUCCESS:
            return {
                ...state,
                isFetching: false,
                dataFetched: false,
                error: false,
                errorMessage: null,
                listTerm: action.payload
            }
        case types.GET_TERMS_FAILURE:
            return {
                ...state,
                isFetching: false,
                error: true,
                errorMessage: action.payload.errorMessage
            }
        //ADD điều khoản
        case types.ADD_TERMS_REQUEST:
            return {
                ...state,
                isFetching: true
            }
        case types.ADD_TERMS_SUCCESS:
            return {
                ...state,
                isFetching: false,
                dataFetched: false,
                error: false,
                errorMessage: null,
                resTerms: action.payload
            }
        case types.ADD_TERMS_FAILURE:
            return {
                ...state,
                isFetching: false,
                error: true,
                errorMessage: action.payload.errorMessage
            }
        //Update điều khoản
        case types.UPDATE_TERMS_REQUEST:
            return {
                ...state,
                isFetching: true
            }
        case types.UPDATE_TERMS_SUCCESS:
            return {
                ...state,
                isFetching: false,
                dataFetched: false,
                error: false,
                errorMessage: null,
                resTerms: action.payload
            }
        case types.UPDATE_TERMS_FAILURE:
            return {
                ...state,
                isFetching: false,
                error: true,
                errorMessage: action.payload.errorMessage
            }
        //DELETE điều khoản
        case types.DELETE_TERMS_REQUEST:
            return {
                ...state,
                isFetching: true
            }
        case types.DELETE_TERMS_SUCCESS:
            return {
                ...state,
                isFetching: false,
                dataFetched: false,
                error: false,
                errorMessage: null,
                resTerms: action.payload
            }
        case types.DELETE_TERMS_FAILURE:
            return {
                ...state,
                isFetching: false,
                error: true,
                errorMessage: action.payload.errorMessage
            }
        default:
            return state
    }
}