import React, { Component } from 'react'
import { Modal, Button } from 'react-bootstrap';

export default class CopyTour extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false
        };
    }

    openModal() {
        this.setState({ showModal: true });
    }

    closeModal() {
        this.setState({ showModal: false })
    }

    submit() {
        this.props.handleCopy(this.props.tour.id);
    }

    render() {
        return (
            <div>
                <Button
                    variant="warning"
                    onClick={() => this.openModal()}
                >Copy</Button>

                <Modal
                    show={this.state.showModal}
                    onHide={() => this.closeModal()}
                    size="lg"
                    aria-labelledby="example-custom-modal-styling-title">
                    <Modal.Header closeButton>
                        <Modal.Title id="example-custom-modal-styling-title" style={{textAlign:"center",width:"100%"}}>
                            Copy <span style={{ color: "red" }}> {this.props.tour.name.toLowerCase()}</span>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ margin: "0 auto" }}>
                        <h5>Bạn có chắc chắn muốn copy không ?</h5>
                    </Modal.Body>
                    <Modal.Footer>
                    <Button variant="danger" onClick={() => this.closeModal()}>Hủy</Button>
                        <Button variant="success" onClick={() => this.submit()}>Xác nhận</Button>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }
}