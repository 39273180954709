import React, { Component } from 'react';
import history from '../../history'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretLeft } from '@fortawesome/free-solid-svg-icons'
library.add(faCaretLeft)
class Menu extends Component {
  render() {
    const path = history.location.pathname;
    const homeClass = path.match('^/home') && "active";
    const scheduleClass = path.match('^/tour-schedule') && "active";
    const beautyClass = path.match('^/beauty') && "active";
    const infoClass = path.match('^/information') && "active";
    const usefulClass = path.match('^/useful-information') && "active";
    const aboutClass = path.match('^/about') && "active";
    const popularClass = path.match('^/popular-tour') && "active";
    const eventClass = path.match('^/event') && "active";
    const requestClass = path.match('^/request') && "active"; 
    const contactClass = path.match('^/contact') && "active"; 
    return (
    <div className="sidebar">
        <img src="/images/logo.jpg" width="200px" alt="logo-nuoc-my"/>
        <a className={homeClass} href="/home">Trang Chủ {homeClass==="active"&&<FontAwesomeIcon style={{float:"right"}} icon={faCaretLeft} />} </a>
        <a className={scheduleClass} href="/tour-schedule">Lịch Trình Tour {scheduleClass==="active"&&<FontAwesomeIcon style={{float:"right"}} icon={faCaretLeft} />} </a>
        <a className={beautyClass} href="/beauty" >Vẻ Đẹp Mỹ {beautyClass==="active"&&<FontAwesomeIcon style={{float:"right"}} icon={faCaretLeft} />} </a>
        <a className={infoClass} href="/information" >Thông Tin Nước Mỹ {infoClass==="active"&&<FontAwesomeIcon style={{float:"right"}} icon={faCaretLeft} />} </a>
        <a className={usefulClass} href="/useful-information">Thông Tin Hữu Ích{usefulClass==="active"&&<FontAwesomeIcon style={{float:"right"}} icon={faCaretLeft} />} </a>
        <a className={aboutClass} href="/about" >Về Chúng Tôi {aboutClass==="active"&&<FontAwesomeIcon style={{float:"right"}} icon={faCaretLeft} />} </a>
        <a className={popularClass} href="/popular-tour" >Tour Tiêu Biểu {popularClass==="active"&&<FontAwesomeIcon style={{float:"right"}} icon={faCaretLeft} />} </a>
        <a className={eventClass} href="/event" >Lịch Khởi Hành {eventClass==="active"&&<FontAwesomeIcon style={{float:"right"}} icon={faCaretLeft} />} </a>
        <a className={requestClass} href="/request">Yêu Cầu Báo Giá {requestClass==="active"&&<FontAwesomeIcon style={{float:"right"}} icon={faCaretLeft} />} </a>
        <a className={contactClass} href="/contact">Liên Hệ {contactClass==="active"&&<FontAwesomeIcon style={{float:"right"}} icon={faCaretLeft} />} </a>
        <a href="#root" onClick={() => {
          localStorage.clear()
          window.location.reload()
        }}>Đăng Xuất</a>
      </div>
    );
  }

}

export default Menu;