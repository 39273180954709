import * as types from "../../../../constant";

export default function addMemberIntro(data) {
  return new Promise((resolve, reject) => {
    const url = types.domain + `/add-new-member`
    fetch(url, {
      method: 'POST',
      body: data
    })
      .then((response) => response.json())
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
