import React from 'react';
import * as actions from '../actions/LoginPageActions';
import Login from '../components/Login'
import { connect } from 'react-redux';

class LoginContainer extends React.Component {
  render() {
    return (
      <div className="LoginContainer">
        <Login {...this.props} />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    login: (data) => dispatch(actions.login(data)),
  };
};

export default connect(null, mapDispatchToProps)(LoginContainer);