import { domain } from '../../constant'
export default function updateTopicAPI(data) {
    return new Promise((resolve, reject) => {
        const url = domain + '/update-tour-type'
        fetch(url, {
            method: "PUT",
            headers: { "Content-type": "application/json" },
            body: JSON.stringify(data)
        })
            .then((response) => response.json())
            .then((res) => {
                resolve(res);
            })
            .catch((error) => {
                reject(error);
            });
    });
}
