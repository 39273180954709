import {domain} from '../../constant'
export default function callApi(data){
    return new Promise((resolve,reject)=>{
        const url = domain+"/add-new-us-beauty"
        fetch(url,{
            method: "POST",
            body: JSON.stringify(data),
            headers:{"Content-type":"application/json"},
        })
        .then((response) => response.json())
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
    })
})
}