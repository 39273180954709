import {domain} from '../../constant'
export default function upLoadImageNew(data){
    return new Promise((resolve,rejects)=>{
        const url = domain + '/update-popular-events'
        fetch(url,{
            method : "PUT",
            headers:{"Content-type":"application/json"},
            body: JSON.stringify({event_id_arr: data})
        })
        .then((response) => response.json())
        .then((res) =>{
            resolve(res);
        })
        .catch((error) =>{
            rejects(error);
        });
    });
}