import React, { Component } from 'react'

class DeleteUsefulInfor extends Component {
    constructor(props) {
        super(props);
        this.state = {
            title: '',
            author: '',
            reference: '',
            images: [
                {
                    file: null,
                    imgUrl: '',
                    alt: '',
                    content: '',
                    isDeleted: false
                }
            ]
        };

    }
    deleteUseful(dataDelete) {
      let payload = {
        dataAPI: dataDelete._id,
        textSearch: this.props.textSearch,
        activePage: this.props.activePage,
        length: this.props.dataContainer.length,
        totalPage: this.props.totalPage
      }
      this.props.deleteUsefulInfor(payload)
    } 

    render(){
        return(
            <div  >
                <button type="button" className="btn btn-danger" data-toggle="modal"
                data-target={"#" + this.props.deleteData} >Xóa</button>
              <div className="modal fade" id={this.props.deleteData} role="dialog">
                <div className="modal-dialog modal-sm">
                  <div className="modal-content">
                    <div className="modal-header" style={{ color: "red" }}>
                      <h4 className="modal-title text-center"  >Bạn có chắc chắn muốn xóa không ?</h4>
                      <button type="button" className="close" data-dismiss="modal">×</button>
                    </div>
                    <div className="modal-footer">
                    <button type="button" className="btn btn-danger"
                        data-dismiss="modal" >Hủy</button>
                      <button type="button" className="btn btn-success" data-dismiss="modal"
                        onClick={() => this.deleteUseful(this.props.item)}  >Xác nhận</button>
                      
                    </div>
                  </div>
                </div>
              </div>
            </div>
            
        )
    }
    
}
export default DeleteUsefulInfor;