import React, { Component } from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import DeletionModal from './DeletionModal'
import LoadingModal from '../commons/LoadingModal'
import history from '../../history'
import "../styles/Event.css"

class EventComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchTxt: sessionStorage.getItem("event.textSearch") || ""
        };
    }

    handleDelete(evtId) {
        // let payload = {
        //     dataAPI: dataDelete,
        //     textSearch: this.state.searchTxt,
        //     activePage: this.props.activePage,
        //     length: this.props.events.length,
        //     totalPage: this.props.totalPages
        // }
        this.props.deleteEvent({ id: evtId, totalPages: this.props.totalPages, length: this.props.events.length });
    }

    // handleUpdate(data) {
    //     let payload = {
    //         dataAPI: data,
    //         textSearch: this.state.searchTxt,
    //         activePage: this.props.activePage
    //     }
    //     this.props.updateEvent(payload);
    // }

    navigateToAddForm() {
        // Reset id in storage
        window.sessionStorage.setItem("event.id", "");
        // Navigate to event form page
        history.push('/event-form');
    }

    navigateToEditForm(event) {
        // Convert time on database
        let tempDT = new Date(event.time);
        let time = tempDT.getDate().toString().padStart(2, "0") + "/"
        + (tempDT.getMonth() + 1).toString().padStart(2, "0") + "/"
        + tempDT.getFullYear()
        // Set event's value to storage
        window.sessionStorage.setItem("event.id", event.id);
        window.sessionStorage.setItem("event.name", event.name);
        window.sessionStorage.setItem("event.day", event.day || "");
        window.sessionStorage.setItem("event.location", event.location);
        window.sessionStorage.setItem("event.price", event.price || "");
        window.sessionStorage.setItem("event.time", time);
        window.sessionStorage.setItem("event.cvr_img", event.coverImg);
        window.sessionStorage.setItem("event.description", event.description);
        window.sessionStorage.setItem("event.topic", event.topic || "");
        // Navigate to event form page
        history.push('/event-form');
    }

    getFormatPrice = price => {
        let validPrice = ""

        price = price ? price.toString() : ""
        for (let i = 0; i < price.length; i++) {
            const char = price.charAt(i)
            if (char.match(/[0-9]/))
                validPrice += char
            if (i !== price.length - 1 && (price.length - i - 1) % 3 === 0)
                validPrice += "."
        }
        return validPrice
    }

    render() {
        let events, 
            // editModals, 
            delModals;
        if (this.props.events.length) {
            // Map event to generate each row in table
            events = this.props.events.map((event) => {
                let tempDT = new Date(event.time)
                return (
                    <tr key={event.id} className="wrap-table-data">
                        <td>{event.name}</td>
                        <td>{tempDT.getDate() + "/" + (tempDT.getMonth() + 1) + "/" + tempDT.getFullYear()}</td>
                        <td>{event.location}</td>
                        <td>{this.getFormatPrice(event.price) || 0}</td>
                        <td className="center-td">
                            <button
                                className="btn btn-primary"
                                data-toggle="modal" data-target={"#myModal" + event.id}
                                onClick={() => this.navigateToEditForm(event)}
                                >Sửa</button>
                            <button
                                className="btn btn-danger"
                                data-toggle="modal" data-target={"#delModal" + event.id}>Xóa</button>
                        </td>
                    </tr>
                );
            });
            // Map event to generate each edition modal
            // editModals = this.props.events.map(
            //     event => <AdditionOrEditionModal key={event.id} event={event} {...this.props} handleUpdate={(data) => {this.handleUpdate(data)}} />
            // );
            // Map event to generate each deletion modal
            delModals = this.props.events.map(
                event => <DeletionModal key={event.id} event={event}
                    handleDelete={(data) => { this.handleDelete(data) }} {...this.props} />
            );
        }

        return (
            <div>
                {/* Search Component */}
                <div className="row clearfix reset-row-margin-both" >
                    <input className="col-10 col-sm-11"
                        type="text"
                        value={this.state.searchTxt}
                        placeholder="Tìm kiếm..."
                        name="search"
                        onChange={(e) => {
                            // If user clear all text in search input
                            if (!e.target.value) {
                                this.props.setSearchingStatus(false);
                                this.props.onEventPaginate(1, false);    // Get all events on first page
                            }
                            // Change search text if detect any change in search input
                            this.setState({ searchTxt: e.target.value });
                        }}
                        onKeyPress={(e) => {
                            // If user hit enter and search input has value
                            if (e.key === "Enter" && this.state.searchTxt) {
                                this.props.setSearchingStatus(true, this.state.searchTxt);
                                this.props.onEventPaginate(1, true, this.state.searchTxt);
                            }

                        }}
                    />
                    <button className="btn-search col-2 col-sm-1"
                        onClick={() => {
                            // User press button search
                            if (this.state.searchTxt) {
                                this.props.setSearchingStatus(true, this.state.searchTxt);
                                this.props.onEventPaginate(1, true, this.state.searchTxt);
                            }
                        }}>
                        <FontAwesomeIcon className="icon-search" icon={faSearch} />
                    </button>
                </div>
                
                {/* Table */}
                {/* Only show table if we have records and we are not fetching data */}
                {events && !this.props.isLoadingData &&
                    <table className="table table-striped fixed-table-event">
                        <tbody>
                            <tr className="table-dark text-dark center-th wrap-header-table">
                                <th>Tên lịch khởi hành</th>
                                <th>Ngày khởi hành</th>
                                <th>Điểm đến</th>
                                <th>Giá tiền</th>
                                <th>Thao tác</th>
                            </tr>
                            {events}
                        </tbody>
                    </table>
                }
                {!this.props.isLoadingData &&
                    <button
                        type="button"
                        className="btn btn-success btn-block"
                        data-toggle="modal" data-target="#myModal"
                        style={{ marginBottom: 10 }}
                        onClick={() => this.navigateToAddForm()}
                    >Thêm</button>
                }
                {/* <AdditionOrEditionModal {...this.props} /> */}
                {/* {editModals} */}
                {delModals}
                {this.props.isLoadingData && <LoadingModal />}
            </div>
        );

    }
}
export default EventComponent;