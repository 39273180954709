import * as types from "../constant"
export function getListContact(payload){
    return ({
      type: types.GET_LIST_CONTACT_REQUEST,
      payload
    });
  }
export function deleteContact( payload){
  return ({
    type: types.DELETE_CONTACT_REQUEST,
    payload
  })
}
export function SearchContact(payload) {

  return ({
      type: types.SEARCH_CONTACT_REQUEST,
      payload
  })
}