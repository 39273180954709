import React from 'react';
import UsefulInforContainer from '../containers/UsefulInforContainer'

class UsefulInfomationPage extends React.Component {
  render() {
    return (
      <div className="UsefulInfomationPage">
        <div className="card">
          <div className="card-header"><h1>Thông Tin Hữu Ích</h1></div>
          <div className="card-body">
            <UsefulInforContainer />
          </div>
        </div>
      </div>
    );
  }
}

export default UsefulInfomationPage;