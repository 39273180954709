import * as types from '../constant';

export function getListUsefulInfor(payload){
    return({
        type:types.GET_USEFULINFOR_REQUEST,
        payload
    })
}
export function addUsefulInfor(payload){
    return({
        type:types.ADD_USEFULINFOR_REQUEST,
        payload
    })
}

export function editUsefulInfor(payload){
    return({
        type:types.EDIT_USEFULINFOR_REQUEST,
        payload
    })
}

export function deleteUsefulInfor(payload){
    return({
        type:types.DELETE_USEFULINFOR_REQUEST,
        payload
    })
}
export function searchUsefulInfor(payload){
    return({
        type:types.SEARCH_USEFULINFOR_REQUEST,
        payload
    })
}
export function refresh(payload){
    return({
        type:types.REFRESH_USEFULINFOR_REQUEST,
        payload
    })
}
