import React from 'react';
import AboutContainer from '../containers/AboutContainer'
class AboutPage extends React.Component {
  render() {
    return (
      <div className="AboutPage">
        <div className="card">
          <div className="card-header"><h1>Trang quản lý Page giới thiệu</h1></div>
          <div className="card-body">
            <AboutContainer />
          </div>
        </div>
      </div>
    );
  }
}

export default AboutPage;