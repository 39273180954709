import React from 'react';
import * as types from '../constant'
import { connect } from 'react-redux';
import NotifyModal from '../components/commons/NotifyModal';

class CommonContainer extends React.Component {
  render() {
    return (
      <div>
        <NotifyModal {...this.props} />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
      content: state.common.content,
      showModal: state.common.showModal
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    turnOffModal: () => dispatch({ type: types.TURN_OFF_NOTIFY_MODAL }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CommonContainer);