import React, { Component } from 'react'
import { Container, Button, Form, Row, Col, Dropdown, DropdownButton } from 'react-bootstrap'
import Editor from './Editor'
import history from '../history'
import { POST_TYPE_FLAG } from '../constant';

export default class AdditionOrEditionPost extends Component {

    constructor(props) {
        super(props);
        this.state = this.setUpState();
    }

    getDefaultState = (type) => {
        // 1 Type => n Post
        // 1 Post => n topic
        return {
            id: "",
            type,
            title: "",
            topic: "",
            author: "",
            description: "",
            allSrcImgArr: []
        };
    }

    checkPostType = () => {
        const type = sessionStorage.getItem('post.type')
        if (!type) {
            alert('Bạn vui lòng chọn chủ đề trước khi thêm hoặc sửa bài đăng !')
            history.push('/beauty')
        }
    }

    getAllSourceImages = (htmlText) => {
        return htmlText && htmlText.match(/(?<=src=")[^"]+(?=")/g)
            ? htmlText.match(/(?<=src=")[^"]+(?=")/g)
            : []
    }

    setUpState = () => {
        this.checkPostType()
        // Check id in session to know the form user want to open
        let id = sessionStorage.getItem('post.id');
        // Edit Form
        if (id) {
            return {
                id: id,
                type: sessionStorage.getItem('post.type'),
                title: sessionStorage.getItem('post.title') || "",
                topic: sessionStorage.getItem('post.topic') || "",
                author: sessionStorage.getItem('post.author') || "",
                description: sessionStorage.getItem('post.description') || "",
                allSrcImgArr: this.getAllSourceImages(sessionStorage.getItem('post.description'))
            }
        }
        // Add form
        return this.getDefaultState(sessionStorage.getItem('post.type'))
    }

    /**
     * Function use to add source image from child-editor component after it is uploaded to server
     * @param {String} url Source image url
     */
    addNewSourceImage = (url) => {
        // Push url to array in state
        this.setState(state => ({
            allSrcImgArr: state.allSrcImgArr.concat(url)
        }));
    }

    deleteUnnecessaryImage = () => {
        // Compare all source image with current source image
        // 1. Get all current source images
        const currSrcImgArr = window.tinymce.get('tiny').getContent().match(/(?<=src=")[^"]+(?=")/g) || []
        // 2. Filter all image in source array that is not in current source image array
        const unnecesaryImgArr = this.state.allSrcImgArr.filter(src => currSrcImgArr.indexOf(src) === -1)
        if (unnecesaryImgArr.length) {
            // If the array is not empty => Dispatch delete image action
            this.props.deleteUnnecesaryImage({ img_urls: unnecesaryImgArr });
        }
    }

    submitForm = () => {
        const { showNotifyModal } = this.props
        const { id, type, title, topic, author } = this.state
        const editor = window.tinymce.get('tiny')
        const description = editor.getContent()
        const short_description = editor.getContent({format: 'text'}).substring(0, 200)
        if (!description.trim() || !type || !title || (type === POST_TYPE_FLAG.USA_BEAUTY && !topic) || !author) {
            showNotifyModal("Yêu cầu nhập đầy đủ tiêu đề, chủ đề (nếu có), tác giả, nội dung")
        } else {
            // Dispatch delete unnecessary image first
            this.deleteUnnecessaryImage()
            // Then dispatch add/update post action
            let payload = {
                type,
                topic,
                author,
                description,
                title,
                short_description
            };
            if (id) {
                payload.id = id
                this.callUpdatePost(payload)
            } else {
                this.callAddPost(payload)
                this.setState(this.getDefaultState)
            }
            history.goBack();
        }
    }

    callAddPost = (post) => {
        const { addUSBeautyPost, addUSInfo, addUsefulInfo } = this.props
        switch (post.type) {
            case POST_TYPE_FLAG.USA_BEAUTY:
                addUSBeautyPost(post)
                break;
            case POST_TYPE_FLAG.USA_INFO:
                addUSInfo(post)
                break;
            case POST_TYPE_FLAG.USEFUL_INFO:
                addUsefulInfo(post)
                break;
            default:
                break;
        }
    }

    callUpdatePost = (post) => {
        post = {
            dataAPI: post,
            activePage: 1
        }
        const { updateUSBeautyPost, updateUSInfo, updateUsefulInfo } = this.props
        switch (post.dataAPI.type) {
            case POST_TYPE_FLAG.USA_BEAUTY:
                updateUSBeautyPost(post)
                break;
            case POST_TYPE_FLAG.USA_INFO:
                updateUSInfo(post)
                break;
            case POST_TYPE_FLAG.USEFUL_INFO:
                updateUsefulInfo(post)
                break;
            default:
                break;
        }
    }

    rejectForm = () => {
        const { id, allSrcImgArr } = this.state
        const { deleteUnnecesaryImage } = this.props
        // Add form => Dispatch delete all source image
        if (!id && allSrcImgArr.length) {
            deleteUnnecesaryImage({ img_urls: allSrcImgArr });
        }
        // Using router navigate to previous page
        history.goBack()
    }

    render = () => {
        let typeOptions
        if (this.props.topics) {
            typeOptions = this.props.topics.map((type, idx) => {
                return (
                    <Dropdown.Item
                        key={idx}
                        onClick={() => this.setState({ topic: type.name })}
                    >{type.name}</Dropdown.Item>
                )
            })
        }
        return (
            <div className="wrap-event-form">
                <Container className="form-container">
                    <Row>
                        <h1 className="header-form">
                            {
                                this.state.id
                                    ? "Cập nhật: "
                                    : "Tạo bài viết mới"
                            }
                            <span
                                style={{ color: "firebrick" }}>
                                {this.state.id 
                                    ? sessionStorage.getItem('post.title') || ""
                                    : ""}
                            </span>
                        </h1>
                    </Row>
                    <Row>
                        <Col>
                            <Row className="wrap-field">
                                <Col sm={4} className="header-col" >
                                    <Form.Label >Tiêu đề</Form.Label>
                                </Col>
                                <Col >
                                    <Form.Control
                                        value={this.state.title}
                                        onChange={(e) => this.setState({ title: e.target.value.trimStart() })} />
                                </Col>
                            </Row>
                        </Col>
                        <Col>
                            <Row className="wrap-field">
                                <Col sm={4} className="header-col" >
                                    <Form.Label>Tác giả</Form.Label>
                                </Col>
                                <Col>
                                    <Form.Control
                                        value={this.state.author}
                                        onChange={(e) => this.setState({ author: e.target.value.trimStart() })} />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    {this.state.type === POST_TYPE_FLAG.USA_BEAUTY &&
                        <Row>
                            <Col>
                                <Row className="wrap-field">
                                    <Col sm={2} className="header-col" >
                                        <Form.Label>Chủ đề</Form.Label>
                                    </Col>
                                    <Col>
                                        <DropdownButton
                                            title={this.state.topic}
                                            id="dropdown-menu-align-right"
                                            className="wrap-drop-down">
                                            {typeOptions}
                                        </DropdownButton>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    }
                    <Row>
                        <Col>
                            <Row >
                                <Col className="header-col-content">
                                    <Form.Label>Nội dung bài đăng</Form.Label>
                                </Col>
                            </Row>
                            <Row className="wrap-editor">
                                <Editor
                                    addSourceImageToParent={(url) => this.addNewSourceImage(url)}
                                    content={this.state.description} />
                            </Row>
                        </Col>
                    </Row>
                    <Row className="wrap-action-button">
                        <Button
                            variant="danger"
                            onClick={() => this.rejectForm()}>Hủy bỏ</Button>
                        <Button
                            variant="success"
                            onClick={() => this.submitForm()}>Xác nhận</Button>
                    </Row>
                </Container>
            </div>
        )
    }
}