import React, { Component } from 'react';
import {
    Container, Row, Col, Modal, Button, Form,
    DropdownButton, Dropdown, InputGroup, Image,
    ListGroup
} from 'react-bootstrap';
import { IMAGE_FLAG } from '../../constant';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import _ from 'lodash'
library.add(faPlusCircle)
library.add(faTimesCircle)

export default class AdditionOrEditionTour extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isUpdateComponent: false,
            showModal: false,
            text: null,
            coverImg: { file: null, alt: "", tempUrl: "" },
            type: "Chọn chủ đề",
            code: "",
            name: "",
            duration: 1,
            schedule: [{ title: "", content: "" }],
            specialPoint: "",
            includeServ: "",
            notIncludeServ: "",
            album: [],
            // attachEventId: "", eventKeyword: "",
            referenceEvtUrl: "",
            price: "",
        };
        this.callSearchEventAction = _.debounce(
            this.callSearchEventAction,
            3000,
            { leading: true, trailing: true }
        )
    }

    componentWillMount() {
        if (this.props.id) {
            this.setUpdatedState();
        } else {
            this.setDefaultState();
        }
    }

    setDefaultState() {
        this.setState({
            text: { modalTitle: "Thêm mới tour", openBtnName: "Thêm", openBtnVariant: "success" },
            coverImg: { file: null, alt: "", tempUrl: "" },
            type: "Chọn chủ đề",
            code: "",
            name: "",
            duration: 1,
            schedule: [{ title: "", content: "" }],
            specialPoint: "",
            includeServ: "",
            notIncludeServ: "",
            album: [],
            // attachEventId: "", eventKeyword: "", 
            referenceEvtUrl: "",
            price: ""
        });
    }

    setUpdatedState() {
        let tour = this.props.tour
        this.setState({
            isUpdateComponent: true,
            text: { modalTitle: "Sửa tour " + tour.name, openBtnName: "Sửa", openBtnVariant: "primary" },
            coverImg: {
                file: null, alt: tour.cover_img.alt, tempUrl: tour.cover_img.src
            },
            type: tour.type,
            code: tour.code,
            name: tour.name,
            duration: tour.duration,
            schedule: tour.schedule,
            specialPoint: tour.special_point,
            includeServ: tour.include_services,
            notIncludeServ: tour.not_include_services,
            // attachEventId: tour.reference_event_id,
            // eventKeyword: tour.reference_event_name,
            referenceEvtUrl: tour.reference_event_link,
            album: tour.slides.map(item => ({
                status: IMAGE_FLAG.IS_OLD, file: null, alt: item.alt, tempUrl: item.src
            })),
            price: tour.price
        });
    }

    openModal() {
        this.setState({ showModal: true });
    }

    closeModal() {
        this.setState({ showModal: false })
    }

    handleOpenButtonClick() {
        if (this.state.isUpdateComponent) {
            this.setUpdatedState();
        } else {
            this.setDefaultState();
        }
        this.openModal();
    }

    handleSelectAnImage(file, fieldName) {
        if (file && file.name.match(/^.*(jpg|jpeg|png)$/gi)) {
            this.setState({
                [fieldName]: {
                    file: file, alt: this.state[fieldName].alt, tempUrl: URL.createObjectURL(file)
                }
            });
        }
    }

    handleSelectAlbum(files) {
        let validItems = [];
        for (let i = 0; i < files.length; i++) {
            if (files[i].name.match(/^.*(jpg|jpeg|png)$/gi)) {
                validItems.push({
                    status: IMAGE_FLAG.IS_NEW, file: files[i], alt: "", tempUrl: URL.createObjectURL(files[i])
                });
            }
        }
        this.setState({ album: [...this.state.album, ...validItems] })
    }

    handleRemoveItemInAlbum(selectedIdx) {
        let tempAlbum = this.state.album;
        switch (tempAlbum[selectedIdx].status) {
            case IMAGE_FLAG.IS_OLD:
                tempAlbum[selectedIdx].status = IMAGE_FLAG.IS_DELETE;
                break;
            default:
                tempAlbum = tempAlbum.filter((item, idx) => idx !== selectedIdx);
                break;
        }
        this.setState({ album: tempAlbum });
    }

    validate() {
        return this.state.coverImg.tempUrl && (this.state.type !== "Chọn chủ đề")
            && this.state.code.trim() && this.state.name.trim()
            && this.state.schedule.filter(item => item.title.trim() && item.content.trim()).length
            && this.state.specialPoint.trim() && this.state.includeServ.trim()
            && this.state.notIncludeServ.trim()
            && this.state.album.filter(item => item.status !== IMAGE_FLAG.IS_DELETE).length
    }

    buildErrorMessage = () => {
        let errMsg = "Bạn cần "
        if (!this.state.coverImg.tempUrl) errMsg += "chọn ảnh đại diện, "
        if (this.state.type === "Chọn chủ đề") errMsg += "chọn chủ đề, "
        if (!this.state.code.trim()) errMsg += "nhập tour code, "
        if (!this.state.name.trim()) errMsg += "nhập tên tour, "
        if (!this.state.schedule.filter(item => item.title.trim() && item.content.trim()).length)
            errMsg += "nhập ít nhất 1 lịch khởi hành, "
        if (!this.state.specialPoint.trim()) errMsg += "nhập điểm nổi bật, "
        if (!this.state.includeServ.trim()) errMsg += "nhập nội dung dịch vụ, "
        if (!this.state.notIncludeServ.trim()) errMsg += "nhập ghi chú, "
        if (!this.state.album.filter(item => item.status !== IMAGE_FLAG.IS_DELETE).length)
            errMsg += "thêm ít nhất 1 ảnh vào album ảnh, "
        return errMsg.substring(0, errMsg.length - 2) + "."
    }

    submit() {
        if (this.validate()) {
            let dataAPI = new FormData();
            dataAPI.append("type", this.state.type);
            dataAPI.append("code", this.state.code.trim());
            dataAPI.append("name", this.state.name.trim());
            dataAPI.append("duration", this.state.duration);
            dataAPI.append("include_services", this.state.includeServ.trim());
            dataAPI.append("not_include_services", this.state.notIncludeServ.trim());
            dataAPI.append("special_point", this.state.specialPoint.trim());
            dataAPI.append("alt_cover_img", this.state.coverImg.alt.trim());
            // dataAPI.append("reference_event_id", this.state.attachEventId);
            dataAPI.append("reference_event_link", this.state.referenceEvtUrl);
            if (this.state.coverImg.file) {
                dataAPI.append("cover_img", this.state.coverImg.file, this.state.coverImg.file.name);
            }
            if (this.state.price) {
                dataAPI.append("price", this.state.price)
            }
            this.state.schedule.forEach(item => {
                if (item.title.trim() && item.content.trim()) {
                    dataAPI.append("schedule_title", item.title.trim());
                    dataAPI.append("schedule_content", item.content.trim());
                }
            });
            this.state.album.forEach(item => {
                if (item.file) {
                    dataAPI.append("slides", item.file, item.file.name);
                }
                if (item.alt.trim()) {
                    dataAPI.append("slide_alts", item.alt.trim() ? item.alt.trim() : null);
                } else {
                    dataAPI.append("slide_alts", null);
                }
                dataAPI.append("statuses", item.status);
            });

            if (this.state.isUpdateComponent) {
                dataAPI.append("id", this.props.id);

                let payload = {
                    dataAPI: dataAPI,
                    textSearch: this.props.textSearch,
                    activePage: this.props.activePage
                }
                // for(let data of dataAPI.entries()){
                //     console.log(data[0] + ": " + data[1])
                // }
                this.props.edit(payload);
            } else {
                this.props.addTour(dataAPI);
            }
            this.closeModal();
        } else {
            let errMsg = this.buildErrorMessage()
            alert(errMsg)
        }
    }

    searchEventByKeyword = keyword => {
        this.setState({
            eventKeyword: keyword
        })
        if (keyword) {
            this.props.preSearch()
            this.callSearchEventAction(keyword)
        }

    }

    callSearchEventAction = keyword => this.props.searchEvent(keyword)

    // attachEvent = evt => () => {
    //     this.setState({
    //         attachEventId: evt._id,
    //         eventKeyword: evt.name
    //     })
    // }

    // removeAttachEvent = () => {
    //     this.setState({
    //         attachEventId: "",
    //         eventKeyword: ""
    //     })
    // }

    _renderAttachedEvent = () => {
        // Case 1: User didn't search anything
        if (!this.state.eventKeyword)
            return null
        // Case 2: User is searching or web is waiting data from server
        if (this.props.isSearchingEvent)
            return (
                <ListGroup.Item disabled>
                    Loading ... (có delay để giảm tải cho server)
                </ListGroup.Item>
            )
        // Case 3: Server can't found any event
        if (this.props.attachedEvents.length === 0)
            return <ListGroup.Item disabled>Không tìm thấy kết quả nào</ListGroup.Item>
        // Case 4: Otherwise map each event to list
        return (
            this.props.attachedEvents.map((item, idx) =>
                <ListGroup.Item key={idx} action
                    onClick={this.attachEvent(item)}>
                    {item.name}
                </ListGroup.Item>
            )
        )
    }

    setPrice = price => {
        this.setState({ price: price.replace(/[^0-9]*/g, "").replace(/^0/, "") })
    }

    render() {
        let typeOptions
        if (this.props.topics) {
            typeOptions = this.props.topics.map((topic, idx) => {
                return (
                    <Dropdown.Item
                        key={idx}
                        onClick={() => {
                            this.setState({ type: topic.name });
                        }}>{topic.name}</Dropdown.Item>
                )
            })
        }

        let scheduleItems = this.state.schedule.map((item, idx) => {
            return (
                <div key={idx}
                    style={{ border: "1px solid #888", position: "relative", padding: 10, marginBottom: 10 }}>
                    {idx !== 0 &&
                        <button className="btn-close-description"
                            onClick={() => {
                                this.setState({ schedule: this.state.schedule.filter((item, currIdx) => currIdx !== idx) })
                            }}>
                            <FontAwesomeIcon icon={faTimesCircle} className="larger-icon" />
                        </button>
                    }
                    <Form.Control
                        type="text"
                        placeholder={"Tiêu đề " + (idx + 1)}
                        value={item.title}
                        onChange={(e) => {
                            let tempSchedule = this.state.schedule;
                            tempSchedule[idx].title = e.target.value;
                            this.setState({ schedule: tempSchedule });
                        }}
                    />
                    <Form.Control
                        as="textarea" rows="3"
                        placeholder={"Nội dung " + (idx + 1)}
                        style={{ marginTop: 10 }}
                        value={item.content}
                        onChange={(e) => {
                            let tempSchedule = this.state.schedule;
                            tempSchedule[idx].content = e.target.value;
                            this.setState({ schedule: tempSchedule });
                        }}
                    />
                </div>
            );
        });

        let albumItems = this.state.album.map((item, idx) => {
            return (
                <div key={idx}>
                    {item.status !== IMAGE_FLAG.IS_DELETE &&
                        <div className="wrap-modal-album-item">
                            <button className="btn-close-description"
                                onClick={() => { this.handleRemoveItemInAlbum(idx) }}>
                                <FontAwesomeIcon icon={faTimesCircle} className="larger-icon" />
                            </button>
                            <Image
                                src={item.tempUrl} fluid />
                            <Form.Control
                                value={item.alt}
                                type="text"
                                placeholder="Nhập mô tả về ảnh"
                                style={{ margin: "10px auto" }}
                                onChange={(e) => {
                                    let tempAlbum = this.state.album;
                                    tempAlbum[idx].alt = e.target.value;
                                    this.setState({ album: tempAlbum });
                                }}
                            />
                        </div>
                    }
                </div>
            );
        });

        return (
            <div>
                <Button
                    block={!this.state.isUpdateComponent}
                    variant={this.state.text.openBtnVariant}
                    onClick={() => this.handleOpenButtonClick()}
                    className={!this.state.isUpdateComponent && "open-modal-btn"}
                >{this.state.text.openBtnName}
                </Button>

                <Modal
                    show={this.state.showModal}
                    onHide={() => this.closeModal()}
                    size="lg"
                    aria-labelledby="example-custom-modal-styling-title">
                    <Modal.Header closeButton>
                        <Modal.Title id="example-custom-modal-styling-title"
                            style={{ width: "100%", textAlign: "center" }}>
                            {this.state.text.modalTitle}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Container style={{ border: "none" }}>

                            <Form.Group as={Row} controlId="formHorizontalEmail"
                                style={{ border: "1px solid #888", padding: "10px 0px" }}>
                                <Form.Label column sm={4}>
                                    Ảnh đại diện (*)
                                </Form.Label>
                                <Col sm={8} style={{ textAlign: "center" }}>
                                    {this.state.coverImg.tempUrl &&
                                        <Image style={{ width: "60%", border: "1px solid #888" }}
                                            src={this.state.coverImg.tempUrl} fluid />
                                    }
                                    {this.state.coverImg.tempUrl &&
                                        <Form.Control
                                            value={this.state.coverImg.alt}
                                            type="text"
                                            placeholder="Nhập mô tả về ảnh"
                                            style={{ margin: "10px auto", width: "60%" }}
                                            onChange={(e) => this.setState({ coverImg: { ...this.state.coverImg, alt: e.target.value } })}
                                        />
                                    }
                                    <div style={{ textAlign: "center" }}>
                                        <label className="wrap-choose-file-btn">
                                            <input type="file"
                                                onChange={(e) => this.handleSelectAnImage(e.target.files[0], "coverImg")}
                                            />
                                            <FontAwesomeIcon
                                                className="fa fa-plus-circle"
                                                icon={faPlusCircle} />
                                            {this.state.coverImg.file ? " Thay thế ảnh" : " Chọn ảnh"}
                                        </label>
                                    </div>
                                </Col>
                            </Form.Group>

                            <Form.Group as={Row} >
                                <Form.Label column sm={4}>
                                    Chủ đề tour (*)
                                </Form.Label>
                                <Col sm={8} style={{ textAlign: "center" }}>
                                    <DropdownButton
                                        title={this.state.type}
                                        id="dropdown-menu-align-right">
                                        {typeOptions}
                                    </DropdownButton>
                                </Col>
                            </Form.Group>

                            <Form.Group as={Row} >
                                <Form.Label column sm={4}>
                                    Tour code (*)
                                </Form.Label>
                                <Col sm={8} >
                                    <Form.Control
                                        type="text"
                                        name="firstName"
                                        placeholder="Nhập tour code"
                                        value={this.state.code}
                                        onChange={(e) => this.setState({ code: e.target.value })}
                                    // isValid={false}
                                    />
                                </Col>
                            </Form.Group>

                            <Form.Group as={Row} >
                                <Form.Label column sm={4}>
                                    Tên tour (*)
                                </Form.Label>
                                <Col sm={8} >
                                    <Form.Control
                                        type="text"
                                        placeholder="Nhập tên tour"
                                        value={this.state.name}
                                        onChange={(e) => this.setState({ name: e.target.value })}
                                    />
                                </Col>
                            </Form.Group>

                            <Form.Group as={Row} >
                                <Form.Label column sm={4}>
                                    Thời lượng
                                </Form.Label>
                                <Col sm={8} style={{ textAlign: "center" }}>
                                    <InputGroup className="mb-3">
                                        <Form.Control
                                            type="number" min={1} max={31} value={this.state.duration}
                                            onChange={(e) => {
                                                let validNum = e.target.value < 1 ? 1 : (e.target.value > 31 ? 31 : e.target.value)
                                                this.setState({ duration: validNum })
                                            }}
                                        />
                                        <InputGroup.Append>
                                            <InputGroup.Text id="basic-addon2">ngày</InputGroup.Text>
                                        </InputGroup.Append>
                                    </InputGroup>
                                </Col>
                            </Form.Group>

                            <Form.Group as={Row} style={{ border: "1px solid #888", padding: "10px 0px" }}>
                                <Form.Label column sm={4}>
                                    Lịch trình (*)
                                </Form.Label>
                                <Col sm={8} style={{ textAlign: "center" }}>
                                    {scheduleItems}
                                    <Button variant="success"
                                        onClick={() => this.setState({ schedule: [...this.state.schedule, { title: "", content: "" }] })}
                                    >Thêm lịch trình</Button>
                                </Col>
                            </Form.Group>

                            <Form.Group as={Row} >
                                <Form.Label column sm={4}>
                                    Chi phí
                                </Form.Label>
                                <Col sm={8} >
                                    <Form.Control
                                        placeholder="Nhập chi phí"
                                        value={this.state.price}
                                        onChange={(e) => this.setPrice(e.target.value)}
                                    />
                                </Col>
                            </Form.Group>

                            {/* <Form.Group as={Row} >
                                <Form.Label column sm={4}>
                                    Gán lịch khởi hành
                                </Form.Label>
                                <Col sm={8} >
                                    <div className="wrap-attach-event-input">
                                        {
                                            this.state.attachEventId
                                                ?
                                                <>
                                                    <Form.Control
                                                        className="attach-event-input"
                                                        value={this.state.eventKeyword}
                                                        disabled
                                                    />

                                                    <button
                                                        type="button"
                                                        className="close-btn"
                                                        aria-label="Close"
                                                        onClick={this.removeAttachEvent}>
                                                        <span aria-hidden="true">&times;</span>
                                                    </button>
                                                </>
                                                : <>
                                                    <Form.Control
                                                        className="attach-event-input"
                                                        placeholder="Tìm kiếm lịch khởi hành dựa theo tên"
                                                        value={this.state.eventKeyword}
                                                        onChange={(e) => this.searchEventByKeyword(e.target.value)}
                                                    />
                                                    <ListGroup className="wrap-attached-event-result">
                                                        {
                                                            this._renderAttachedEvent()
                                                        }
                                                    </ListGroup>
                                                </>
                                        }
                                    </div>
                                </Col>
                            </Form.Group> */}


                            <Form.Group as={Row} >
                                <Form.Label column sm={4}>
                                    Link lịch khởi hành
                                </Form.Label>
                                <Col sm={8} >
                                    <Form.Control
                                        placeholder="Nhập link lịch khởi hành"
                                        value={this.state.referenceEvtUrl}
                                        onChange={(e) => this.setState({ referenceEvtUrl: e.target.value })}
                                    />
                                </Col>
                            </Form.Group>

                            <Form.Group as={Row} >
                                <Form.Label column sm={4}>
                                    Điểm nổi bật (*)
                                </Form.Label>
                                <Col sm={8} >
                                    <Form.Control
                                        as="textarea" rows="3"
                                        placeholder="Nhập nổi bật"
                                        value={this.state.specialPoint}
                                        onChange={(e) => this.setState({ specialPoint: e.target.value })}
                                    />
                                </Col>
                            </Form.Group>

                            <Form.Group as={Row} >
                                <Form.Label column sm={4}>
                                    Nội dung dịch vụ (*)
                                </Form.Label>
                                <Col sm={8} >
                                    <Form.Control
                                        as="textarea" rows="3"
                                        placeholder="Nhập nội dung dịch vụ"
                                        value={this.state.includeServ}
                                        onChange={(e) => this.setState({ includeServ: e.target.value })}
                                    />
                                </Col>
                            </Form.Group>

                            <Form.Group as={Row} >
                                <Form.Label column sm={4}>
                                    Ghi chú (*)
                                </Form.Label>
                                <Col sm={8} >
                                    <Form.Control
                                        as="textarea" rows="3"
                                        placeholder="Nhập ghi chú"
                                        value={this.state.notIncludeServ}
                                        onChange={(e) => this.setState({ notIncludeServ: e.target.value })}
                                    />
                                </Col>
                            </Form.Group>

                            {/* <Form.Group as={Row} style={{ border: "1px solid #888", padding: "10px 0px" }}>
                                <Form.Label column sm={4}>
                                    Ảnh bản đồ
                                </Form.Label>
                                <Col sm={8} style={{ textAlign: "center" }}>
                                    {this.state.lctImg.tempUrl &&
                                        <Image style={{ width: "60%", border: "1px solid #888" }}
                                            src={this.state.lctImg.tempUrl} fluid />
                                    }
                                    {this.state.lctImg.tempUrl &&
                                        <Form.Control
                                            value={this.state.lctImg.alt}
                                            type="text"
                                            placeholder="Nhập mô tả về ảnh"
                                            style={{ margin: "10px auto", width: "60%" }}
                                            onChange={(e) => this.setState({ lctImg: { ...this.state.lctImg, alt: e.target.value } })}
                                        />
                                    }
                                    <div style={{ textAlign: "center" }}>
                                        <label style={{
                                            border: "1px solid #ccc",
                                            display: "inline-block",
                                            padding: "6px 12px",
                                            cursor: "pointer",
                                            borderRadius: 5,
                                            background: "#008F30",
                                            color: "white"
                                        }}>
                                            <input type="file"
                                                style={{ display: "none" }}
                                                onChange={(e) => this.handleSelectAnImage(e.target.files[0], "lctImg")}
                                            />
                                            <FontAwesomeIcon
                                                className="fa fa-plus-circle"
                                                icon={faPlusCircle} />
                                            {this.state.lctImg.file ? " Thay thế ảnh" : " Chọn ảnh"}
                                        </label>
                                    </div>
                                </Col>

                            </Form.Group> */}

                            <Form.Group as={Row} controlId="formHorizontalEmail">
                                <Form.Label column sm={4}>
                                    Album ảnh (*)
                                </Form.Label>
                                <Col sm={8}>
                                    <div className={this.state.album.length > 0 ? "btn-select-album" : ""}>
                                        <label className="wrap-choose-file-btn">
                                            <input type="file"
                                                multiple
                                                onChange={(e) => this.handleSelectAlbum(e.target.files)}
                                                onClick={(e) => e.target.value = ""}
                                            />
                                            <FontAwesomeIcon
                                                className="fa fa-plus-circle"
                                                icon={faPlusCircle} />
                                            {this.state.album.filter(item => item.status !== IMAGE_FLAG.IS_DELETE).length > 0
                                                ? " Thêm ảnh"
                                                : " Chọn ảnh"}
                                        </label>
                                    </div>
                                </Col>
                            </Form.Group>
                            <Col sm={12} className="wrap-modal-album">

                                {albumItems}
                            </Col>

                        </Container>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="danger" onClick={() => this.closeModal()}>Hủy</Button>
                        <Button variant="success" onClick={() => this.submit()}>Xác nhận</Button>
                    </Modal.Footer>
                </Modal>

            </div>
        );
    }
}