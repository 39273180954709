import * as types from '../constant';

export function getListUsaInfor(payload){
    return({
        type:types.GET_USAINFOR_REQUEST,
        payload
        
    })
}
export function addUsaInfor(payload){
    return({
        type:types.ADD_USAINFOR_REQUEST,
        payload
    })
}

export function editUsaInfor(payload){
    return({
        type:types.EDIT_USAINFOR_REQUEST,
        payload
    })
}

export function deleteUsaInfor(payload){
    return({
        type:types.DELETE_USAINFOR_REQUEST,
        payload
    })
}
export function searchUsaInfor(payload){
    return({
        type:types.SEARCH_USAINFOR_REQUEST,
        payload
    })
}

export function refresh(payload){
    return({
        type:types.REFRESH_USAINFOR_REQUEST,
        payload
    })
}