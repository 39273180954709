import React, { Component } from 'react'
import '../../styles/abouts/ImgMember.css'
// import * as types from '../../../constant';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Image } from 'react-bootstrap'
import LoadingModal from '../../commons/LoadingModal'

class ImgMember extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: '',
            name: '',
            description: '',
            avatar: {
                src: '',
                alt: '',
            },
            selectedFile: null,
        }
    }
    validated() {
        if (this.state.name === '' || this.state.description === '' || this.state.avatar.alt === '') {
            return false
        } else {
            alert('Cập nhật thành viên thành công')
            return true
        }
    }
    handleSelectedFile(e) {
        // Get user's selected file
        let file = e.target.files[0];
        // Check extension of file
        // To make sure it is an image
        if (file && file.name.match(/.(jpg|jpeg|png)$/gi)) {
            let url = URL.createObjectURL(file);
            this.setState({
                selectedFile: file, avatar: { ...this.state.avatar, src: url }
            });
        }
    }
    handleUpdateMember() {
        // Package all the data
        const data = new FormData();
        if (this.state.selectedFile) {
            data.append("avatar", this.state.selectedFile, this.state.selectedFile.name);
        }
        data.append('id', this.state.id)
        data.append('alt_avatar', this.state.avatar.alt);
        data.append('name', this.state.name);
        data.append('description', this.state.description);
        if (this.validated()) {
            // Call request to server
            this.props.updateMem(data);
            this.handleReset()
        } else {
            alert('Vui lòng nhập đầy đủ các trường!')
        }
    }
    handleDelete(id) {
        this.props.deleteMem(id);
        alert('Xoá thành công!');
    }
    handleReset() {
        this.setState({
            id: '',
            name: '',
            description: '',
            avatar: {
                src: '',
                alt: '',
            },
            selectedFile: null,
        })
    }
    render() {
        let listMember
        if (this.props.members) {
            listMember = this.props.members.map((item, key) => {
                let textDes = item.description.split("\n").map((desChild, idx) => {
                    return (
                        <p className="pInfoMember" key={idx}>{desChild}</p>
                    )
                })
                return (
                    <tr key={key}>
                        <td className="sttKey">{key + 1}</td>
                        <td className="tdImgIntroMember">
                            <img className="imgMemberTD"
                                src={item.avatar.src}
                                alt={item.avatar.alt} />
                        </td>
                        <td className="tdNameMember">{item.name}</td>
                        <td className="tdIntroMember">
                            {/* <textarea value={item.description} disabled style={{ resize: "none", width: "100%", height: 150 }} /> */}
                            {textDes}
                        </td>
                        <td className="btnActionMember">
                            <button type="button"
                                className="btn btn-primary btnUpdateImage"
                                data-toggle="modal"
                                data-target={"#updateMember" + key}
                                onClick={() => this.setState({
                                    id: item._id,
                                    name: item.name,
                                    description: item.description,
                                    avatar: {
                                        src: '',
                                        alt: item.avatar.alt,
                                    },
                                    selectedFile: null
                                })}>Sửa</button>
                            <div className="modal fade" id={'updateMember' + key} role="dialog">
                                <div className="modal-dialog">
                                    <div className="modal-content modalIntroMember">
                                        <div className="modal-header">
                                            <h4 className="center-modal-title">Sửa nội dung đội ngũ</h4>
                                        </div>
                                        <div className="modal-body">
                                            <div className="bodyAddMember">
                                                <div className="divIntroMember">
                                                    <label className="labelIntroMember">Tên Thành Viên:</label>
                                                    <div className="divIntrosMember">
                                                        <input className="inputIntroMember"
                                                            value={this.state.name}
                                                            onChange={(e) => {
                                                                this.setState({ name: e.target.value })
                                                            }} placeholder="Nhập tiêu đề" />
                                                    </div>
                                                </div>
                                                <div className="divIntroMember">
                                                    <label className="labelIntroMember">Thông Tin Thành Viên:</label>
                                                    <div className="divIntrosMember">
                                                        <textarea className="textareaIntroMember"
                                                            value={this.state.description}
                                                            onChange={(e) => { this.setState({ description: e.target.value }) }}
                                                            placeholder="Mô tả thành viên" />
                                                    </div>
                                                </div>
                                                <div className="divIntroMember">
                                                    <div>
                                                        <label className="labelIntroMember"> Ảnh Thành Viên: </label>
                                                    </div>
                                                    <form className="divIntrosMember" >
                                                        <div className="displayIMG" >
                                                            {this.state.avatar.src
                                                                ? <img className="imgIntroUrl" src={this.state.avatar.src} alt="" />
                                                                : <Image style={{ width: "60%", border: "1px solid #888" }}
                                                                    src={item.avatar.src} fluid />
                                                            }
                                                            <div style={{ textAlign: "center" }}>
                                                                <label style={{
                                                                    border: "1px solid #ccc",
                                                                    display: "inline-block",
                                                                    padding: "6px 12px",
                                                                    cursor: "pointer",
                                                                    borderRadius: 5,
                                                                    background: "#008F30",
                                                                    color: "white"
                                                                }}>
                                                                    <input type="file"
                                                                        style={{ display: "none" }}
                                                                        onChange={(e) => this.handleSelectedFile(e)}
                                                                        onClick={(e) => e.target.value = ""}
                                                                    />
                                                                    <FontAwesomeIcon
                                                                        className="fa fa-plus-circle"
                                                                        icon={faPlusCircle} />
                                                                    {this.state.selectedFile ? " Thay thế ảnh" : " Thay thế ảnh"}
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </form>
                                                    <div className="divAltIntroMember">
                                                        <label className="labelAltIntroMember">Alt:</label>
                                                        <div className="divAltIntrosMember">
                                                            <input type="text" className="inputALTMember"
                                                                value={this.state.avatar.alt}
                                                                onChange={e =>
                                                                    this.setState({
                                                                        avatar: {
                                                                            ...this.state.avatar,
                                                                            alt: e.target.value
                                                                        }
                                                                    })} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="btnIntroMember modal-footer">
                                                    <button type="button" className="btn btn-danger" data-dismiss="modal"
                                                    >Hủy</button>
                                                    <button type="button" className="btn btn-success"
                                                        data-dismiss="modal"
                                                        onClick={() => this.handleUpdateMember()}>Xác nhận</button>


                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <button type="button"
                                className="btn btn-danger"
                                data-toggle="modal"
                                data-target={"#deleteMember" + key}>Xóa</button>
                            <div className="modal fade" id={'deleteMember' + key} role="dialog">
                                <div className="modal-dialog">
                                    <div className="modal-content">
                                        <div className="modal-body">
                                            <div className="divDelMember">
                                                <h5>Bạn Có Chắc Chắn Xóa Thành Viên</h5>
                                                <div className="divActionsDelMember">
                                                    <button type="button"
                                                        className="btn btn-danger"
                                                        style={{ marginRight: 5 }}
                                                        data-dismiss="modal">Huỷ</button>
                                                    <button type="button" className="btn btn-success"
                                                        data-dismiss="modal"
                                                        onClick={() => this.handleDelete(item._id)}>Xác nhận</button>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </td>
                    </tr>
                )
            })
        }
        return (
            <div>
                <h3>2. Quản lý nội dung đội ngũ</h3>
                <div className="imgMember">
                    {(!this.props.isLoading && listMember) ?
                        <table className="table table-striped table-hover">
                            <thead>
                                <tr className="table-dark text-dark">
                                    <th className="sttMember">STT</th>
                                    <th className="imgMemberTH">Ảnh</th>
                                    <th className="nameMember">Tiêu đề</th>
                                    <th>Mô tả</th>
                                    <th className="actionMember">Action</th>
                                </tr>
                            </thead>

                            <tbody>
                                {listMember}
                            </tbody>

                        </table>
                        : <LoadingModal />}
                </div>
            </div>
        )
    }
}
export default ImgMember;