import React, { Component } from "react"
import "../../components/styles/Event.css"
class Table extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchTxt: "",
            selectedDataIds: []
        };
    }
    
    handleDisable(data) {
        let tempIdArr = this.state.selectedDataIds;
        tempIdArr.unshift(data.id);
        tempIdArr = tempIdArr.slice(0, 4);
        this.setState({ selectedDataIds: tempIdArr }, () => {
            this.setButtonStatus();
        });
    }

    setButtonStatus(){
        // Enable all buttons
        this.props.data.forEach(item => {
            if (document.getElementById(item.id)) {
                document.getElementById(item.id).className = "btn btn-primary";
            }
        });
        // Disable buttons of selected data
        this.state.selectedDataIds.forEach(id => {
            if (document.getElementById(id)) {
                document.getElementById(id).className = "fixBottonImage";
            }
        });
    }

    componentDidUpdate(){
        this.setButtonStatus();
    }
    
    upLoad() {
        this.props.handlePassImage(this.state.selectedDataIds)
       
    }
    render() {
        let listData;
        if (this.props.data.length > 0) {
            listData = this.props.data.map((item, key) => {
                return (
                    <tr key={key}>
                        <td>{item.name}</td>
                        <td><img className="img-thumbnail" src={item.cover_img.src} alt="khong tai dc" /></td>
                        <td className="center-td">
                            <button
                                id={item.id}
                                className="btn btn-primary"
                                data-toggle="modal"
                                // disabled={this.state.selectedDataIds.indexOf(item.id) !== -1}
                                onClick={() => {
                                    this.props.chooseHandle(item)
                                    this.handleDisable(item)
                                    this.upLoad()
                                }}
                            >Chọn</button>
                        </td>
                    </tr>
                );
            });
        }
        return (
            <div>

                {/* Table */}
                {/* Only show table if we have records and we are not fetching data */}
                    <table className="table table-striped fixed-table-event">
                        <thead>
                            <tr className="table-dark text-dark center-th">
                                <th className="text-left">{this.props.flag === 'tour'?'Tên Tour': 'Tên Sự Kiện'}</th>
                                <th className="text-center"> Ảnh</th>
                                <th className="text-center">Thao tác</th>
                            </tr>
                        </thead>
                        <tbody>
                            {listData}
                        </tbody>
                    </table>
            </div>
        );

    }
}
export default Table;