import {domain} from '../../constant'

export default (imgUrlArr) => {
    return new Promise((resolve, reject) => {
        const url = domain +'/delete-images'
        fetch(url, {
          method: "POST",
          headers: { "Content-type": "application/json" },
          body: JSON.stringify(imgUrlArr)
        })
          .then((response) => response.json())
          .then((res) => {
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      });
}