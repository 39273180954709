import * as types from '../constant';
const DEFAULT_STATE = {
  listUSBeauty: [],
  dataFetched: false,
  isFetching: false,
  error: false,
  errorMessage: null,
  activePageUS: 1,
  total_pages: 1,
  textSearch: ''
}

export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case types.GET_USBEAUTY_REQUEST:
      return {
        ...state,
        isFetching: true,
        activePageUS: action.payload,
        textSearch: ''
      }
    case types.GET_USBEAUTY_SUCCESS:
      return {
        ...state,
        isFetching: false,
        dataFetched: true,
        error: false,
        errorMessage: null,
        listUSBeauty: action.payload.posts,
        total_pages: action.payload.total_pages
      }
    case types.GET_USBEAUTY_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: true,
        errorMessage: action.payload.errorMessage
      }
    case types.ADD_USBEAUTY_REQUEST:
      return {
        ...state,
        isFetching: true
      }
    case types.ADD_USBEAUTY_SUCCESS:
      return {
        ...state,
        isFetching: false
      }
    case types.ADD_USBEAUTY_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: true,
        errorMessage: action.payload.errorMessage
      }
    case types.EDIT_USBEAUTY_REQUEST:
      return {
        ...state,
        isFetching: true
      }
    case types.EDIT_USBEAUTY_SUCCESS:
      return {
        ...state,
        isFetching: false
      }
    case types.EDIT_USBEAUTY_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: true,
        errorMessage: action.payload.errorMessage
      }
    case types.DELETE_USBEAUTY_REQUEST:
      return {
        ...state,
        isFetching: true
      }
    case types.DELETE_USBEAUTY_SUCCESS:
      return {
        ...state,
        isFetching: false
      }
    case types.DELETE_USBEAUTY_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: true,
        errorMessage: action.payload.errorMessage
      }
    case types.SEARCH_USBEAUTY_REQUEST:
      return {
        ...state,
        isFetching: true,
        textSearch: action.payload.text,
        activePageUS: action.payload.offset
      }
    case types.SEARCH_USBEAUTY_SUCCESS:
      return {
        ...state,
        isFetching: false,
        dataFetched: true,
        error: false,
        errorMessage: null,
        listUSBeauty: action.payload.posts,
        total_pages: action.payload.total_pages
      }
    case types.SEARCH_USBEAUTY_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: true,
        errorMessage: action.payload.errorMessage
      }
    case types.REFRESH_USBEAUTY_REQUEST:
      return {
        ...state,
        isFetching: true
      }
    case types.REFRESH_USBEAUTY_SUCCESS:
      return {
        ...state,
        isFetching: false,
        error: false,
        errorMessage: null
      }
    case types.REFRESH_USBEAUTY_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: true,
        errorMessage: action.payload.errorMessage
      }
    default:
      return state;
  }
};