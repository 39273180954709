import * as types from '../constant';

const DEFAULT_STATE = {
    listEvent: [],
    activePage: 0,
    totalPages: 0,
	isLoading: false
};

export default (state = DEFAULT_STATE, action) => {
    switch (action.type) {
        case types.GET_EVENTS_REQUEST:
		case types.SEARCH_EVENT_REQUEST:
		case types.ADD_EVENT_REQUEST:
		case types.UPDATE_EVENT_REQUEST:
		case types.DELETE_EVENT_REQUEST:
            return {
                ...state,
				isLoading: true,
				totalPages: 0
            };
        case types.GET_EVENTS_SUCCESS:
        case types.SEARCH_EVENT_SUCCESS:
            return {
                ...state,
                listEvent: action.payload.events,
                totalPages: action.payload.totalPages,
                activePage: action.payload.activePage,
				isLoading: false
            };
		case types.GET_EVENTS_FAILED:
		case types.SEARCH_EVENT_FAILED:
            return {
                ...state,
				isLoading: false,
				listEvent: []
            };
		case types.ADD_EVENT_FAILED:
			return {
				...state
			};
        case types.ADD_EVENT_SUCCESS:
            return {
                ...state,
            };
        case types.UPDATE_EVENT_SUCCESS:
            return {
                ...state,
            };
        case types.UPDATE_EVENT_FAILED:
            return {
                ...state,
            };
        case types.DELETE_EVENT_SUCCESS:
            return {
                ...state,
            };
        case types.DELETE_EVENT_FAILED:
            return {
                ...state,
            };
        default:
            return state;
    }
};