import { domain } from '../../constant'

export default function addEventRequest(event) {
    return new Promise((resolve, reject) => {
        const url = domain + "/add-event";
        fetch(url, {
            method: 'POST',
            headers:{"Content-type":"application/json"},
            body: JSON.stringify(event)
        })
            .then((response) => response.json())
            .then((res) => {
                resolve(res);
            })
            .catch((error) => {
                reject(error);
            });
    });
}
