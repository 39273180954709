import * as types from "../constant"

const State_Default = {
    listContact: [],
    activePagesContact: 1,
    totalPages: 1,
    isFetching: false,

}

export default (state = State_Default, action) => {
    switch (action.type) {
        case types.GET_LIST_CONTACT_REQUEST:
            return {
                ...state,
                activePagesContact: action.payload,
                isFetching: true

            }
        case types.GET_LIST_CONTACT_SUCCESS:
            return {
                ...state,
                isFetching: false,
                listContact: action.payload.contacts,
                totalPages: action.payload.totalPages
            }
        case types.GET_LIST_CONTACT_FAILURE:
            return {
                ...state,
                isFetching: false,
                errorMessage: action.payload.errorMessage
            }
        case types.SEARCH_CONTACT_REQUEST:
            return {
                ...state,
                isFetching: true,
                activePagesContact: action.payload.page
            }
        case types.SEARCH_CONTACT_SUCCESS:
            return {
                ...state,
                isFetching: false,
                listContact: action.payload.contacts,
                totalPages: action.payload.totalPages
            }
        case types.SEARCH_CONTACT_FAILURE:
            return {
                ...state,
                isFetching: false,
                errorMessage: action.payload.errorMessage
            };
        case types.DELETE_CONTACT_SUCCESS:
            return {
                ...state,
                isFetching: false,
                errorMessage: action.payload.errorMessage
            };
        default:
            return state;
    }
}
