import React, { Component } from "react"

export default class LoadingModal extends Component {

    render() {
        return (
			<div style={{border: "1px solid rgba(0, 0, 0, 0.2)", maxWidth: "500px", maxHeight: "500px",
						margin: "0 auto", borderRadius: "5px" }}>
				<div className="center-modal-title">
					<img alt="Loading ..." width="100" height="100" src="/images/loading.gif"/>
					<h4 className="center-modal-title">Loading...</h4>
					<p>Please wait</p>
				</div>
			</div>
        )
    }
}