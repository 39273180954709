import * as types from '../../constant'
export default function callApi(id) {
    return new Promise((resolve, reject) => {
        const url = types.domain +'/del-post/'+id
        fetch(url, {
          method: "DELETE"
        })
          .then((response) => response.json())
          .then((res) => {
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      });
}