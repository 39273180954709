import React, { Component } from 'react';
import { Modal, Button, Form, Row, Col } from 'react-bootstrap';

export default class AddTopicComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            type: "",
            showModal: false,
            invalidType: false
        };
    }

    addTopic() {
        if (this.state.type.trim()) {
            this.props.addTopic(this.state);
            this.setState({ type: "", invalidType: false });
            this.closeModal();
        } else {
            this.setState({ invalidType: true, type: "" });
        }
    }

    openModal() {
        this.setState({ showModal: true, invalidType: false });
    }

    closeModal() {
        this.setState({ showModal: false, type: "" })
    }

    render() {
        return (
            <div>
                <Button
                    variant="success"
                    block
                    onClick={() => this.openModal()}
                    style={{marginTop: 10}}
                >Thêm
                </Button>

                <Modal
                    show={this.state.showModal}
                    onHide={() => this.closeModal()}
                    size="md"
                    aria-labelledby="example-custom-modal-styling-title">
                    <Modal.Header closeButton>
                        <Modal.Title id="example-custom-modal-styling-title " style={{textAlign:"center",width:"100%"}}>
                            Thêm chủ đề mới
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ margin: "0 auto" }}>
                        <Form.Group as={Row} >
                            <Form.Label column sm={4} style={{ fontWeight: "bold" }}>
                                Chủ đề:
                            </Form.Label>
                            <Col sm={8} style={{ textAlign: "center" }}>
                                <Form.Control
                                    type="text"
                                    value={this.state.type}
                                    placeholder="Tên chủ đề"
                                    isInvalid={this.state.invalidType}
                                    onChange={(e) => {
                                        this.setState({ type: e.target.value, invalidType: !e.target.value.trim() })
                                    }}
                                    onKeyPress={(e) => {
                                        if (e.key === "Enter" && this.state.type.trim()) {
                                            this.addTopic();
                                        }
                                    }}
                                />
                            </Col>
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="danger" onClick={() => this.closeModal()}>Hủy</Button>
                        <Button variant="success" onClick={() => this.addTopic()} >Xác nhận</Button>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }
}