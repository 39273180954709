import React from 'react'
import PostFormContainer from '../containers/PostFormContainer'
import { POST_TYPE_FLAG } from '../constant';

export default class PostFormPage extends React.Component {

    getTitlePage = () => {
        const type = window.sessionStorage.getItem("post.type")
        const id = window.sessionStorage.getItem("post.id")
        const secondPart = id ? "Sửa bài đăng" : "Thêm bài đăng"

        switch (type) {
            case POST_TYPE_FLAG.USA_BEAUTY:
                return "Vẻ đẹp Mỹ > " + secondPart
            case POST_TYPE_FLAG.USA_INFO:
                return "Thông tin nước Mỹ > " + secondPart
            case POST_TYPE_FLAG.USEFUL_INFO:
                return "Thông tin hữu ích > " + secondPart
            default:
                return ""
        }

    }

    render() {
        return (
            <div className="HomePage">
                <div className="card">
                    <div className="card-header">
                        <h1>{this.getTitlePage()}</h1>
                    </div>
                    <div className="card-body">
                        <PostFormContainer />
                    </div>
                </div>
            </div>
        );
    }
}