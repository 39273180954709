import {domain} from '../../constant'
export default function addNewTour(data) {
      return new Promise((resolve, reject) => {
          const url = domain+'/add-tour'
          fetch(url, {
            method: "POST",
            body: data
          })
            .then((response) => response.json())
            .then((res) => {
              resolve(res);
            })
            .catch((error) => {
              reject(error);
            });
        });
  }
    