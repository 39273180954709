import * as types from '../constant';

const DEFAULT_STATE = {
    errorMessage: "",
    isFetching: false,
};

export default (state = DEFAULT_STATE, action) => {
    switch (action.type) {
        case types.LOGIN_REQUEST:
            return {
                ...state,
                isFetching: true
            }
        case types.LOGIN_SUCCESS:
            return {
                ...state,
                isFetching: false
            }
        case types.LOGIN_FAILURE:
            return {
                ...state,
                errorMessage: action.payload.errorMessage,
                isFetching: false
            }
        default:
            return state;
    }
}