import React, { Component } from 'react';
import { Button, Modal, Form } from 'react-bootstrap'
import './styles/Login.css'

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userName: '',
            passWord: ''
        };
    }
    handleClick() {
        let data = {
            userName: this.state.userName,
            passWord: this.state.passWord
        }
        this.props.login(data)
    }
    validateLogin() {
        if (this.state.userName && this.state.passWord) {
            return true
        } else {
            alert('Vui lòng nhập đầy đủ Username và Password!')
            return false
        }
    }
    render() {
        return (
            <Modal
                show={true}
                size="lg"
                aria-labelledby="example-custom-modal-styling-title"
            >
                <Modal.Body>
                    <Modal.Title
                        style={{ textAlign: "center" }}
                    >Đăng Nhập</Modal.Title>

                    <Form.Label style={{ fontWeight: "bold", color: "gray" }}>
                        Tên tài khoản
                        </Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Nhập tên tài khoản"
                        value={this.state.userName}
                        onChange={(e) => this.setState({ userName: e.target.value })}
                        onKeyPress={(e) => {
                            // If user hit enter and search input has value
                            if (e.key === "Enter" && this.validateLogin()) {
                                this.handleClick()
                            }
                        }}
                    />

                    <Form.Label style={{ fontWeight: "bold", color: "gray" }}>
                        Mật khẩu
                        </Form.Label>
                    <Form.Control
                        type="password"
                        placeholder="Nhập mật khẩu"
                        value={this.state.passWord}
                        onChange={(e) => this.setState({ passWord: e.target.value })}
                        onKeyPress={(e) => {
                            // If user hit enter and search input has value
                            if (e.key === "Enter" && this.validateLogin()) {
                                this.handleClick()
                            }
                        }}
                    />
                    <Button
                        style={{ marginTop: 10 }}
                        block={true}
                        onClick={() => {
                            if (this.validateLogin()) {
                                this.handleClick()
                            }
                        }}
                    >Đăng nhập</Button>
                </Modal.Body>
            </Modal>


            // <div className="wrap-login-form">
            //     <div className="container main-panel">
            //         <label htmlFor="uname"><b>Username</b></label>
            //         <input type="text" placeholder="Nhập Username" name="uname" onChange={(e) => { this.setState({ userName: e.target.value }) }}
            //             onKeyPress={(e) => {
            //                 // If user hit enter and search input has value
            //                 if (e.key === "Enter" && this.validateLogin()) {
            //                     this.handleClick()
            //                 }
            //             }}
            //         />
            //         <label htmlFor="psw"><b>Password</b></label>
            //         <input type="password" placeholder="Nhập Password" name="psw" onChange={(e) => { this.setState({ passWord: e.target.value }) }}
            //             onKeyPress={(e) => {
            //                 // If user hit enter and search input has value
            //                 if (e.key === "Enter" && this.validateLogin()) {
            //                     this.handleClick()
            //                 }
            //             }}
            //         />
            //         <button className="btn btn-success" onClick={() => {
            //             if (this.validateLogin()) {
            //                 this.handleClick()
            //             }
            //         }}>Đăng nhập</button>
            //     </div>
            // </div>
        );
    }

}

export default Login;