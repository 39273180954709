import React from 'react';

class AddTopicComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            type: ''
        }
    }

    check() {
        if (this.state.type === '' ) {
            return false
        } else {
            return true
        }
    }
    addTopic(){
        if (this.check()) {
            this.props.addTopic(this.state)
            this.setState({
                type: ''
        })
        }      
    }

    render() {
        return (
            <div>
                <button type="button" className="btn btn-success btn-block"
                    data-toggle="modal" data-target="#topicModal">Thêm</button>
                <div className="modal fade" id="topicModal" role="dialog">
                    <div className="modal-dialog">
                        {/* Modal content*/}
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title" style={{width:"100%",textAlign:"center"}} >Thêm chủ đề</h4>
                                <button type="button" className="close" data-dismiss="modal"
                                onClick={()=>this.setState({type: ''})}>×</button>
                            </div>
                            <div className="modal-body">
                                <div>
                                    <label className="name-label" >Chủ Đề:</label>
                                    <input className="form1" placeholder="Tên Chủ Đề" value={this.state.type}
                                    onChange={(e) => { this.setState({ type: e.target.value }) }}/>
                                    <div className="clear" ></div>
                                </div>
                            </div>
                            <div className="modal-footer">
                            <button type="button" className="btn btn-danger" data-dismiss="modal" onClick={()=>this.setState({type: ''})} >Hủy</button>
                                <button type="button" className="btn btn-success" data-dismiss="modal" onClick={() => this.addTopic()}>Xác nhận</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default AddTopicComponent