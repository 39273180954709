import { domain } from "../../../constant";

export default function deleteFAQsAPI(id) {
    return new Promise((resolve, reject) => {
      const url = domain+"/del-faq/"+id;
      fetch(url, {
        method: 'DELETE'
      })
        .then((response) => response.json())
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
    