import {domain} from '../../constant'

export default function callApi(data) {
    return new Promise((resolve, reject) => {
        const url = domain + '/update-tour/';
        fetch(url, {
            method: "PUT",
            body: data
        })
            .then((response) => response.json())
            .then((res) => {
                resolve(res);
            })
            .catch((error) => {
                reject(error);
            });
    });
}
