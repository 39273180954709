import React, { Component } from 'react'
import '../../styles/abouts/Term.css'
import LoadingModal from '../../commons/LoadingModal'
class Term extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: '',
            title: '',
            content: ''
        }
    }
    validated() {
        if (this.state.title === '' || this.state.content === '') {
            return false
        } else {
            alert('Cập Nhật Điều Khoản Thành Công!')
            return true
        }
    }
    handleUpdateTerm(data) {
        if (this.validated()) {
            const textTerm = {
                title: this.state.title,
                content: this.state.content,
                id: data._id
            }
            this.props.updateTerm(textTerm);
        } else {
            alert('Thiếu Điều Khoản Hoặc Nội Dung Điều Khoản!')
        }

    }
    handleDeleteTerm(id) {
        this.props.deleteTerm(id);
        alert('Xoá Thành Công!')
    }
    resetTerm() {
        this.setState({
            id: '',
            title: '',
            content: ''
        })
    }
    render() {
        let listTerms
        if (this.props.term) {
            listTerms = this.props.term.map((item, key) => {
                let contItem = item.content.split("\n").map((contChild, idx) => {
                    return (
                        <p key={idx} className="pContentTerm">{contChild}</p>
                    )
                })
                return (
                    <tr key={key}>
                        <td className="tdTermSTT">{key + 1}</td>
                        <td className="tdTermTitle">{item.title}</td>
                        <td className="tdTermContent">
                            {contItem}
                        </td>
                        <td className="tdTermBtn">
                            <button type="button"
                                className="btn btn-primary buttonUpdateTerm"
                                data-toggle="modal"
                                data-target={"#editTerm" + key}
                                onClick={() => {
                                    this.setState({
                                        title: item.title,
                                        content: item.content,
                                    })
                                }}>Sửa</button>
                            <div className="modal fade" id={"editTerm" + key} role="dialog">
                                <div className="modal-dialog">
                                    <div className="modal-content modalUpdateTerm">
                                        <div className="modal-header">
                                            <h4 className="center-modal-title">Sửa nội dung điều khoản sử dụng</h4>
                                        </div>
                                        <div className="modal-body">
                                            <div>
                                                <div className="divUpdateTerm">
                                                    <label className="labelUpdateTerm">Điều khoản:</label>
                                                    <div className="divInputUpdateTerm">
                                                        <input className="inputUpdateTerm"
                                                            value={this.state.title}
                                                            onChange={(e) => this.setState({ title: e.target.value })}
                                                            placeholder="Nhập điều khoản" />
                                                    </div>
                                                </div>
                                                <div className="divUpdateTerm">
                                                    <label className="labelUpdateTerm">Nội dung điều khoản:</label>
                                                    <div className="divInputUpdateTerm">
                                                        <textarea className="textUpdateTerm"
                                                            value={this.state.content}
                                                            onChange={(e) => this.setState({ content: e.target.value })}
                                                            placeholder="Nhập nội dung điều khoản" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="btnActionsUpdateTerm">
                                                <button type="button"
                                                    className="btn btn-danger btnCloseUpdateTerm"
                                                    data-dismiss="modal"
                                                    style={{ marginRight: 5 }}
                                                    onClick={() => this.resetTerm()}>Huỷ</button>
                                                <button type="button"
                                                    className="btn btn-success"
                                                    data-dismiss="modal"
                                                    onClick={() => this.handleUpdateTerm(item)}>Xác nhận</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <button type="button"
                                className="btn btn-danger buttonDeleteTerm"
                                data-toggle="modal"
                                data-target={"#deleteTerm" + key}>Xoá</button>
                            <div className="modal fade" id={"deleteTerm" + key} role="dialog">
                                <div className="modal-dialog">
                                    <div className="modal-content">
                                        <div className="modal-body">
                                            <div className="divDeleteTerm">
                                                <h5>Bạn Có Chắc Chắn Xóa Điều Khoản</h5>
                                                <div className="divActionDelTerm">
                                                    <button type="button"
                                                        className="btn btn-danger btnCloseDelTerm"
                                                        style={{ marginRight: 5 }}
                                                        data-dismiss="modal"
                                                        onClick={() => this.resetTerm()}>Hủy</button>
                                                    <button type="button"
                                                        className="btn btn-success"
                                                        data-dismiss="modal"
                                                        onClick={() => this.handleDeleteTerm(item._id)}>Xác Nhận</button>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </td>
                    </tr>
                )
            })
        }
        return (
            <div className="aboutTermPage">
                <h3>Quản lý nội dung điều khoản sử dụng</h3>
                <div className="tableTermDisplay">
                    {(!this.props.isLoading && listTerms) ?
                        <table className="table table-striped table-hover">
                            <thead>
                                <tr className="table-dark text-dark">
                                    <th className="thTermSTT">STT</th>
                                    <th>Điều khoản</th>
                                    <th className="thTermContent">Nội dung điều khoản</th>
                                    <th className="thTermAction">Tùy chọn</th>
                                </tr>
                            </thead>
                            <tbody>
                                {listTerms}
                            </tbody>
                        </table>
                        : <LoadingModal />}
                </div>
            </div >
        )
    }
}

export default Term