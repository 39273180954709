import { connect } from 'react-redux';
import React, { Component } from 'react'
import EventForm from "../components/EventComponent/AdditionOrEditionEvent"
import * as types from '../constant'

class EventFormContainer extends Component {

    render() {
        return (
            <div>
                <EventForm {...this.props} />
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        urlCoverImg: state.common.urlCoverImg
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        showNotifyModal: (msg) => dispatch({ type: types.SHOW_NOTIFY_MODAL, payload: msg }),
        addEvent: (payload) => dispatch({ type: types.ADD_EVENT_REQUEST, payload }),
        updateEvent: (payload) => dispatch({ type: types.UPDATE_EVENT_REQUEST, payload }),
        uploadSingleImage: (payload) => dispatch({ type: types.UPLOAD_IMAGE_REQUEST, payload }),
        deleteUnnecesaryImage: (payload) => dispatch({ type: types.DELETE_IMAGES_REQUEST, payload })
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(EventFormContainer)