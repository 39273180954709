import { all } from 'redux-saga/effects';
import { TextSaga } from './HomeAdminSaga/TextSaga';
import { GetImage } from './HomeAdminSaga/GetImage';
import { EditTextSaga } from './HomeAdminSaga/EditTextSaga';
import { EditTextNew } from './HomeAdminSaga/EditTextNew';
import { USBeautySaga } from './USBeautySaga';
import { UsefulInformationSaga } from './UsefulInformationSaga';
import { UsaInformationSaga } from './UsaInformationSaga';
import { RequestSaga } from "../sagas/PriceRequestSaga";
import { ContactSaga } from "../sagas/ContactSaga";
import {AboutSaga} from './AboutSaga'
import{TourScheduleSaga} from './TourScheduleSaga'
import{EditImageSaga} from './HomeAdminSaga/EditImageSaga'
import{EditImageEvent} from './HomeAdminSaga/EditImageEvent'
import { EventSaga } from './EventSaga';
import { GetImageEvent } from './HomeAdminSaga/GetImageEvent';
import { GetImageTour } from './HomeAdminSaga/GetImageTour';
import{EditImageTour} from './HomeAdminSaga/EditImageTour'
import { popularTourSaga } from "./PopularTourSaga"
import { UsBeautyTopicSaga } from './USBeautyTopicSaga';
import { AddImage } from "./HomeAdminSaga/AddImage";
import { DeleteImage } from "./HomeAdminSaga/DeleteImage";
import { CommonSaga } from "./CommonSaga";


function* rootSaga() {
  yield all([
    ...TextSaga,
    ...GetImage,
    ...EditTextSaga,
    ...EditTextNew,
    ...USBeautySaga,
    ...UsBeautyTopicSaga,
    ...UsefulInformationSaga,
    ...UsaInformationSaga,
    ...RequestSaga,
    ...ContactSaga,
    ...AboutSaga,
    ...TourScheduleSaga,
    ...EventSaga,
    ...popularTourSaga,
    ...EditImageSaga,
    ...EditImageEvent,
    ...EditImageTour,
    ...GetImageEvent,
    ...GetImageTour,
    ...AddImage,
    ...DeleteImage,
    ...CommonSaga
  ]);
}
export default rootSaga;