import { put, takeEvery } from 'redux-saga/effects';
import * as types from '../constant';

import getTourAPI from '../fetchAPI/TourAPI/getTourSchedule'
import addNewTour from '../fetchAPI/TourAPI/addTour'
import update from '../fetchAPI/TourAPI/updateTourSchedule'
import deleteTourSchedule from '../fetchAPI/TourAPI/deleteTourSchedule'
import copyTourSchedule from '../fetchAPI/TourAPI/copyTourSchedule'
import getTopicAPI from '../fetchAPI/TourAPI/getTopicAPI'
import addTopicAPI from '../fetchAPI/TourAPI/addTopicAPI'
import updateTopicAPI from '../fetchAPI/TourAPI/updateTopicAPI'
import deleteTopicAPI from '../fetchAPI/TourAPI/deleteTopicAPI'
import searchTourAPI from '../fetchAPI/TourAPI/searchTourAPI'

function* getTour(action) {
  try {
    const res = yield getTourAPI(action.payload);
    let tours = res.tours.map(tour => {
      return {
        ...tour,
        id: tour._id,
        nameTour: tour.name,
        tourCode: tour.code,
        include: tour.include_services,
        notInclude: tour.not_include_services,
        tips: tour.special_point,
        time: tour.duration
      }
    })
    yield put({
      type: types.GET_TOUR_SUCCESS,
      payload: { tours: tours, totalPage: res.total_pages }
    });
  } catch (error) {
    yield put({
      type: types.GET_TOUR_FAILURE,
      payload: {
        errorMessage: error.message
      }
    });
  }
}

function* addTour(action) {
  try {
    const res = yield addNewTour(action.payload);
    yield put({
      type: types.ADD_TOUR_SUCCESS,
      payload: res
    });
    yield put({
      type: types.GET_TOUR_REQUEST,
      payload: 1
    })
  } catch (error) {
    yield put({
      type: types.ADD_TOUR_FAILURE,
      payload: {
        errorMessage: error.message
      }
    });
  }
}

function* updateTour(action) {
  try {
    const res = yield update(action.payload.dataAPI);
    yield put({
      type: types.EDIT_TOUR_SUCCESS,
      payload: res
    });
    if(action.payload.textSearch){
      yield put({
        type:types.SEARCH_TOUR_REQUEST,
        payload: {
          offset: action.payload.activePage,
          text: action.payload.textSearch
        }
      })
    }else{
      yield put({
        type:types.GET_TOUR_REQUEST,
        payload: action.payload.activePage
      })
    }
  } catch (error) {
    yield put({
      type: types.EDIT_TOUR_FAILURE,
      payload: {
        errorMessage: error.message
      }
    });
  }
}

function* deleteTour(action) {
  try {
    const res = yield deleteTourSchedule(action.payload.id);
    yield put({
      type: types.DELETE_TOUR_SUCCESS,
      payload: res
    });
    let offset = action.payload.activePage
		if (action.payload.length === 1 && action.payload.totalPage > 1) {
			offset -= 1
		}
		if (action.payload.textSearch) {
			yield put({
				type: types.SEARCH_TOUR_REQUEST,
				payload: {
					offset: offset,
					text: action.payload.textSearch
				}
			})
		} else {
			yield put({
				type: types.GET_TOUR_REQUEST,
				payload: offset
			})
		}
  } catch (error) {
    yield put({
      type: types.DELETE_TOUR_FAILURE,
      payload: {
        errorMessage: error.message
      }
    });
  }
}

function* copyTour(action) {
  try {
    const res = yield copyTourSchedule(action.payload.id);
    yield put({
      type: types.COPY_TOUR_SUCCESS,
      payload: res
    });
    let offset = action.payload.activePage
		if (action.payload.length === 1 && action.payload.totalPage > 1) {
			offset -= 1
		}
		if (action.payload.textSearch) {
			yield put({
				type: types.SEARCH_TOUR_REQUEST,
				payload: {
					offset: offset,
					text: action.payload.textSearch
				}
			})
		} else {
			yield put({
				type: types.GET_TOUR_REQUEST,
				payload: offset
			})
		}
  } catch (error) {
    yield put({
      type: types.COPY_TOUR_FAILURE,
      payload: {
        errorMessage: error.message
      }
    });
  }
}

function* getTopics() {
  try {
    const res = yield getTopicAPI();
    yield put({
      type: types.GET_TOPIC_SUCCESS,
      payload: res
    });
  } catch (error) {
    yield put({
      type: types.GET_TOPIC_FAILURE,
      payload: {
        errorMessage: error.message
      }
    });
  }
}

function* addTopic(action) {
  try {
    const res = yield addTopicAPI(action.payload);
    yield put({
      type: types.ADD_TOPIC_SUCCESS,
      payload: res
    });
    yield put({
      type: types.GET_TOPIC_REQUEST,
      payload: res
    });
  } catch (error) {
    yield put({
      type: types.ADD_TOPIC_FAILURE,
      payload: {
        errorMessage: error.message
      }
    });
  }
}

function* updateTopic(action) {
  try {
    const res = yield updateTopicAPI(action.payload);
    yield put({
      type: types.UPDATE_TOPIC_SUCCESS,
      payload: res
    });
    yield put({
      type: types.GET_TOPIC_REQUEST,
      payload: 1
    });
    yield put({
      type: types.GET_TOUR_REQUEST,
      payload: 1
    });
  } catch (error) {
    yield put({
      type: types.UPDATE_TOPIC_FAILURE,
      payload: {
        errorMessage: error.message
      }
    });
  }
}

function* deleteTopic(action) {
  try {
    const res = yield deleteTopicAPI(action.payload);
    yield put({
      type: types.DELETE_TOPIC_SUCCESS,
      payload: res
    });
    yield put({
      type: types.GET_TOPIC_REQUEST,
      payload: 1
    });
    yield put({
      type: types.GET_TOUR_REQUEST,
      payload: 1
    });
  } catch (error) {
    yield put({
      type: types.DELETE_TOPIC_FAILURE,
      payload: {
        errorMessage: error.message
      }
    });
  }
}
function* searchTour(action) {
  try {
    const res = yield searchTourAPI(action.payload);
    let tours = res.tours.map(tour => {
      return {
        ...tour,
        id: tour._id,
        nameTour: tour.name,
        tourCode: tour.code,
        include: tour.include_services,
        notInclude: tour.not_include_services,
        tips: tour.special_point,
        time: tour.duration
      }
    })
    yield put({
      type: types.SEARCH_TOUR_SUCCESS,
      payload: { tours: tours, totalPage: res.total_pages }
    })
  } catch (error) {
    yield put({
      type: types.SEARCH_TOUR_FAILURE,
      payload: {
        errorMessage: error.message
      }
    })
  }
}
export const TourScheduleSaga = [
  takeEvery(types.GET_TOUR_REQUEST, getTour),
  takeEvery(types.ADD_TOUR_REQUEST, addTour),
  takeEvery(types.EDIT_TOUR_REQUEST, updateTour),
  takeEvery(types.DELETE_TOUR_REQUEST, deleteTour),
  takeEvery(types.COPY_TOUR_REQUEST, copyTour),
  takeEvery(types.GET_TOPIC_REQUEST, getTopics),
  takeEvery(types.ADD_TOPIC_REQUEST, addTopic),
  takeEvery(types.UPDATE_TOPIC_REQUEST, updateTopic),
  takeEvery(types.DELETE_TOPIC_REQUEST, deleteTopic),
  takeEvery(types.SEARCH_TOUR_REQUEST, searchTour)
];