import { put, takeEvery } from 'redux-saga/effects';
import GetImageTours from '../../fetchAPI/HomeAdmin/GetImageTour';
import * as types from '../../constant';
function* getImageTour() {
    try {
      const res = yield GetImageTours();
      yield put({
        type: types.GET_IMAGE_TOUR_SUCCESS,
        payload: res
      });
    } catch (error) {
      yield put({
        type: types.GET_IMAGE_TOUR_FAILURE,
        payload: {
          errorMessage: error.message
        }
      });
    }
  }
  
  export const GetImageTour = [
    takeEvery(types.GET_IMAGE_TOUR_REQUEST, getImageTour)
  ];
  
  