import { domain, EVENT_ITEMS_PER_PAGE } from '../../constant'

export default function searchEventRequest(textSearch, activePage) {
  return new Promise((resolve, reject) => {
    let url = domain + `/search-event/${EVENT_ITEMS_PER_PAGE}/${activePage}/${textSearch}`;
    fetch(url, {
      method: 'GET'
    })
      .then((response) => response.json())
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
