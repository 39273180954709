import React, { Component } from 'react'
import { Container, Button, Form, Row, Col } from 'react-bootstrap'
import Editor from '../Editor';
import history from '../../history'
import { getAllSourceImages, isContainImages } from '../../utils/regexHelper';

export default class AdditionOrEditionEvent extends Component {
    constructor(props) {
        super(props);
        this.state = this.setUpState();
    }

    getDefaultState() {
        return {
            id: "",
            name: "",
            urlCoverImg: '',
            price: "",
            day: "",
            time: new Date().getDate().toString().padStart(2, "0") + "/"
            + (new Date().getMonth() + 1).toString().padStart(2, "0") + "/"
            + new Date().getFullYear(),
            location: "",
            description: "",
            allSrcImgArr: [],
            topic: ""
        };
    }

    getFormatPrice = price => {
        let validPrice = ""
        for (let i = 0; i < price.length; i++) {
            const char = price.charAt(i)
            if (char.match(/[0-9]/))
                validPrice += char
            if (i !== price.length - 1 && (price.length - i - 1) % 3 === 0)
                validPrice += "."
        }
        return validPrice
    }

    setUpState() {
        // Check id in session to know the form user want to open
        let id = sessionStorage.getItem('event.id')
        let price = sessionStorage.getItem('event.price') || ""
        price = this.getFormatPrice(price)
        // Edit Form
        if (id) {
            return {
                id: id,
                name: sessionStorage.getItem('event.name') || "",
                urlCoverImg: sessionStorage.getItem('event.cvr_img') || "",
                location: sessionStorage.getItem('event.location') || "",
                price,
                topic: sessionStorage.getItem('event.topic') || "",
                day: sessionStorage.getItem('event.day') || "",
                description: sessionStorage.getItem('event.description') || "",
                time: sessionStorage.getItem('event.time') ||
                    new Date().getDate().toString().padStart(2, "0") + "/"
                    + (new Date().getMonth() + 1).toString().padStart(2, "0") + "/"
                    + new Date().getFullYear(),
                allSrcImgArr:
                    sessionStorage.getItem('event.description') 
                    && isContainImages(sessionStorage.getItem('event.description'))
                        ? getAllSourceImages(sessionStorage.getItem('event.description'))
                        : [],
            }
        }
        // Add form
        return this.getDefaultState()
    }

    handleSelectCoverImg(file) {
        // None file selected or invalid file => Negate access resource
        if (!file || !file.name.match(/.(jpg|jpeg|png)$/gi))
            return;
        const { urlCoverImg } = this.state
        this.setState({ urlCoverImg: "" });
        const payload = new FormData();
        payload.append("upload", file, file.name);
        this.props.uploadSingleImage(payload);
        if (urlCoverImg) {
            if (this.state.id) {
                // Edit form => add old url to array in order to delete it if user confirm updating action
                this.addNewSourceImage(urlCoverImg)
            } else {
                // Add form => Direct delete image
                this.props.deleteUnnecesaryImage({ img_urls: [urlCoverImg] });
            }
        }
    }

    shouldComponentUpdate(nextProps) {
        // Got url from props => Set it to state
        if (nextProps.urlCoverImg && !this.state.urlCoverImg) {
            this.setState({ urlCoverImg: nextProps.urlCoverImg });
        }
        return true;    // Re-render if receive new props or state
    }

    /**
     * Function use to add source image from child-editor component after it is uploaded to server
     * @param {String} url Source image url
     */
    addNewSourceImage(url) {
        // Push url to array in state
        this.setState(state => ({
            allSrcImgArr: state.allSrcImgArr.concat(url)
        }));
    }

    deleteUnnecessaryImage() {
        // Compare all source image with current source image
        // 1. Get all current source images
        const currSrcImgArr = getAllSourceImages(window.tinymce.get('tiny').getContent()) || []
        // 2. Filter all image in source array that is not in current source image array
        const unnecesaryImgArr = this.state.allSrcImgArr.filter(src => currSrcImgArr.indexOf(src) === -1)
        if (unnecesaryImgArr.length) {
            // If the array is not empty => Dispatch delete image action
            this.props.deleteUnnecesaryImage({ img_urls: unnecesaryImgArr });
        }
    }

    submitForm() {
        const { showNotifyModal, addEvent, updateEvent } = this.props
        const editor = window.tinymce.get('tiny')
        const description = editor.getContent()
        const short_description = editor.getContent({ format: 'text' }).substring(0, 200)

        const time = this.getDateFromDateString(this.state.time)
        if(!time) {
            showNotifyModal("Yêu cầu nhập đúng định dạng ngày DD/MM/YYYY. VD: 10/10/2020")
            return
        }
        
        if (!description 
            || !this.state.price 
            || !this.state.name 
            || !this.state.location 
            || !this.state.urlCoverImg
            || !this.state.time
            || !this.state.day) {
            showNotifyModal(
                "Yêu cầu nhập đầy đủ danh mục, tên lịch trình, giá tiền, điểm đến, nội dung, số ngày, ảnh đại diện"
            )
        } else {
            // Dispatch delete unnecessary image first
            this.deleteUnnecessaryImage()
            // Then dispatch add/update event action
            let payload = {
                name: this.state.name.trim(),
                price: this.state.price
                    .trim().replace(/\./g, ""),
                time,
                location: this.state.location.trim(),
                description,
                cover_img: this.state.urlCoverImg,
                short_description,
                day: this.state.day.trim(),
                topic: this.state.topic.trim()
            };
            if (this.state.id) {
                payload.id = this.state.id
                updateEvent(payload)
            } else {
                addEvent(payload)
                this.setState(this.getDefaultState);
            }
            history.goBack();
        }
    }

    rejectForm() {
        const { id, allSrcImgArr } = this.state
        const { deleteUnnecesaryImage } = this.props
        // Add form => Dispatch delete all source image
        if (!id && allSrcImgArr.length) {
            deleteUnnecesaryImage({ img_urls: allSrcImgArr });
        }
        // Using router navigate to previous page
        history.goBack()
    }

    setPrice = (price) => {
        let validPrice = ""
        price = price.replace(/\./g, "")
        for (let i = 0; i < price.length; i++) {
            const char = price.charAt(i)
            if (char.match(/[0-9]/))
                validPrice += char
            if (i !== price.length - 1 && (price.length - i - 1) % 3 === 0)
                validPrice += "."
        }
        this.setState({ price: validPrice })
    }

    setDate = value => {
        if(value.length <= 10) {
            const time = Array.from(value).filter(char => char.match(/[0-9/]/)).join("")
            this.setState({
                time
            })
        }
    }

    getDateFromDateString = dateStr => {
        const tempArr = dateStr.split("/")
        let date, month, year
        if(tempArr.length !== 3)
            return
        date = parseInt(tempArr[0])
        month = parseInt(tempArr[1])
        year = parseInt(tempArr[2])
        if(isNaN(date) || isNaN(month) || isNaN(year))
            return
        if(date < 1 || date > 31 || month < 1 || month > 12 || year < 1980 || year > 2100)
            return
        if(date === 31 && ![1,3,5,7,8,10,12].find(m => m === month))
            return
        if(month === 2 && date > 29)
            return
        if(month === 2 && date === 29 && !this.isLeafYear(year))
            return
        return `${year}-${month}-${date}`
    }

    isLeafYear = year => year % 400 === 0 || (year % 4 === 0 && year % 100 !== 0)

    render() {
        return (
            <div className="wrap-event-form">
                <Container className="form-container">
                    <Row>
                        <h1 className="header-form">
                            {
                                this.state.id
                                    ? "Cập nhật Tour: "
                                    : "Tạo tour mới"
                            }
                            <span
                                style={{ color: "firebrick" }}>
                                {this.state.id
                                    ? sessionStorage.getItem('event.name') || ""
                                    : ""}
                            </span>
                        </h1>
                    </Row>
                    <Row>
                        <Col>
                            <Row className="wrap-field">
                                <Col sm={4} className="header-col" >
                                    <Form.Label >Thời gian (DD/MM/YYYY)</Form.Label>
                                </Col>
                                <Col >
                                    <Form.Control
                                        type="text"
                                        value={this.state.time}
                                        onChange={(e) => this.setDate(e.target.value)} />
                                </Col>
                            </Row>

                            <Row className="wrap-field">
                                <Col sm={4} className="header-col">
                                    <Form.Label>Giá tiền ($)</Form.Label>
                                </Col>
                                <Col>
                                    <Form.Control
                                        type="text"
                                        value={this.state.price}
                                        onChange={e => this.setPrice(e.target.value)} />
                                </Col>
                            </Row>

                            <Row className="wrap-field">
                                <Col sm={4} className="header-col">
                                    <Form.Label>Tên tour</Form.Label>
                                </Col>
                                <Col>
                                    <Form.Control
                                        type="text"
                                        value={this.state.name}
                                        onChange={(e) => this.setState({ name: e.target.value.trimStart() })} />
                                </Col>
                            </Row>

                            <Row className="wrap-field">
                                <Col sm={4} className="header-col">
                                    <Form.Label>Điểm đến</Form.Label>
                                </Col>
                                <Col>
                                    <Form.Control
                                        type="text"
                                        value={this.state.location}
                                        onChange={(e) => this.setState({ location: e.target.value.trimStart() })} />
                                </Col>
                            </Row>

                            <Row className="wrap-field">
                                <Col sm={4} className="header-col">
                                    <Form.Label>Số ngày</Form.Label>
                                </Col>
                                <Col>
                                    <Form.Control
                                        type="text"
                                        value={this.state.day}
                                        onChange={(e) => this.setState({ day: e.target.value.trimStart() })} />
                                </Col>
                            </Row>

                            <Row className="wrap-field">
                                <Col sm={4} className="header-col">
                                    <Form.Label>Chủ đề</Form.Label>
                                </Col>
                                <Col>
                                    <Form.Control
                                        type="text"
                                        value={this.state.topic}
                                        onChange={(e) => this.setState({ topic: e.target.value.trimStart() })} />
                                </Col>
                            </Row>
                        </Col>
                        <Col className="wrap-background-col">
                            <label className="lb-background">
                                <img
                                    src={this.state.urlCoverImg}
                                    alt=""
                                />
                                <div className="mask">
                                    <h5 className="wrap-text">{this.state.urlCoverImg ? "Thay thế ảnh" : "Chọn ảnh"}</h5>
                                </div>
                                <input
                                    type="file"
                                    onChange={(e) => this.handleSelectCoverImg(e.target.files[0])} />
                            </label>
                            <Form.Label className="wrap-header-bgr">Ảnh đại diện</Form.Label>
                        </Col>

                    </Row>
                    <Row>
                        <Col>
                            <Row >
                                <Col className="header-col-content">
                                    <Form.Label>Giới thiệu tour</Form.Label>
                                </Col>
                            </Row>
                            <Row className="wrap-editor">
                                <Editor
                                    addSourceImageToParent={(url) => this.addNewSourceImage(url)}
                                    content={this.state.description} />
                            </Row>
                        </Col>
                    </Row>
                    <Row className="wrap-action-button">
                        <Button
                            variant="danger"
                            onClick={() => this.rejectForm()}>Hủy bỏ</Button>
                        <Button
                            variant="success"
                            onClick={() => this.submitForm()}>Xác nhận</Button>
                    </Row>
                    {/* <div id="quan"></div> */}
                </Container>
            </div>
        )
    }
}