import * as types from '../../constant'
export default function callApi(data){
    return new Promise((resolve,reject)=>{

        const url = types.domain+"/update-us-info"
        fetch(url,{
            method: "PUT",
            body: JSON.stringify(data),
            headers:{"Content-type":"application/json"},
        })
        .then((response) => response.json())
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
    })
})
}