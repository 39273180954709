import React, { Component } from 'react'
import { connect } from 'react-redux'
import * as component from '../components/abouts/index'
import * as introsActions from '../actions/abouts/IntroActions'
import * as faqsActions from '../actions/abouts/FaqsActions'
import * as termsActions from '../actions/abouts/TermsActions'
import { Tabs, Tab } from 'react-bootstrap';
class AboutContainer extends Component {
    componentDidMount() {
        this.props.initLoad()
    }
    render() {
        return (
            <div>
                <Tabs defaultActiveKey="intro" transition={false} id="noanim-tab-example">
                    <Tab eventKey="intro" title="Giới Thiệu">
                        <component.Intro {...this.props} />
                        <component.ImgMember {...this.props} />
                        <component.AddImgMember {...this.props} />
                    </Tab>
                    <Tab eventKey="faqs" title="FAQs">
                        <component.FAQ {...this.props} />
                        <component.addFAQ {...this.props} />
                    </Tab>
                    <Tab eventKey="terms" title="Điều Khoản">
                        <component.Term {...this.props} />
                        <component.addTerm {...this.props} />
                    </Tab>
                </Tabs>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        intro: state.about.listIntro,
        members:state.about.listMember,
        faqs: state.about.listFAQs,
        term: state.about.listTerm,
        isLoading: state.about.isFetching,

    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        initLoad: () => {
            dispatch(introsActions.getText());
            dispatch(introsActions.getMem());
            dispatch(faqsActions.getFAQ());
            dispatch(termsActions.getTerm());
        },
        //phần giới thiệu(Intro)
        updateTextIntro: (data) => {
            dispatch(introsActions.updateText(data))
        },
        //phần giới thiệu đội ngũ(Member)
        addMem:(data) => {
            dispatch(introsActions.addMem(data))
        },
        updateMem:(data) => {
            dispatch(introsActions.updateMem(data))
        },
        deleteMem:(id) => {
            dispatch(introsActions.deleteMem(id))
        },
        //phần câu hỏi thường gặp
        addFAQ: (data) => {
            dispatch(faqsActions.addFAQ(data))
        },
        updateFAQ: (data) => {
            dispatch(faqsActions.updateFAQ(data))
        },
        deleteFAQ: (id) => {
            dispatch(faqsActions.deleteFAQ(id))
        },
        //phần điều khoản sử dụng(Terms)
        addTerm: (data) => {
            dispatch(termsActions.addTerm(data))
        },
        updateTerm: (data) => {
            dispatch(termsActions.updateTerm(data))
        },
        deleteTerm: (id) => {
            dispatch(termsActions.deleteTerm(id))
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AboutContainer)