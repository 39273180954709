import { put, takeEvery } from 'redux-saga/effects'
import * as types from '../constant'
import get from '../fetchAPI/USBeautyFetchAPI/Topic/getTopic'
import add from '../fetchAPI/USBeautyFetchAPI/Topic/addTopic'
import edit from '../fetchAPI/USBeautyFetchAPI/Topic/editTopic'
import deleteType from '../fetchAPI/USBeautyFetchAPI/Topic/deleteTopic'

function* getTopic(action) {
    try {
        const res = yield get(action.payload);
        yield put({
            type: types.GET_USBEAUTYTOPIC_SUCCESS,
            payload: res
        });
    } catch (error) {
        yield put({
            type: types.GET_USBEAUTYTOPIC_FAILURE,
            payload: {
                errorMessage: error.message
            }
        });
    }
}
function* addTopic(action) {
    try {
      const res = yield add(action.payload);
      yield put({
        type: types.ADD_USBEAUTYTOPIC_SUCCESS,
        payload: res
      });
  
      yield put({
        type: types.GET_USBEAUTYTOPIC_REQUEST,
        payload: 1
      })
  
    } catch (error) {
      yield put({
        type: types.ADD_USBEAUTYTOPIC_FAILURE,
        payload: {
          errorMessage: error.message
        }
      });
    }
  }
  function* editTopic(action) {
    try {
      const res = yield edit(action.payload);
      yield put({
        type: types.EDIT_USBEAUTYTOPIC_SUCCESS,
        payload: res
      });
      yield put({
        type: types.GET_USBEAUTYTOPIC_REQUEST,
        payload: 1
      });
    } catch (error) {
      yield put({
        type: types.EDIT_USBEAUTYTOPIC_FAILURE,
        payload: {
          errorMessage: error.message
        }
      });
    }
  }

  function* deleteTopic(action) {
    try {
      const res = yield deleteType(action.payload);
      yield put({
        type: types.DELETE_USBEAUTYTOPIC_SUCCESS,
        payload: res
      });
      yield put({
        type: types.GET_USBEAUTYTOPIC_REQUEST,
        payload: 1
      });
    } catch (error) {
      yield put({
        type: types.DELETE_USBEAUTYTOPIC_FAILURE,
        payload: {
          errorMessage: error.message
        }
      });
    }
  }
export const UsBeautyTopicSaga = [
    takeEvery(types.GET_USBEAUTYTOPIC_REQUEST, getTopic),
    takeEvery(types.ADD_USBEAUTYTOPIC_REQUEST, addTopic),
    takeEvery(types.EDIT_USBEAUTYTOPIC_REQUEST, editTopic),
    takeEvery(types.DELETE_USBEAUTYTOPIC_REQUEST, deleteTopic),
    // takeEvery(types.SEARCH_USAINFOR_REQUEST, searchUsaInfor)
];