import * as types from '../constant';
export function getListTour(payload){
  return ({
    type: types.GET_POPULAR_TOUR_REQUEST,
    payload
  });
}

export function updateTour(payload){
  return ({
    type: types.UPDATE_POPULAR_TOUR_REQUEST,
    payload
  });
}

export function deleteTour(payload){
    return ({
      type: types.DELETE_POPULAR_TOUR_REQUEST,
      payload
    });
  }

  export function createTour(payload){
    return ({
      type: types.CREATE_POPULAR_TOUR_REQUEST,
      payload
    });
  }
  export function searchTour(payload){
    return ({
      type: types.SEARCH_POPULAR_TOUR_REQUEST,
      payload
    });
  }

