import * as types from '../constant';

export function getListUSBEAUTY(payload){
    return({
        type:types.GET_USBEAUTY_REQUEST,
        payload
    })
}
export function addUSBEAUTY(payload){
    return({
        type:types.ADD_USBEAUTY_REQUEST,
        payload
    })
}

export function editUSBEAUTY(payload){
    return({
        type:types.EDIT_USBEAUTY_REQUEST,
        payload
    })
}

export function deleteUSBEAUTY(payload){
    return({
        type:types.DELETE_USBEAUTY_REQUEST,
        payload
    })
}
export function search(payload){
    return ({
        type:types.SEARCH_USBEAUTY_REQUEST,
        payload
    });
}

export function getTopic(payload){
    return({
        type:types.GET_USBEAUTYTOPIC_REQUEST,
        payload
    })
}
export function addTopic(payload){
    return({
        type:types.ADD_USBEAUTYTOPIC_REQUEST,
        payload
    })
}

export function editTopic(payload){
    return({
        type:types.EDIT_USBEAUTYTOPIC_REQUEST,
        payload
    })
}

export function deleteTopic(payload){
    return({
        type:types.DELETE_USBEAUTYTOPIC_REQUEST,
        payload
    })
}

export function refresh(payload){
    return({
        type:types.REFRESH_USBEAUTY_REQUEST,
        payload
    })
}
