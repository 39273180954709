import {domain} from '../../constant'
export default function editSlide(data){
    return new Promise((resolve,rejects)=>{
        const url = domain + '/update-bgr-img/'
        fetch(url,{
            method : "PUT",
            body: data
        })
        .then((response) => response.json())
        .then((res) =>{
            resolve(res);
        })
        .catch((error) =>{
            rejects(error);
        });
    });
}