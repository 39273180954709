import {domain} from '../../constant'
export default function addSlide(data){
    return new Promise((resolve,rejects)=>{
        const url = domain + '/add-bgr-img'
        fetch(url,{
            method : "POST",
            body: data
        })
        .then((response) => response.json())
        .then((res) =>{
            resolve(res);
        })
        .catch((error) =>{
            rejects(error);
        });
    });
}