import * as types from '../constant';

const DEFAULT_STATE = {
    listTour: null,
    errorMessage: "",
    isFetching: false,
};

export default (state = DEFAULT_STATE, action) => {
    switch (action.type) {
        case types.GET_POPULAR_TOUR_REQUEST:
            return {
                ...state,
                isFetching: true
            }
        case types.GET_POPULAR_TOUR_SUCCESS:
            return {
                ...state,
                listTour: action.payload,
                isFetching: false
            }
        case types.GET_POPULAR_TOUR_FAILURE:
            return {
                ...state,
                errorMessage: action.payload.errorMessage,
                listTour: null,
                isFetching: false
            }


        case types.CREATE_POPULAR_TOUR_REQUEST:
            return {
                ...state,
                isFetching: true
            }
        case types.CREATE_POPULAR_TOUR_SUCCESS:
            return {
                ...state,
                isFetching: false
            };
        case types.CREATE_POPULAR_TOUR_FAILURE:
            return {

                ...state,
                errorMessage: action.payload.errorMessage,
                isFetching: false

            }
        case types.UPDATE_POPULAR_TOUR_REQUEST:
            return {

                ...state,
                isFetching: true

            }
        case types.UPDATE_POPULAR_TOUR_SUCCESS:
            return {
                ...state,
                isFetching: false
            }
        case types.UPDATE_POPULAR_TOUR_FAILURE:
            return {

                ...state,
                errorMessage: action.payload.errorMessage,
                isFetching: false

            }

        // case types.SEARCH_POPULAR_TOUR_REQUEST:
        // return{
        //     ...state
        // }
        // case types.SEARCH_POPULAR_TOUR_SUCCESS:
        //     return {
        //         ...state,
        //         listTour: action.payload

        //     };
        // case types.SEARCH_POPULAR_TOUR_FAILURE:
        //     return {
        //         ...state,
        //         errorMessage: action.payload.errorMessage,
        //     }

        case types.DELETE_POPULAR_TOUR_REQUEST:
            return {
                ...state,
                isFetching: true
            };
        case types.DELETE_POPULAR_TOUR_SUCCESS:
            return {
                ...state,
                isFetching: false
            };
        case types.DELETE_POPULAR_TOUR_FAILURE:
            return {

                ...state,
                errorMessage: action.payload.errorMessage,
                isFetching: false

            }
        default:
            return state;
    }
}