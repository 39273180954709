import * as types from '../../constant'
export default function callAPI(data) {
    return new Promise((resolve, reject) => {
        const url = types.domain+'/search-us-info/10/'+data.offset+'/'+data.text
        fetch(url, {
          method: "GET"
        })
          .then((response) => response.json())
          .then((res) => {
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      });
}