import {put, takeEvery} from 'redux-saga/effects'
import EditSlideAPI from '../../fetchAPI/HomeAdmin/EditSlideAPI'
import * as types from '../../constant';
function* editSlide(action){
    try{
        const response = yield EditSlideAPI(action.payload)
        yield put({
            type: types.EDIT_IMAGE_SUCCESS,
            payload: response
        })
        yield put({
            type:types.GET_IMAGE_REQUEST
        })

    }catch (error){
        yield put({
            type:types.EDIT_IMAGE_FAILURE,
            payload:{
                errorMessage : error.message
            }
        })
    }
}
export const EditImageSaga = [
    takeEvery(types.EDIT_IMAGE_REQUEST,editSlide)
]