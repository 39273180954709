import {put, takeEvery} from 'redux-saga/effects'
import EditImageEvents from '../../fetchAPI/HomeAdmin/EditImageEvent'
import * as types from '../../constant';
function* upLoadImageNew(action){
    try{
        const response = yield EditImageEvents(action.payload)
        yield put({
            type: types.EDIT_IMAGE_EVENT_SUCCESS,
            payload: response
        })
        yield put({
            type:types.GET_IMAGE_EVENT_REQUEST
        })

    }catch (error){
        yield put({
            type:types.EDIT_IMAGE_EVENT_FAILURE,
            payload:{
                errorMessage : error.message
            }
        })
    }
}
export const EditImageEvent = [
    takeEvery(types.EDIT_IMAGE_EVENT_REQUEST,upLoadImageNew)
]