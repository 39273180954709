import { put, takeEvery } from 'redux-saga/effects';
import GetImageEvents from '../../fetchAPI/HomeAdmin/GetImageEvent';
import * as types from '../../constant';
function* getImageEvent() {
    try {
      const res = yield GetImageEvents();
      yield put({
        type: types.GET_IMAGE_EVENT_SUCCESS,
        payload: res
      });
    } catch (error) {
      yield put({
        type: types.GET_IMAGE_EVENT_FAILURE,
        payload: {
          errorMessage: error.message
        }
      });
    }
  }
  
  export const GetImageEvent = [
    takeEvery(types.GET_IMAGE_EVENT_REQUEST, getImageEvent)
  ];
  
  