import { put, takeEvery } from 'redux-saga/effects';
import getTextIntroAPI from '../fetchAPI/abouts/introAPI/textIntroAPI/getTextIntroAPI'
import updateTextIntroAPI from '../fetchAPI/abouts/introAPI/textIntroAPI/updateTextIntroAPI'
import addMemberAPI from '../fetchAPI/abouts/introAPI/memberAPI/addMemberAPI'
import getMemberAPI from '../fetchAPI/abouts/introAPI/memberAPI/getMemberAPI'
import updateMemberAPI from '../fetchAPI/abouts/introAPI/memberAPI/updateMemberAPI'
import deleteMemberAPI from '../fetchAPI/abouts/introAPI/memberAPI/deleteMemberAPI'
import getFAQsAPI from '../fetchAPI/abouts/faqsAPI/getFAQsAPI'
import addFAQsAPI from '../fetchAPI/abouts/faqsAPI/addFAQsAPI'
import updateFAQsAPI from '../fetchAPI/abouts/faqsAPI/updateFAQsAPI'
import deleteFAQsAPI from '../fetchAPI/abouts/faqsAPI/deleteFAQsAPI'
import getTextTermAPI from '../fetchAPI/abouts/termsAPI/getTermsAPI'
import addTermsAPI from '../fetchAPI/abouts/termsAPI/addTermsAPI'
import updateTermsAPI from '../fetchAPI/abouts/termsAPI/updateTermsAPI'
import deleteTermsAPI from '../fetchAPI/abouts/termsAPI/deleteTermsAPI'
import * as types from '../constant';
// Xử lý phần Giới thiệu
function* getIntro() {
    try {
        const response = yield getTextIntroAPI()
        yield put({
            type: types.GET_TEXT_INTRO_SUCCESS,
            payload: response.texts
        })
    } catch (error) {
        yield put({
            type: types.GET_TEXT_INTRO_FAILURE,
            payload: {
                errorMessage: error.message
            }
        })
    }
}
function* updateIntro(action) {
    try {
        yield updateTextIntroAPI(action.payload.headerTxt);
        yield updateTextIntroAPI(action.payload.footerTxt);
        yield put({
            type: types.UPDATE_TEXT_INTRO_SUCCESS,
            
        })
        yield put({
            type: types.GET_TEXT_INTRO_REQUEST
        })
    } catch (error) {
        yield put({
            type: types.UPDATE_TEXT_INTRO_FAILURE,
            payload: {
                errorMessage: error.message
            }
        })
    }
}
//Xử lý phần Đội ngũ
function* addMember(action){
    try{
        const response = yield addMemberAPI(action.payload);
        yield put({
            type:types.ADD_MEM_INTRO_SUCCESS,
            payload:response
        })
        yield put({
            type:types.GET_MEM_INTRO_REQUEST
        })
    }catch(error){
        yield put({
            type:types.ADD_MEM_INTRO_FAILURE,
            payload:{
                errorMessage:error.message
            }
        })
    }
}
function* getMember(action){
    try{
        const response = yield getMemberAPI();
        yield put({
            type:types.GET_MEM_INTRO_SUCCESS,
            payload:response.members
        })
    }catch(error){
        yield put({
            type:types.GET_MEM_INTRO_FAILURE,
            payload:{
                errorMessage:error.message
            }
        })
    }
}
function* updateMember(action){
    try{
        const response = yield updateMemberAPI(action.payload);
        yield put({
            type:types.UPDATE_MEM_INTRO_SUCCESS,
            payload:response
        })
        yield put({
            type:types.GET_MEM_INTRO_REQUEST
        })
    }catch(error){
        yield put({
            type:types.UPDATE_MEM_INTRO_FAILURE,
            payload:{
                errorMessage:error.message
            }
        })
    }
}
function* deleteMember(action){
    try{
        const response = yield deleteMemberAPI(action.payload);
        yield put({
            type:types.DELETE_MEM_INTRO_SUCCESS,
            payload:response
        })
        yield put({
            type:types.GET_MEM_INTRO_REQUEST
        })
    }catch(error){
        yield put({
            type:types.DELETE_MEM_INTRO_FAILURE,
            payload:{
                errorMessage:error.message
            }
        })
    }
}
// Xử lý phần FAQs
function* getFAQs() {
    try {
        const response = yield getFAQsAPI()
        yield put({
            type: types.GET_FAQ_SUCCESS,
            payload: response.faq
        })
    } catch (error) {
        yield put({
            type: types.GET_FAQ_FAILURE,
            payload: {
                errorMessage: error.message
            }
        })
    }
}
function* addFAQs(action) {
    try {
        const response = yield addFAQsAPI(action.payload)
        yield put({
            type: types.ADD_FAQ_SUCCESS,
            payload: response
        })
        yield put({
            type: types.GET_FAQ_REQUEST
        })
    } catch (error) {
        yield put({
            type: types.ADD_FAQ_FAILURE,
            payload: {
                errorMessage: error.message
            }
        })
    }
}
function* updateFAQs(action) {
    try {
        const response = yield updateFAQsAPI(action.payload)
        yield put({
            type: types.UPDATE_FAQ_SUCCESS,
            payload: response
        })
        yield put({
            type: types.GET_FAQ_REQUEST
        })
    } catch (error) {
        yield put({
            type: types.UPDATE_FAQ_FAILURE,
            payload: {
                errorMessage: error.message
            }
        })
    }
}
function* deleteFAQs(action) {
    try {
        const response = yield deleteFAQsAPI(action.payload)
        yield put({
            type: types.DELETE_FAQ_SUCCESS,
            payload: response
        })
        yield put({
            type: types.GET_FAQ_REQUEST
        })
    } catch (error) {
        yield put({
            type: types.DELETE_FAQ_FAILURE,
            payload: {
                errorMessage: error.message
            }
        })
    }
}
//Xử lý phần Terms
function* getTerms() {
    try {
        const response = yield getTextTermAPI()
        yield put({
            type: types.GET_TERMS_SUCCESS,
            payload: response.terms
        })
    } catch (error) {
        yield put({
            type: types.GET_TERMS_FAILURE,
            payload: {
                errorMessage: error.message
            }
        })
    }
}
function* addTerm(action) {
    try {
        const response = yield addTermsAPI(action.payload)
        yield put({
            type: types.ADD_TERMS_SUCCESS,
            payload: response
        })
        yield put({
            type: types.GET_TERMS_REQUEST
        })
    } catch (error) {
        yield put({
            type: types.ADD_TERMS_FAILURE,
            payload: {
                errorMessage: error.message
            }
        })
    }
}
function* updateTerms(action) {
    try {
        const response = yield updateTermsAPI(action.payload);

        yield put({
            type: types.UPDATE_TERMS_SUCCESS,
            payload: response
        })
        yield put({
            type: types.GET_TERMS_REQUEST
        })
    } catch (error) {
        yield put({
            type: types.UPDATE_TERMS_FAILURE,
            payload: {
                errorMessage: error.message
            }
        })
    }
}
function* deleteTerms(action) {
    try {
        const response = yield deleteTermsAPI(action.payload);

        yield put({
            type: types.DELETE_TERMS_SUCCESS,
            payload: response
        })
        yield put({
            type: types.GET_TERMS_REQUEST
        })
    } catch (error) {
        yield put({
            type: types.DELETE_TERMS_FAILURE,
            payload: {
                errorMessage: error.message
            }
        })
    }
}
export const AboutSaga = [
    takeEvery(types.GET_TEXT_INTRO_REQUEST, getIntro),
    takeEvery(types.UPDATE_TEXT_INTRO_REQUEST, updateIntro),
    takeEvery(types.ADD_MEM_INTRO_REQUEST, addMember),
    takeEvery(types.GET_MEM_INTRO_REQUEST, getMember),
    takeEvery(types.UPDATE_MEM_INTRO_REQUEST, updateMember),
    takeEvery(types.DELETE_MEM_INTRO_REQUEST, deleteMember),
    takeEvery(types.GET_FAQ_REQUEST, getFAQs),
    takeEvery(types.ADD_FAQ_REQUEST, addFAQs),
    takeEvery(types.UPDATE_FAQ_REQUEST, updateFAQs),
    takeEvery(types.DELETE_FAQ_REQUEST, deleteFAQs),
    takeEvery(types.GET_TERMS_REQUEST, getTerms),
    takeEvery(types.ADD_TERMS_REQUEST,addTerm),
    takeEvery(types.UPDATE_TERMS_REQUEST, updateTerms),
    takeEvery(types.DELETE_TERMS_REQUEST,deleteTerms)
]