import { put, takeEvery } from 'redux-saga/effects';
import getText from '../../fetchAPI/HomeAdmin/gettext';

import * as types from '../../constant';
function* getTextTour() {
    try {
      const res = yield getText();
      
      yield put({
        type: types.GET_TEXT_SUCCESS,
        payload: res
      });
    } catch (error) {
      yield put({
        type: types.GET_TEXT_FAILURE,
        payload: {
          errorMessage: error.message
        }
      });
    }
  }
  
  export const TextSaga = [
    takeEvery(types.GET_TEXT_REQUEST, getTextTour)
  ];
  
  