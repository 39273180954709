import React, { Component } from 'react';
import AddTopicComponent from './AddTopicComponent'
import LoadingModal from '../commons/LoadingModal'
class USBeautyTopic extends Component {
    constructor(props) {
        super(props);
        this.state = {
            topicName: ''
        };
    }
    check() {
        if (this.state.type === '') {
            return false
        } else {
            return true
        }
    }
    addTopic(data) {
        this.props.addTopic(data)
    }
    editTopic(data) {
        if (this.state.topicName !== '' && data !== this.state.topicName) {
            if (this.check()) {
                this.props.editTopic({
                    curr_type_name: data,
                    new_type_name: this.state.topicName.trim()
                })
            }
        }
    }
    deleteTopic(data) {
        this.props.deleteTopic(data)
    }
    render() {

        let listTopic
        if (this.props.dataTopic) {
            listTopic = this.props.dataTopic.map((item, key) => {
                let edit = "edit" + key
                let deleteData = "delete" + key
                return (
                    <tr className="colum2" key={key}>
                        <td>{key + 1}</td>
                        <td>{item.name}</td>
                        <td>
                            <button type="button" className="btn btn-primary"
                                style={{ marginRight: 5 }}
                                data-toggle="modal" data-target={"#" + edit}
                                onClick={() => {
                                    this.setState({
                                        topicName: item.name
                                    })
                                }}>Sửa</button>

                            <div className="modal fade" id={edit} role="dialog">
                                <div className="modal-dialog">
                                    {/* Modal content*/}
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h3 className="modal-title" style={{ color: "#007bff", width: "100%", textAlign: "center" }} >Sửa Tên Chủ Đề</h3>

                                            <button type="button" className="close" data-dismiss="modal">×</button>
                                        </div>
                                        <div className="modal-body">
                                            <div>
                                                <label className="name-label" >Chủ Đề:</label>
                                                <input type="text" value={this.state.topicName} className="form1" placeholder="Tên Chủ Đề"
                                                    onChange={(e) => { this.setState({ topicName: e.target.value }) }} />
                                                <div className="clear" ></div>
                                            </div>

                                            <div className="modal-footer">
                                                <button type="button" className="btn btn-danger"
                                                    data-dismiss="modal">Hủy</button>
                                                <button type="button" className="btn btn-success"
                                                    data-dismiss="modal"
                                                    onClick={() => this.editTopic(item.name)}>Xác nhận</button>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <button type="button" className="btn btn-danger" data-toggle="modal"
                                data-target={"#" + deleteData} >Xóa</button>
                            <div className="modal fade" id={deleteData} role="dialog">
                                <div className="modal-dialog modal-sm">
                                    <div className="modal-content">
                                        <div className="modal-header" style={{ color: "red" }}>

                                            <h4 className="modal-title" style={{ width: "100%", textAlign: "center" }} >
                                                Thao tác này sẽ xóa những bài viết có chủ đề này.
                                            Bạn có chắc chắn muốn xóa không ?</h4>
                                            <button type="button" className="close" data-dismiss="modal">×</button>
                                        </div>
                                        <div className="modal-footer">
                                            <button type="button" className="btn btn-danger"
                                                data-dismiss="modal" >Hủy</button>
                                            <button type="button" className="btn btn-success" data-dismiss="modal"
                                                onClick={() => this.deleteTopic(item.name)} >Xác nhận</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </td>
                    </tr>
                )
            })
        }

        return (
            <div>
                <div className="table-responsive">
                    {this.props.isLoadingTopic ? <LoadingModal /> :
                        <table className="table table-striped">
                            <thead>
                                <tr className="table-dark text-dark">
                                    <th>STT</th>
                                    <th>Tên Chủ Đề</th>
                                    <th>Thao tác</th>
                                </tr>
                            </thead>
                            <tbody>
                                {listTopic}
                            </tbody>
                        </table>
                    }
                    <AddTopicComponent addTopic={(data) => {
                        this.addTopic(data)
                    }} />
                </div>
            </div>
        );
    }

}

export default USBeautyTopic;