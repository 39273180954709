import { combineReducers } from 'redux';
import TourScheduleReducer from './TourScheduleReducer';
import HomePageReducer from './HomePageReducer';
import USBeautyReducer from "./USBeautyReducer";
import UsefulInforReducer from './UsefulInforReducer'
import UsaInforReducer from './UsaInforReducer'
import ContactReducer from "./ContactReducer"
import PriceRequestReducer from "./PriceRequestReducer"
import AboutReducer from './AboutReducer'
import EventReducer from './EventReducer'
import PopularTourReducer from "./PopularTourReducer"
import USBeautyTopicReducer from './USBeautyTopicReducer';
import LoginReducer from './LoginReducer';
import CommonReducer from './CommonReducer';

export default combineReducers({
  text: HomePageReducer,
  usBeauty: USBeautyReducer,
  usefulInfor: UsefulInforReducer,
  usaInfor: UsaInforReducer,
  request: PriceRequestReducer, 
  contact: ContactReducer,
  about: AboutReducer,
  tours: TourScheduleReducer,
  event: EventReducer,
  popularTour:PopularTourReducer,
  type: USBeautyTopicReducer,
  login: LoginReducer,
  common: CommonReducer
});