import React, { Component } from 'react'
import '../../styles/abouts/Intro.css'
import LoadingModal from '../../commons/LoadingModal'

class Intro extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: '',
            type: '',
            text: ''
        }
    }
    handleUpdateText(headerID, footerID) {
        let textObjEdit = {
            headerTxt: { id: headerID, text: this.state.header },
            footerTxt: { id: footerID, text: this.state.footer }
        };
        this.props.updateTextIntro(textObjEdit);
        alert('Cập nhật thành công!')
    }
    render() {
        let listIntro
        if (this.props.intro) {
            let headerItem, footerItem;
            this.props.intro.forEach(item => {
                if (item.type === "contact_us_header") {
                    headerItem = item
                } else {
                    footerItem = item
                }
            });

            listIntro = (
                <div>
                    <div className="divTextIntro">
                        <div>
                            <label className="labelTextIntro">Đầu Trang</label>
                        </div>
                        <div className="divTextAreaIntro">
                            <textarea className="textAreaIntro"
                                value={headerItem.text} disabled />
                        </div>
                    </div>
                    <div className="divTextIntro">
                        <div>
                            <label className="labelTextIntro">Cuối Trang</label>
                        </div>
                        <div className="divTextAreaIntro">
                            <textarea className="textAreaIntro"
                                value={footerItem.text} disabled />
                        </div>
                    </div>
                    <div className="buttonAreaUpdate">
                        <button type="button"
                            className="btn btn-primary"
                            data-toggle="modal"
                            data-target="#myModal"
                            onClick={() => {
                                this.setState({
                                    header: headerItem.text,
                                    footer: footerItem.text
                                })
                            }}>Sửa</button>
                        <div className="modal fade" id="myModal" role="dialog">
                            <div className="modal-dialog">
                                <div className="modal-content modalTextUpdateIntro">
                                    <div className="modal-header">
                                        <h4 className="center-modal-title">Sửa nội dung giới thiệu</h4>
                                    </div>
                                    <div className="modal-body">
                                        <form className="form-horizontal">
                                            <div className="form-group divUpdateIntro">
                                                <div className="titleAddFAQ">
                                                    <label
                                                        className="control-label labelUpdateIntro">
                                                        Đầu Trang:</label>
                                                </div>
                                                <div className="divTextAreaUpdateIntro">
                                                    <textarea className="form-control textAreaUpdateIntro"
                                                        value={this.state.header}
                                                        onChange={(e) => this.setState({ header: e.target.value })}
                                                        placeholder="Nhập đầu trang giới thiệu" />
                                                </div>
                                            </div>
                                            <div className="form-group divUpdateIntro">
                                                <div className="titleAddFAQ">
                                                    <label
                                                        className="control-label labelUpdateIntro">
                                                        Cuối Trang:</label>
                                                </div>
                                                <div className="divTextAreaUpdateIntro">
                                                    <textarea className="form-control textAreaUpdateIntro"
                                                        value={this.state.footer}
                                                        onChange={(e) => this.setState({ footer: e.target.value })}
                                                        placeholder="Nhập cuối trang giới thiệu" />
                                                </div>
                                            </div>
                                        </form>
                                        <div className="btnTextIntro">
                                            <button className="btn btn-danger btnCloseUpdateIntro"
                                                data-dismiss="modal"
                                                onClick={() => this.setState({
                                                    header: headerItem.text,
                                                    footer: footerItem.text
                                                })}>Hủy</button>
                                            <button type="button"
                                                style={{ marginRight: 5 }}
                                                className="btn btn-success"
                                                data-dismiss="modal"
                                                onClick={() =>
                                                    this.handleUpdateText(headerItem._id, footerItem._id)}>Xác nhận</button>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="clearfix"></div>
                </div>
            )
        }
        return (
            <div className="divIntroduce">
                <div>
                    <h3>1. Quản lý nội dung giới thiệu</h3>
                </div>
                <div className="textIntro">
                    {(!this.props.isLoading && listIntro) ?
                        <div className="textArea">
                            {listIntro}
                        </div>
                        : <LoadingModal />}
                </div>
            </div>
        )
    }
}

export default Intro