import React from 'react';
import Table from "./Table"
import PaginationCommon from '../commons/PaginationCommon'
import LoadingModal from '../commons/LoadingModal'
class ImageTour extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dataTour: [],
      dataEvent: [],
      listUpdateTour: [],
      listUpdateEvent: []
    };
  }

  chooseHandleEvent(data) {
    this.setState({ dataEvent: [data, ...this.state.dataEvent].slice(0, 4) });

  }
  chooseHandleTour(data) {
    this.setState({ dataTour: [data, ...this.state.dataTour].slice(0, 4) });
  }
  handlePassImageEvent(data) {
    this.setState({ listUpdateEvent: data });
  }
  handlePassImageTour(data) {
    this.setState({ listUpdateTour: data });
  }
  upLoadImageEvent() {
    if (this.state.dataEvent.length === 4) {
      this.props.upLoadImageNewEvent(this.state.dataEvent.map(item => item._id));
    }
  }
  upLoadImageTour() {
    if (this.state.dataTour.length === 4) {
      this.props.upLoadImageNewTour(this.state.dataTour.map(item => item._id));
    }
  }
  render() {
    let imgsTourSelect, imgsEventSelect
    if (this.state.dataTour) {
      imgsTourSelect = this.state.dataTour.map((item, key) => {
        return (
          <img
            key={key}
            className="img-thumbnail listImage"
            src={item.cover_img.src}
            alt="khong tai dc" />
        )
      })
    }
    if (this.state.dataEvent) {
      imgsEventSelect = this.state.dataEvent.map((item, key) => {
        return (
          <img
            key={key}
            className="img-thumbnail listImage"
            src={item.cover_img.src}
            alt="khong tai dc" />
        )
      })
    }
    //get image tour
    let imgTours
    if (this.props.imgTour) {
      imgTours = this.props.imgTour.tours.map((item, key) => {
        return (
          <td key={key} className="text-center">
            <img className="img-tour" src={item.cover_img.src} alt="Không tải đc !" />
            <div >{item.name}</div>
          </td>
        )
      })
    }
    // get image event
    let imgEvents
    if (this.props.imgEvent) {
      imgEvents = this.props.imgEvent.events.map((item1, key1) => {
        return (
          <td key={key1} className="text-center">
            <img className="img-event" src={item1.cover_img} alt="Không tải đc !" />
            <div >{item1.name}</div>
          </td>
        )
      })
    }
    return (
      <div>
        <div className="modal fade" id="ListImageEvent" role="dialog">
          <div className="modal-dialog">
            {/* Modal content*/}
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="center-modal-title">Cập nhật sự kiện mới</h4>
                <button type="button" className="close" data-dismiss="modal">×</button>
              </div>
              <div className="modal-body">
                <h5>Mời bạn chọn 4 sự kiện mới :</h5>

                {/* List image db */}
                <Table {...this.props}
                  data={this.props.events.map(evt => {
                    return {...evt, cover_img: {src: evt.cover_img}}
                  })}
                  flag='event'
                  chooseHandle={(data) => {
                    this.chooseHandleEvent(data)
                  }}
                  handlePassImage={(data) => { this.handlePassImageEvent(data) }}
                />
                <div>
                  <div>
                    <PaginationCommon
                      activePage={this.props.activePageEvent}
                      totalPage={this.props.totalPagesEvent}
                      onPaginate={
                        (page) => { this.props.onEventPaginate(page) }
                      } />
                  </div>
                  {/* List image select */}
                  <h5> Danh sách sự kiện đã chọn :</h5>
                  <div className="listSelect">
                    {imgsEventSelect}
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-danger" data-dismiss="modal" >Hủy</button>
                <button type="button" className="btn btn-success" data-dismiss="modal" onClick={() => this.upLoadImageEvent()}>Xác nhận</button>
              </div>
            </div>
          </div>
        </div>
        {/* Tour Modal */}
        <div className="modal fade" id="ListImageTour" role="dialog">
          <div className="modal-dialog">
            {/* Modal content*/}
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="center-modal-title">Cập nhật tour mới</h4>
                <button type="button" className="close" data-dismiss="modal">×</button>
              </div>
              <div className="modal-body">
                <h5> Mời bạn chọn 4 tour mới :</h5>

                {/* List image db */}
                <Table {...this.props}
                  data={this.props.tours}
                  flag='tour'
                  chooseHandle={(data) => { this.chooseHandleTour(data) }}
                  handlePassImage={(data) => { this.handlePassImageTour(data) }}
                />
                <div>
                  <div>
                    <PaginationCommon
                      activePage={this.props.activePageTour}
                      totalPage={this.props.totalPagesTour}
                      onPaginate={
                        (page) => { this.props.onTourPaginate(page) }
                      } />
                  </div>
                  {/* List image select */}
                  <h5> Danh sách tour đã chọn :</h5>
                  <div className="listSelect">
                    {imgsTourSelect}
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-danger" data-dismiss="modal">Hủy</button>
                <button type="button" className="btn btn-success" data-dismiss="modal" onClick={() => this.upLoadImageTour()}>Xác nhận</button>
              </div>
            </div>
          </div>
        </div>
        {/* table */}
        <div>
          <div className="table-responsive">
            <table className="table table-striped">
              <thead>
                <tr className="table-dark text-dark">
                  <th colSpan={4}>1. TOUR MỚI - TRẢI NGHIỆM MỚI</th>
                  <th>Thao tác</th>
                </tr>
              </thead>
              <tbody>
                {(!this.props.isLoadingText && imgTours) ?
                  <tr>
                    {imgTours}
                    <td><button type="button" className="btn btn-primary" data-toggle="modal" data-target="#ListImageTour" >Sửa</button></td>
                  </tr>
                  : <LoadingModal />}
              </tbody>
            </table>
          </div>
          <div className="table-responsive">
            <table className="table table-striped">
              <thead>
                <tr className="table-dark text-dark">
                  <th colSpan={4}>2. SỰ KIỆN MỚI - CƠ HỘI MỚI</th>
                  <th>Thao tác</th>
                </tr>
              </thead>
              <tbody>
                {(!this.props.isLoadingText && imgEvents) ?
                  <tr>
                    {imgEvents}
                    <td>
                      <button type="button" className="btn btn-primary" data-toggle="modal" data-target="#ListImageEvent" >Sửa</button>

                    </td>
                  </tr>
                  : <LoadingModal />}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}

export default ImageTour;