import React from 'react';
import * as component from '../components/index';
import * as actions from '../actions/HomePageActions';
import * as actionsEvent from '../actions/EventActions';
import * as actionsTour from '../actions/TourScheduleAction';
import { connect } from 'react-redux';
import { Tabs, Tab } from 'react-bootstrap';
// import PropTypes from 'prop-types';

class HomePageContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isSearching: false,
      textSearch: ""
    };
  }
  componentDidMount() {
    this.props.initLoad();
    this.props.onEventPaginate(1);
    this.props.onTourPaginate(1);
  }
  setSearchingStatus(status, textSearch) {
    this.setState(
      { isSearching: status, textSearch: textSearch }
    );
  }
  render() {
    return (
      <div className="HomePageContainer">
        <Tabs defaultActiveKey="topic-slide" transition={false} id="noanim-tab-example">
          <Tab eventKey="topic-slide" title="Slides - Backgrounds">

            <div className="Slide">
              <component.Slide {...this.props} />
            </div>
          </Tab>
          <hr />
          <Tab eventKey="topic-TextTour" title="Lời phi lộ">
            <div className="TextTour">
              <component.TextTour {...this.props} />
            </div>
          </Tab>

          <hr />
          <Tab eventKey="topic-ImageTour" title="Ảnh tour mới - Ảnh sự kiện mới">

            <div className="ImageTour">
              <component.ImageTour {...this.props} />
            </div>
          </Tab>

          <hr />
          <Tab eventKey="topic-Info" title="Thông tin liên lạc">

            <div className="Info">
              <component.Info {...this.props} />
            </div>
          </Tab>

        </Tabs>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    imgTour: state.text.listImageTour,
    imgEvent: state.text.listImageEvent,
    text: state.text.listText,
    images: state.text.listImage,
    isLoadingText: state.text.isFetching,
    events: state.event.listEvent,
    tours: state.tours.listTour,
    activePageEvent: state.event.activePage,
    totalPagesEvent: state.event.totalPages,
    activePageTour: state.tours.actiPageTour,
    totalPagesTour: state.tours.totalPage
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    initLoad: () => {
      dispatch(actions.getTextTour());
      dispatch(actions.getListImage());
      dispatch(actions.getImageEvent());
      dispatch(actions.getImageTour());

    },
    // edit slides and backgrounds
    uploadImg: (data) => {
      dispatch(actions.editSlide(data));
    },
    // edit text tour and event and info
    edit: (listFix) => {
      dispatch(actions.edit(listFix))
    },
    addImg: (data) => {
      dispatch(actions.addSlide(data));
    },
    editTextNew: (data) => {
      dispatch(actions.editTextNew(data))
    },
    onEventPaginate: (page) => {
      dispatch(actionsEvent.getEvent(page));
    },
    upLoadImageNewEvent: (data) => {
      dispatch(actions.upLoadImageNewEvent(data))
    },
    onTourPaginate: (page) => {
      dispatch(actionsTour.getTour(page));
    },
    upLoadImageNewTour: (data) => {
      dispatch(actions.upLoadImageNewTour(data))
    },
    deleteImage: (data) => {
      dispatch(actions.deleteSlide(data));
    }
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(HomePageContainer);