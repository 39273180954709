import { put, takeEvery } from 'redux-saga/effects'
import get from '../fetchAPI/usaInforFetchAPI/getUsaInfor'
import add from '../fetchAPI/usaInforFetchAPI/addUsaInforFetchAPI'
import edit from '../fetchAPI/usaInforFetchAPI/editUsaInforFetchAPI'
import deleteUseful from '../fetchAPI/usaInforFetchAPI/deleteUsaInforFetchAPI'
import search from '../fetchAPI/usaInforFetchAPI/searchUsaInfor'
import * as types from '../constant';
import refreshAPI from '../fetchAPI/common/refreshAPI'

function* getListUsaInfor(action) {
  try {
    const res = yield get(action.payload);
    yield put({
      type: types.GET_USAINFOR_SUCCESS,
      payload: res
    });
  } catch (error) {
    yield put({
      type: types.GET_USAINFOR_FAILURE,
      payload: {
        errorMessage: error.message
      }
    });
  }
}

function* addUsaInfor(action) {
  try {
    const res = yield add(action.payload);
    yield put({
      type: types.ADD_USAINFOR_SUCCESS,
      payload: res
    });

    yield put({
      type: types.GET_USAINFOR_REQUEST,
      payload: 1
    })

  } catch (error) {
    yield put({
      type: types.ADD_USAINFOR_FAILURE,
      payload: {
        errorMessage: error.message
      }
    });
  }
}

function* editUsaInfor(action) {
  try {
    const res = yield edit(action.payload.dataAPI);
    yield put({
      type: types.EDIT_USAINFOR_SUCCESS,
      payload: res
    });
    if (action.payload.textSearch) {
      yield put({
        type: types.SEARCH_USAINFOR_REQUEST,
        payload: {
          offset: action.payload.activePage,
          text: action.payload.textSearch
        }
      })
    } else {
      yield put({
        type: types.GET_USAINFOR_REQUEST,
        payload: action.payload.activePage
      })
    }
  } catch (error) {
    yield put({
      type: types.EDIT_USAINFOR_FAILURE,
      payload: {
        errorMessage: error.message
      }
    });
  }
}

function* refresh(action) {
  try {
      const response = yield refreshAPI(action.payload.dataAPI)
      yield put({
          type: types.REFRESH_USAINFOR_SUCCESS,
          payload: response
      })
      if (action.payload.textSearch) {
        yield put({
          type: types.SEARCH_USAINFOR_REQUEST,
          payload: {
            offset: action.payload.activePage,
            text: action.payload.textSearch
          }
        })
      } else {
        yield put({
          type: types.GET_USAINFOR_REQUEST,
          payload: action.payload.activePage
        })
      }
  } catch (error) {
      yield put({
          type: types.REFRESH_USAINFOR_FAILURE,
          payload: {
              errorMessage: error.message
          }
      })
  }
}
function* deleteUsaInfor(action) {
  try {
    const res = yield deleteUseful(action.payload.dataAPI);
    yield put({
      type: types.DELETE_USAINFOR_SUCCESS,
      payload: res
    });
    let offset = action.payload.activePage
    if(action.payload.length === 1 && action.payload.totalPage > 1){
      offset -= 1
    }
    if (action.payload.textSearch) {
      yield put({
        type: types.SEARCH_USAINFOR_REQUEST,
        payload: {
          offset: offset,
          text: action.payload.textSearch
        }
      })
    } else {
      yield put({
        type: types.GET_USAINFOR_REQUEST,
        payload: offset
      })
    }
  } catch (error) {
    yield put({
      type: types.DELETE_USAINFOR_FAILURE,
      payload: {
        errorMessage: error.message
      }
    });
  }
}
function* searchUsaInfor(action) {
  try {
    const response = yield search(action.payload);
    yield put({
      type: types.SEARCH_USAINFOR_SUCCESS,
      payload: response
    })
  } catch (error) {
    yield put({
      type: types.SEARCH_USAINFOR_FAILURE,
      payload: { errorMessage: error.message }
    })
  }
}

export const UsaInformationSaga = [
  takeEvery(types.GET_USAINFOR_REQUEST, getListUsaInfor),
  takeEvery(types.ADD_USAINFOR_REQUEST, addUsaInfor),
  takeEvery(types.EDIT_USAINFOR_REQUEST, editUsaInfor),
  takeEvery(types.DELETE_USAINFOR_REQUEST, deleteUsaInfor),
  takeEvery(types.SEARCH_USAINFOR_REQUEST, searchUsaInfor),
  takeEvery(types.REFRESH_USAINFOR_REQUEST, refresh)
];