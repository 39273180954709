import React from 'react';
class SlideDelete extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: ""
    }
  }

  deleteImage() {
    this.props.deleteImage(this.state.id)
  }
  render() {
    return (
      <span>
        <button type="button" className="btn btn-danger" data-toggle="modal"
          data-target={"#" + this.props.deleteData}
          onClick={() => {
            this.setState({
              id: this.props.item._id
            })
          }}
        >Xóa</button>

        <div className="modal fade" id={this.props.deleteData} role="dialog">
          <div className="modal-dialog modal-sm">
            <div className="modal-content">
              <div className="modal-header" style={{ color: "red" }}>

                <h4 className="modal-title" style={{
                  marginLeft: "30px",
                }} >Bạn có chắc chắn muốn xóa không ?</h4>
                <button type="button" className="close" data-dismiss="modal">×</button>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-danger"
                  data-dismiss="modal" 
                  >Hủy</button>
                <button type="button" className="btn btn-success" data-dismiss="modal"
                  onClick={() => this.deleteImage()}
                   >Xác nhận</button>

              </div>
            </div>
          </div>
        </div>
      </span>

    )
  }
}
export default SlideDelete;