import React from 'react';
import '../styles/USBeauty/USBeautyComponent.css'
import { domain, POST_TYPE_FLAG, POST_ITEMS_PER_PAGE } from '../../constant'
import SearchUSBeauty from '../USBeautyComponent/SearchUSBeautyComponent'
import LoadingModal from '../commons/LoadingModal'
import { DropdownButton, Dropdown, Button } from 'react-bootstrap';
import axios from 'axios'
import history from '../../history'

class USBeautyComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      title: '',
      topic: 'Chọn chủ đề',
      author: '',
      reference: '',
      images: [
        {
          file: null,
          imgUrl: '',
          alt: '',
          content: '',
          isDeleted: false
        }
      ]
    }
  }

  handleSelectedFile(e, idx) {
    // Save a file
    let file = e.target.files[0];
    // None file selected or invalid file => Negate access resource
    if (!file || !file.name.match(/.(jpg|jpeg|png)$/gi)) {
      return;
    }
    // Copy value of current array to a temp array
    let tempImgs = this.state.images;
    // Change value of selected element in array
    let url = URL.createObjectURL(file);
    tempImgs[idx] = {
      ...tempImgs[idx],
      file: file,
      imgUrl: url
    };
    // Set old array to new array
    this.setState({ images: tempImgs });
    // Reset value => Make onChange active everytime we browse file 
    e.target.value = "";
  }

  handleContentChange(e, idx) {
    // Copy value of current array to a temp array
    let tempImgs = this.state.images;
    // Change value of selected element in array
    tempImgs[idx] = {
      ...tempImgs[idx],
      content: e.target.value
    };
    // Set old array to new array
    this.setState({
      images: tempImgs
    });
  }

  handleAltChange(e, idx) {
    // Copy value of current array to a temp array
    let tempImgs = this.state.images;
    // Change value of selected element in array
    tempImgs[idx] = {
      ...tempImgs[idx],
      alt: e.target.value
    };
    // Set old array to new array
    this.setState({
      images: tempImgs
    });
  }

  navigateToAddForm = () => {
    // Reset id in storage
    window.sessionStorage.setItem("post.id", "");
    window.sessionStorage.setItem("post.type", POST_TYPE_FLAG.USA_BEAUTY);
    // Navigate to post form page
    history.push('/post-form')
  }

  navigateToEditForm(post) {
    // Set post's value to storage
    window.sessionStorage.setItem("post.id", post._id);
    window.sessionStorage.setItem("post.type", POST_TYPE_FLAG.USA_BEAUTY);
    window.sessionStorage.setItem("post.title", post.title);
    window.sessionStorage.setItem("post.topic", post.topic);
    window.sessionStorage.setItem("post.author", post.author);
    window.sessionStorage.setItem("post.description", post.description);

    // Navigate to postform page
    history.push('/post-form');
}

  handleAdd = () => {
    this.setState({
      images: this.state.images.concat(
        [{
          file: null,
          imgUrl: "",
          alt: "",
          content: "",
          isDeleted: false
        }]
      )
    });
  };

  handleRemove = idx => () => {
    // Copy value of current array to a temp array
    let tempImgs = this.state.images;
    // Change value of selected element in array
    tempImgs[idx] = {
      file: null,
      imgUrl: "",
      alt: "",
      content: "",
      isDeleted: true
    };
    // Set old array to new array
    this.setState({
      images: tempImgs
    });
  };

  check() {
    if (this.state.title === '' || this.state.author === '' || this.state.reference === '') {
      alert('Fill enough please')
      return false
    } else {
      return true
    }
  }

  saveAllImagesToState(description) {
    description.forEach((item, idx) => {
      if (item.src) {
        axios({
          url: domain + '/file/' + item.src,
          method: "GET",
          responseType: "blob"
        })
          .then(response => {
            // let url = URL.createObjectURL(response.data);
            //this.setState({tempURL: url})
            let file = new File([response.data], item.src)
            let tempArr = this.state.images;
            tempArr[idx].file = file;
            // tempArr[idx].imgUrl = url;
            this.setState({ images: tempArr });
          })
          .catch(err => {
            // handle error
            console.log(err);
          })
      }
    });
  }

  editBeauty(id) {
    let dataAPI = new FormData();
    this.state.images.forEach(e => {
      if (e.file) {
        dataAPI.append("images", e.file, e.file.name);
        dataAPI.append("img_idx", 1);
        // dataAPI.append("alts", e.alt.trim() ? e.alt.trim() : null);
      } else {
        dataAPI.append("img_idx", 0)
        // dataAPI.append("alts", null);
      }
      dataAPI.append("alts", e.alt.trim() ? e.alt.trim() : null);
      dataAPI.append("contents", e.content);
    });
    dataAPI.append("title", this.state.title.trim());
    dataAPI.append("id", id);
    dataAPI.append("topic", this.state.topic);
    dataAPI.append("author", this.state.author.trim());
    dataAPI.append("reference", this.state.reference.trim());
    let payload = {
      dataAPI: dataAPI,
      textSearch: this.props.textSearch,
      activePage: this.props.dataActivePage
    }
    if (this.check()) {
      this.props.editUSBEAUTY(payload);
      this.setState({
        title: '',
        author: '',
        reference: ''
      });
    }
  }
  deleteBeauty(dataDelete) {
    let payload = {
      dataAPI: dataDelete._id,
      textSearch: this.props.textSearch,
      activePage: this.props.dataActivePage,
      length: this.props.dataContainer.length,
      totalPage: this.props.total_pages
    }
    this.props.deleteUSBEAUTY(payload)
  }

  render() {
    let userFulInfor = this.props.dataContainer
    let listUSBeauty
    let typeOptions
    if (this.props.dataTopic) {
      typeOptions = this.props.dataTopic.map((type, idx) => {
        return (
          <Dropdown.Item
            key={idx}
            onClick={() => {
              this.setState({ topic: type.name });
            }}>{type.name}</Dropdown.Item>
        )
      })
    }
    if (userFulInfor) {
      listUSBeauty = userFulInfor.map((item, key) => {
        let tempDT = new Date(item.last_update_time);
        let edit = "edit" + item._id
        let deleteData = "delete" + item._id
        let refreshData = "refresh" + item._id
        
        return (
          <tr className="colum2" key={key}>
            <td>{this.props.dataActivePage * POST_ITEMS_PER_PAGE - POST_ITEMS_PER_PAGE + key + 1}</td>
            <td>{item.title}</td>
            <td>{item.topic}</td>
            <td>{item.author}</td>
            <td>{tempDT.getDate() + "/" + (tempDT.getMonth() + 1) + "/" + tempDT.getFullYear()}</td>
            <td style={{ width: "13%" }}>
              <button 
                type="button"
                className="btn btn-primary"
                style={{ marginRight: 5 }}
                onClick={() => this.navigateToEditForm(item)}>Sửa</button>

              

              <div className="modal fade" id={edit} role="dialog">
                <div className="modal-dialog">
                  {/* Modal content*/}
                  <div className="modal-content" style={{ width: 650 }}>
                    <div className="modal-header">
                      <h3 className="modal-title text-center" style={{ color: "#007bff", width: "100%" }} >Sửa vẻ đẹp Mỹ</h3>
                      <button type="button" className="close" data-dismiss="modal">×</button>
                    </div>
                    <div className="modal-body">
                      <div className="divUpdateUSBeautyComponent">
                        <label className="name-label labelUpdateUSBeautyComponent" >Tiêu đề:</label>
                        <div className="divUpdateUSBeauty">
                          <input
                            type="text"
                            value={this.state.title}
                            className="form1 textUSBeautyComponent"
                            placeholder="Tiêu đề bài viết"
                            onChange={(e) => { this.setState({ title: e.target.value }) }}
                          />
                        </div>
                        <div className="clear" ></div>
                      </div>
                      <div className="divUpdateUSBeautyComponent">
                        <label className="name-label labelUpdateUSBeautyComponent" >Chủ đề:</label>
                        <DropdownButton
                          title={this.state.topic}
                          id="dropdown-menu-align-right">
                          {typeOptions}
                        </DropdownButton>
                        <div className="clear" ></div>
                      </div>
                      <div className="divUpdateUSBeautyComponent">
                        <label className="name-label labelUpdateUSBeautyComponent" >Người đăng bài:</label>
                        <div className="divUpdateUSBeauty">
                          <input type="text" value={this.state.author} className="form1 textUSBeautyComponent"
                            placeholder="Người đăng bài "
                            onChange={(e) => { this.setState({ author: e.target.value }) }} />
                        </div>
                        <div className="clear" ></div>
                      </div>
                      <div className="divUpdateUSBeautyComponent" style={{ marginTop: "18px" }} >
                        <label className="name-label labelUpdateUSBeautyComponent" >Nguồn: </label>
                        <div className="divUpdateUSBeauty">
                          <input type="text" value={this.state.reference} className="form1 textUSBeautyComponent" placeholder="Nguồn tham khảo"
                            onChange={(e) => { this.setState({ reference: e.target.value }) }} />
                        </div>
                        <div className="clear" ></div>
                      </div>

                      {this.state.images.map((image, idx) => (
                        <div key={idx} className="form-text1"  >
                          {image.isDeleted ? <div></div> :
                            <div className="titleUpdateUSBeautyComponent">
                              <div className="form-text2 clearUpdateFormText2">
                                <label className="labelUpdateUSBeautyComponent"> Thêm hình ảnh: </label>
                                <button type="button" onClick={this.handleRemove(idx)}
                                  className="btn btn-primary" id="remove">-</button>
                              </div>
                              <form action="path/to/next.php" method="post"
                                className="form-photo formUpdateUSBeautyComponent" >
                                <div className="divFormUpdateUSBeautyComponent">
                                  <input type="file" className="myFile" name="picture" id="picture"
                                    style={{ width: 100 }}
                                    onChange={(e) => this.handleSelectedFile(e, idx)} />
                                  <div>
                                    {
                                      this.state.images[idx].imgUrl
                                        ?
                                        <img className="imgUrl imgUpdateUSBeautyComponent"
                                          src={this.state.images[idx].imgUrl} alt={this.state.images[idx].alt} />
                                        :
                                        <div></div>
                                    }
                                  </div>
                                </div>
                                <div className="form-image2 contImgUpdateUSBeautyComponent" >
                                  <div className="divContImgUpdateUSBeautComponent">
                                    <label className="name-label-alt altUpdateUSBeautyComponent">Alt:</label>
                                    <input type="text" className="inputAltUpdateUSBeautyComponent"
                                      name="alt" value={this.state.images[idx].alt}
                                      onChange={e => this.handleAltChange(e, idx)} />
                                  </div>
                                  <div>
                                    <label className="name-label-image contUpdateUSBeautyComponent">
                                      Nội dung:
                                    </label>
                                    <textarea className="text-area textareaUpdateUSBeautyComponent"
                                      type="text" rows="3" cols="23"
                                      value={this.state.images[idx].content}
                                      onChange={e => this.handleContentChange(e, idx)} />
                                  </div>
                                </div>
                              </form>
                              <div className="clear"></div>
                            </div>
                          }
                        </div>
                      ))}
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button" onClick={this.handleAdd}
                        className="btn btn-success">+</button>
                      <button type="button" className="btn btn-primary"
                        data-dismiss="modal"
                        onClick={() => this.editBeauty(item._id)}>Xác nhận</button>
                      <button type="button" className="btn btn-danger"
                        data-dismiss="modal">Hủy</button>
                    </div>
                  </div>
                </div>
              </div>
              <button type="button" className="btn btn-danger" data-toggle="modal"
                data-target={"#" + deleteData} >Xóa</button>
              <div className="modal fade" id={deleteData} role="dialog">
                <div className="modal-dialog modal-sm">
                  <div className="modal-content">
                    <div className="modal-header" style={{ color: "red" }}>
                      <h4 className="modal-title text-center" style={{ marginLeft: "30px" }} >Bạn có chắc chắn muốn xóa không ?</h4>
                      <button type="button" className="close" data-dismiss="modal">×</button>
                    </div>
                    <div className="modal-body text-center">
                      <button type="button" className="btn btn-primary" data-dismiss="modal"
                        onClick={() => this.deleteBeauty(item)} style={{ marginRight: 5 }} >Có</button>
                      <button type="button" className="btn btn-danger"
                        data-dismiss="modal" >Không</button>
                    </div>
                    <div className="modal-footer"></div>
                  </div>
                </div>
              </div>
              <button type="button" className="btn btn-info" data-toggle="modal"
                data-target={"#" + refreshData} >Làm mới</button>
              <div className="modal fade" id={refreshData} role="dialog">
                <div className="modal-dialog modal-sm">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h4 className="modal-title" style={{ marginLeft: "30px" }} >Bạn có chắc chắn muốn làm mới không ?</h4>
                      <button type="button" className="close" data-dismiss="modal">×</button>
                    </div>
                    <div className="modal-body">
                      <button type="button" className="btn btn-primary" data-dismiss="modal"
                        onClick={() => this.props.refresh(
                          {
                            dataAPI: item._id,
                            textSearch: this.props.textSearch,
                            activePage: this.props.dataActivePage
                          }
                        )} style={{ marginLeft: "35px" }} >Có</button>
                      <button type="button" className="btn btn-danger"
                        data-dismiss="modal" style={{ marginLeft: "10px" }}>Không</button>
                    </div>
                    <div className="modal-footer"></div>
                  </div>
                </div>
              </div>
            </td>
          </tr>
        )
      })
    }

    return (
      <div>
        <div>
          <SearchUSBeauty {...this.props} />
        </div>

        <div>
          {this.props.isLoadingData ? <LoadingModal /> :
            <div className="table-responsive">
            <Button
            variant="success"
            block
            onClick={() => this.navigateToAddForm()}>Thêm</Button>
              <table className="table table-striped">
                <thead>
                  <tr className="table-dark text-dark">
                    <th>STT</th>
                    <th>Tiêu đề</th>
                    <th>Chủ đề</th>
                    <th>Người đăng</th>
                    <th>Thời gian</th>
                    <th style={{ width: "13%" }}>Thao tác</th>
                  </tr>
                </thead>
                <tbody>
                  {listUSBeauty}
                </tbody>
              </table>
             
            </div>
          }
        </div>
      </div>
    );
  }

}

export default USBeautyComponent;