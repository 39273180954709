import React from 'react';
import TourScheduleContainer from '../containers/TourScheduleContainer';

class TourSchedulePage extends React.Component {
  render() {
    return (
      <div className="TourSchedulePage">
        <div className="card">
          <div className="card-header"><h1>Lịch Trình Tour</h1></div>
          <div className="card-body">
            <TourScheduleContainer />
          </div>
        </div>
      </div>
    );
  }
}

export default TourSchedulePage;