// type of common actions
export const SHOW_NOTIFY_MODAL = 'SHOW_NOTIFY_MODAL';
export const TURN_OFF_NOTIFY_MODAL = 'TURN_OFF_NOTIFY_MODAL';
export const UPLOAD_IMAGE_REQUEST = 'UPLOAD_IMAGE_REQUEST';
export const UPLOAD_IMAGE_SUCCESS = 'UPLOAD_IMAGE_SUCCESS';
export const UPLOAD_IMAGE_FAILURE = 'UPLOAD_IMAGE_FAILURE';
export const DELETE_IMAGES_REQUEST = 'DELETE_IMAGES_REQUEST';

//type of actions
export const GET_ITEM_REQUEST = 'GET_ITEM_REQUEST';
export const GET_ITEM_SUCCESS = 'GET_ITEM_SUCCESS';
export const GET_ITEM_FAILURE = 'GET_ITEM_FAILURE';

//type of actions TourSchedule
export const GET_TOUR_REQUEST = 'GET_TOUR_REQUEST';
export const GET_TOUR_SUCCESS = 'GET_TOUR_SUCCESS';
export const GET_TOUR_FAILURE = 'GET_TOUR_FAILURE';

export const ADD_TOUR_REQUEST = 'ADD_TOUR_REQUEST';
export const ADD_TOUR_SUCCESS = 'ADD_TOUR_SUCCESS';
export const ADD_TOUR_FAILURE = 'ADD_TOUR_FAILURE';

export const EDIT_TOUR_REQUEST = 'EDIT_TOUR_REQUEST';
export const EDIT_TOUR_SUCCESS = 'EDIT_TOUR_SUCCESS';
export const EDIT_TOUR_FAILURE = 'EDIT_TOUR_FAILURE';

export const DELETE_TOUR_REQUEST = 'DELETE_TOUR_REQUEST';
export const DELETE_TOUR_SUCCESS = 'DELETE_TOUR_SUCCESS';
export const DELETE_TOUR_FAILURE = 'DELETE_TOUR_FAILURE';

export const COPY_TOUR_REQUEST = 'COPY_TOUR_REQUEST';
export const COPY_TOUR_SUCCESS = 'COPY_TOUR_SUCCESS';
export const COPY_TOUR_FAILURE = 'COPY_TOUR_FAILURE';

export const SEARCH_TOUR_REQUEST = 'SEARCH_TOUR_REQUEST';
export const SEARCH_TOUR_SUCCESS = 'SEARCH_TOUR_SUCCESS';
export const SEARCH_TOUR_FAILURE = 'SEARCH_TOUR_FAILURE';
//HomePage
export const EDIT_IMAGE_REQUEST = 'EDIT_IMAGE_REQUEST';
export const EDIT_IMAGE_SUCCESS = 'EDIT_IMAGE_SUCCESS';
export const EDIT_IMAGE_FAILURE = 'EDIT_IMAGE_FAILURE';

export const GET_IMAGE_REQUEST = 'GET_IMAGE_REQUEST';
export const GET_IMAGE_SUCCESS = 'GET_IMAGE_SUCCESS';
export const GET_IMAGE_FAILURE = 'GET_IMAGE_FAILURE';

export const ADD_IMAGE_REQUEST = 'ADD_IMAGE_REQUEST';
export const ADD_IMAGE_SUCCESS = 'ADD_IMAGE_SUCCESS';
export const ADD_IMAGE_FAILURE = 'ADD_IMAGE_FAILURE';

export const DELETE_IMAGE_REQUEST = 'DELETE_IMAGE_REQUEST';
export const DELETE_IMAGE_SUCCESS = 'DELETE_IMAGE_SUCCESS';
export const DELETE_IMAGE_FAILURE = 'DELETE_IMAGE_FAILURE';

export const EDIT_TEXT_REQUEST = 'EDIT_TEXT_REQUEST';
export const EDIT_TEXT_SUCCESS = 'EDIT_TEXT_SUCCESS';
export const EDIT_TEXT_FAILURE = 'EDIT_TEXT_FAILURE';

export const GET_TEXT_REQUEST = 'GET_TEXT_REQUEST';
export const GET_TEXT_SUCCESS = 'GET_TEXT_SUCCESS';
export const GET_TEXT_FAILURE = 'GET_TEXT_FAILURE';

export const EDIT_TEXT_NEW_REQUEST = 'EDIT_TEXT_NEW_REQUEST';
export const EDIT_TEXT_NEW_SUCCESS = 'EDIT_TEXT_NEW_SUCCESS';
export const EDIT_TEXT_NEW_FAILURE = 'EDIT_TEXT_NEW_FAILURE';

export const GET_IMAGE_EVENT_REQUEST = 'GET_IMAGE_EVENT_REQUEST';
export const GET_IMAGE_EVENT_SUCCESS = 'GET_IMAGE_EVENT_SUCCESS';
export const GET_IMAGE_EVENT_FAILURE = 'GET_IMAGE_EVENT_FAILURE';

export const GET_IMAGE_TOUR_REQUEST = 'GET_IMAGE_TOUR_REQUEST';
export const GET_IMAGE_TOUR_SUCCESS = 'GET_IMAGE_TOUR_SUCCESS';
export const GET_IMAGE_TOUR_FAILURE = 'GET_IMAGE_TOUR_FAILURE';

export const EDIT_IMAGE_EVENT_REQUEST = 'EDIT_IMAGE_EVENT_REQUEST';
export const EDIT_IMAGE_EVENT_SUCCESS = 'EDIT_IMAGE_EVENT_SUCCESS';
export const EDIT_IMAGE_EVENT_FAILURE = 'EDIT_IMAGE_EVENT_FAILURE';

export const EDIT_IMAGE_TOUR_REQUEST = 'EDIT_IMAGE_TOUR_REQUEST';
export const EDIT_IMAGE_TOUR_SUCCESS = 'EDIT_IMAGE_TOUR_SUCCESS';
export const EDIT_IMAGE_TOUR_FAILURE = 'EDIT_IMAGE_TOUR_FAILURE';

//USBEAUTY Topic actions:
export const GET_USBEAUTYTOPIC_REQUEST = 'GET_USBEAUTYTOPIC_REQUEST';
export const GET_USBEAUTYTOPIC_SUCCESS = 'GET_USBEAUTYTOPIC_SUCCESS';
export const GET_USBEAUTYTOPIC_FAILURE = 'GET_USBEAUTYTOPIC_FAILURE';

export const ADD_USBEAUTYTOPIC_REQUEST = 'ADD_USBEAUTYTOPIC_REQUEST';
export const ADD_USBEAUTYTOPIC_SUCCESS = 'ADD_USBEAUTYTOPIC_SUCCESS';
export const ADD_USBEAUTYTOPIC_FAILURE = 'ADD_USBEAUTYTOPIC_FAILURE';

export const EDIT_USBEAUTYTOPIC_REQUEST = 'EDIT_USBEAUTYTOPIC_REQUEST';
export const EDIT_USBEAUTYTOPIC_SUCCESS = 'EDIT_USBEAUTYTOPIC_SUCCESS';
export const EDIT_USBEAUTYTOPIC_FAILURE = 'EDIT_USBEAUTYTOPIC_FAILURE';

export const DELETE_USBEAUTYTOPIC_REQUEST = 'DELETE_USBEAUTYTOPIC_REQUEST';
export const DELETE_USBEAUTYTOPIC_SUCCESS = 'DELETE_USBEAUTYTOPIC_SUCCESS';
export const DELETE_USBEAUTYTOPIC_FAILURE = 'DELETE_USBEAUTYTOPIC_FAILURE';

//USBEAUTY actions:
export const GET_USBEAUTY_REQUEST = 'GET_USBEAUTY_REQUEST';
export const GET_USBEAUTY_SUCCESS = 'GET_USBEAUTY_SUCCESS';
export const GET_USBEAUTY_FAILURE = 'GET_USBEAUTY_FAILURE';

export const ADD_USBEAUTY_REQUEST = 'ADD_USBEAUTY_REQUEST';
export const ADD_USBEAUTY_SUCCESS = 'ADD_USBEAUTY_SUCCESS';
export const ADD_USBEAUTY_FAILURE = 'ADD_USBEAUTY_FAILURE';

export const EDIT_USBEAUTY_REQUEST = 'EDIT_USBEAUTY_REQUEST';
export const EDIT_USBEAUTY_SUCCESS = 'EDIT_USBEAUTY_SUCCESS';
export const EDIT_USBEAUTY_FAILURE = 'EDIT_USBEAUTY_FAILURE';

export const DELETE_USBEAUTY_REQUEST = 'DELETE_USBEAUTY_REQUEST';
export const DELETE_USBEAUTY_SUCCESS = 'DELETE_USBEAUTY_SUCCESS';
export const DELETE_USBEAUTY_FAILURE = 'DELETE_USBEAUTY_FAILURE';

export const SEARCH_USBEAUTY_REQUEST = 'SEARCH_USBEAUTY_REQUEST';
export const SEARCH_USBEAUTY_SUCCESS = 'SEARCH_USBEAUTY_SUCCESS';
export const SEARCH_USBEAUTY_FAILURE = 'SEARCH_USBEAUTY_FAILURE';


export const REFRESH_USBEAUTY_REQUEST = 'REFRESH_USBEAUTY_REQUEST';
export const REFRESH_USBEAUTY_SUCCESS = 'REFRESH_USBEAUTY_SUCCESS';
export const REFRESH_USBEAUTY_FAILURE = 'REFRESH_USBEAUTY_FAILURE';

//UsefulInformation actions:
export const GET_USEFULINFOR_REQUEST = 'GET_USEFULINFOR_REQUEST';
export const GET_USEFULINFOR_SUCCESS = 'GET_USEFULINFOR_SUCCESS';
export const GET_USEFULINFOR_FAILURE = 'GET_USEFULINFOR_FAILURE';

export const ADD_USEFULINFOR_REQUEST = 'ADD_USEFULINFOR_REQUEST';
export const ADD_USEFULINFOR_SUCCESS = 'ADD_USEFULINFOR_SUCCESS';
export const ADD_USEFULINFOR_FAILURE = 'ADD_USEFULINFOR_FAILURE';

export const EDIT_USEFULINFOR_REQUEST = 'EDIT_USEFULINFOR_REQUEST';
export const EDIT_USEFULINFOR_SUCCESS = 'EDIT_USEFULINFOR_SUCCESS';
export const EDIT_USEFULINFOR_FAILURE = 'EDIT_USEFULINFOR_FAILURE';

export const DELETE_USEFULINFOR_REQUEST = 'DELETE_USEFULINFOR_REQUEST';
export const DELETE_USEFULINFOR_SUCCESS = 'DELETE_USEFULINFOR_SUCCESS';
export const DELETE_USEFULINFOR_FAILURE = 'DELETE_USEFULINFOR_FAILURE';

export const SEARCH_USEFULINFOR_REQUEST = 'SEARCH_USEFULINFOR_REQUEST';
export const SEARCH_USEFULINFOR_SUCCESS = 'SEARCH_USEFULINFOR_SUCCESS';
export const SEARCH_USEFULINFOR_FAILURE = 'SEARCH_USEFULINFOR_FAILURE';

export const REFRESH_USEFULINFOR_REQUEST = 'REFRESH_USEFULINFOR_REQUEST';
export const REFRESH_USEFULINFOR_SUCCESS = 'REFRESH_USEFULINFOR_SUCCESS';
export const REFRESH_USEFULINFOR_FAILURE = 'REFRESH_USEFULINFOR_FAILURE';
//UsaInformation actions:
export const GET_USAINFOR_REQUEST = 'GET_USAINFOR_REQUEST';
export const GET_USAINFOR_SUCCESS = 'GET_USAINFOR_SUCCESS';
export const GET_USAINFOR_FAILURE = 'GET_USAINFOR_FAILURE';

export const ADD_USAINFOR_REQUEST = 'ADD_USAINFOR_REQUEST';
export const ADD_USAINFOR_SUCCESS = 'ADD_USAINFOR_SUCCESS';
export const ADD_USAINFOR_FAILURE = 'ADD_USAINFOR_FAILURE';

export const EDIT_USAINFOR_REQUEST = 'EDIT_USAINFOR_REQUEST';
export const EDIT_USAINFOR_SUCCESS = 'EDIT_USAINFOR_SUCCESS';
export const EDIT_USAINFOR_FAILURE = 'EDIT_USAINFOR_FAILURE';

export const DELETE_USAINFOR_REQUEST = 'DELETE_USAINFOR_REQUEST';
export const DELETE_USAINFOR_SUCCESS = 'DELETE_USAINFOR_SUCCESS';
export const DELETE_USAINFOR_FAILURE = 'DELETE_USAINFOR_FAILURE';

export const SEARCH_USAINFOR_REQUEST = 'SEARCH_USAINFOR_REQUEST';
export const SEARCH_USAINFOR_SUCCESS = 'SEARCH_USAINFOR_SUCCESS';
export const SEARCH_USAINFOR_FAILURE = 'SEARCH_USAINFOR_FAILURE';

export const REFRESH_USAINFOR_REQUEST = 'REFRESH_USAINFOR_REQUEST';
export const REFRESH_USAINFOR_SUCCESS = 'REFRESH_USAINFOR_SUCCESS';
export const REFRESH_USAINFOR_FAILURE = 'REFRESH_USAINFOR_FAILURE';

//PriceRequestPage
export const GET_LIST_REQUEST = "GET_LIST_REQUEST";
export const GET_LIST_SUCCESS = "GET_LIST_SUCCESS";
export const GET_LIST_FAILURE = "GET_LIST_FAILURE";
export const DELETE_REQUEST_REQUEST = "DELETE_REQUEST_REQUEST";
export const DELETE_REQUEST_SUCCESS = "DELETE_REQUEST_SUCCESS";
export const DELETE_REQUEST_FAILURE = "DELETE_REQUEST_FAILURE";
export const SEARCH_LIST_REQUEST = "SEARCH_LIST_REQUEST";
export const SEARCH_LIST_SUCCESS = "SEARCH_LIST_SUCCESS";
export const SEARCH_LIST_FAILURE = "SEARCH_LIST_FAILURE";

//ContactPage
export const GET_LIST_CONTACT_REQUEST = "GET_LIST_CONTACT_REQUEST";
export const GET_LIST_CONTACT_SUCCESS = "GET_LIST_CONTACT_SUCCESS";
export const GET_LIST_CONTACT_FAILURE = "GET_LIST_CONTACT_FAILURE";
export const DELETE_CONTACT_REQUEST = "DELETE_CONTACT_REQUEST";
export const DELETE_CONTACT_SUCCESS = "DELETE_CONTACT_SUCCESS";
export const DELETE_CONTACT_FAILURE = "DELETE_CONTACT_FAILURE";
export const SEARCH_CONTACT_REQUEST = "SEARCH_CONTACT_REQUEST";
export const SEARCH_CONTACT_SUCCESS = "SEARCH_CONTACT_SUCCESS";
export const SEARCH_CONTACT_FAILURE = "SEARCH_CONTACT_FAILURE";

//action cho about page: phần giới thiệu - Intro
export const GET_TEXT_INTRO_REQUEST = 'GET_TEXT_INTRO_REQUEST';
export const GET_TEXT_INTRO_SUCCESS = 'GET_TEXT_INTRO_SUCCESS';
export const GET_TEXT_INTRO_FAILURE = 'GET_TEXT_INTRO_FAILURE';
export const UPDATE_TEXT_INTRO_REQUEST = 'UPDATE_TEXT_INTRO_REQUEST';
export const UPDATE_TEXT_INTRO_SUCCESS = 'UPDATE_TEXT_INTRO_SUCCESS';
export const UPDATE_TEXT_INTRO_FAILURE = 'UPDATE_TEXT_INTRO_FAILURE';
//phần giới thiệu - Member
export const GET_MEM_INTRO_REQUEST = 'GET_MEM_INTRO_REQUEST';
export const GET_MEM_INTRO_SUCCESS = 'GET_MEM_INTRO_SUCCESS';
export const GET_MEM_INTRO_FAILURE = 'GET_MEM_INTRO_FAILURE';
export const ADD_MEM_INTRO_REQUEST = 'ADD_MEM_INTRO_REQUEST';
export const ADD_MEM_INTRO_SUCCESS = 'ADD_MEM_INTRO_SUCCESS';
export const ADD_MEM_INTRO_FAILURE = 'ADD_MEM_INTRO_FAILURE';
export const UPDATE_MEM_INTRO_REQUEST = 'UPDATE_MEM_INTRO_REQUEST';
export const UPDATE_MEM_INTRO_SUCCESS = 'UPDATE_MEM_INTRO_SUCCESS';
export const UPDATE_MEM_INTRO_FAILURE = 'UPDATE_MEM_INTRO_FAILURE';
export const DELETE_MEM_INTRO_REQUEST = 'DELETE_MEM_INTRO_REQUEST';
export const DELETE_MEM_INTRO_SUCCESS = 'DELETE_MEM_INTRO_SUCCESS';
export const DELETE_MEM_INTRO_FAILURE = 'DELETE_MEM_INTRO_FAILURE';
//action cho about page: phần FAQs
//phần GET của FAQs
export const GET_FAQ_REQUEST = 'GET_FAQ_REQUEST';
export const GET_FAQ_SUCCESS = 'GET_FAQ_SUCCESS';
export const GET_FAQ_FAILURE = 'GET_FAQ_FAILURE';
//phần ADD của FAQs
export const ADD_FAQ_REQUEST = 'ADD_FAQ_REQUEST';
export const ADD_FAQ_SUCCESS = 'ADD_FAQ_SUCCESS';
export const ADD_FAQ_FAILURE = 'ADD_FAQ_FAILURE';
//phần UPDATE của FAQs
export const UPDATE_FAQ_REQUEST ='UPDATE_FAQ_REQUEST';
export const UPDATE_FAQ_SUCCESS ='UPDATE_FAQ_SUCCESS';
export const UPDATE_FAQ_FAILURE ='UPDATE_FAQ_FAILURE';
//phần DELETE của FAQs
export const DELETE_FAQ_REQUEST = 'DELETE_FAQ_REQUEST';
export const DELETE_FAQ_SUCCESS = 'DELETE_FAQ_SUCCESS';
export const DELETE_FAQ_FAILURE = 'DELETE_FAQ_FAILURE';
//action cho about page: phần Điều khoản sử dụng(Terms)
//phần GET của Terms
export const GET_TERMS_REQUEST = 'GET_TERMS_REQUEST';
export const GET_TERMS_SUCCESS = 'GET_TERMS_SUCCESS';
export const GET_TERMS_FAILURE = 'GET_TERMS_FAILURE';
//phần ADD của Terms
export const ADD_TERMS_REQUEST = 'ADD_TERMS_REQUEST';
export const ADD_TERMS_SUCCESS = 'ADD_TERMS_SUCCESS';
export const ADD_TERMS_FAILURE = 'ADD_TERMS_FAILURE';
//phần UPDATE của Terms
export const UPDATE_TERMS_REQUEST = 'UPDATE_TERMS_REQUEST';
export const UPDATE_TERMS_SUCCESS = 'UPDATE_TERMS_SUCCESS';
export const UPDATE_TERMS_FAILURE = 'UPDATE_TERMS_FAILURE';
//phần DELETE của Terms
export const DELETE_TERMS_REQUEST = 'DELETE_TERMS_REQUEST';
export const DELETE_TERMS_SUCCESS = 'DELETE_TERMS_SUCCESS';
export const DELETE_TERMS_FAILURE = 'DELETE_TERMS_FAILURE';

// Event actions
export const GET_EVENTS_REQUEST    = 'GE001';
export const GET_EVENTS_SUCCESS    = 'GE002';
export const GET_EVENTS_FAILED     = 'GE003';
export const ADD_EVENT_REQUEST     = 'AE001';
export const ADD_EVENT_SUCCESS     = 'AE002';
export const ADD_EVENT_FAILED      = 'AE003';
export const UPDATE_EVENT_REQUEST  = 'UE001';
export const UPDATE_EVENT_SUCCESS  = 'UE002';
export const UPDATE_EVENT_FAILED   = 'UE003';
export const DELETE_EVENT_REQUEST  = 'DE001';
export const DELETE_EVENT_SUCCESS  = 'DE002';
export const DELETE_EVENT_FAILED   = 'DE003';
export const SEARCH_EVENT_REQUEST  = 'SE001';
export const SEARCH_EVENT_SUCCESS  = 'SE002';
export const SEARCH_EVENT_FAILED   = 'SE003';

//Popular Tours Type  Actions
export const GET_POPULAR_TOUR_REQUEST = 'GET_POPULAR_TOUR_REQUEST';
export const GET_POPULAR_TOUR_SUCCESS = 'GET_POPULAR_TOUR_SUCCESS';
export const GET_POPULAR_TOUR_FAILURE = 'GET_POPULAR_TOUR_FAILURE';

export const UPDATE_POPULAR_TOUR_REQUEST = 'UPDATE_POPULAR_TOUR_REQUEST';
export const UPDATE_POPULAR_TOUR_SUCCESS = 'UPDATE_POPULAR_TOUR_SUCCESS';
export const UPDATE_POPULAR_TOUR_FAILURE = 'UPDATE_POPULAR_TOUR_FAILURE';

export const DELETE_POPULAR_TOUR_REQUEST = 'DELETE_POPULAR_TOUR_REQUEST';
export const DELETE_POPULAR_TOUR_SUCCESS = 'DELETE_POPULAR_TOUR_SUCCESS';
export const DELETE_POPULAR_TOUR_FAILURE = 'DELETE_POPULAR_TOUR_FAILURE';

export const CREATE_POPULAR_TOUR_REQUEST = 'CREATE_POPULAR_TOUR_REQUEST';
export const CREATE_POPULAR_TOUR_SUCCESS = 'CREATE_POPULAR_TOUR_SUCCESS';
export const CREATE_POPULAR_TOUR_FAILURE = 'CREATE_POPULAR_TOUR_FAILURE';

export const SEARCH_POPULAR_TOUR_REQUEST = 'SEARCH_POPULAR_TOUR_REQUEST';
export const SEARCH_POPULAR_TOUR_SUCCESS = 'SEARCH_POPULAR_TOUR_SUCCESS';
export const SEARCH_POPULAR_TOUR_FAILURE = 'SEARCH_POPULAR_TOUR_FAILURE';


//tour's topic action
export const GET_TOPIC_REQUEST = 'GET_TOPIC_REQUEST';
export const GET_TOPIC_SUCCESS = 'GET_TOPIC_SUCCESS';
export const GET_TOPIC_FAILURE = 'GET_TOPIC_FAILURE';

export const ADD_TOPIC_REQUEST = 'ADD_TOPIC_REQUEST';
export const ADD_TOPIC_SUCCESS = 'ADD_TOPIC_SUCCESS';
export const ADD_TOPIC_FAILURE = 'ADD_TOPIC_FAILURE';

export const UPDATE_TOPIC_REQUEST = 'UPDATE_TOPIC_REQUEST';
export const UPDATE_TOPIC_SUCCESS = 'UPDATE_TOPIC_SUCCESS';
export const UPDATE_TOPIC_FAILURE = 'UPDATE_TOPIC_FAILURE';

export const DELETE_TOPIC_REQUEST = 'DELETE_TOPIC_REQUEST';
export const DELETE_TOPIC_SUCCESS = 'DELETE_TOPIC_SUCCESS';
export const DELETE_TOPIC_FAILURE = 'DELETE_TOPIC_FAILURE';

//Login action
export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

// Flag
export const IMAGE_FLAG = {
    IS_OLD : 0, IS_NEW: 1, IS_DELETE: 2
}
export const POST_TYPE_FLAG = {
    USA_BEAUTY: "0",
    USA_INFO: "1",
    USEFUL_INFO: "2"
}

// Number of tour items in a page
export const TOUR_ITEMS_PER_PAGE = 10;
export const EVENT_ITEMS_PER_PAGE = 10;
export const POST_ITEMS_PER_PAGE = 10;

//endpoint
export const domain = 'https://nuocmy.net/api'
