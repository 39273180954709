import { domain } from '../../constant'

export default function DeleteContact(id) {
  return new Promise((resolve, reject) => {
    const url = domain + `/del-contact/${id}`;
    fetch(url, {
      method: "DELETE",
    })
      .then((response) => response.json())
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}