import React from 'react';
import LoadingModal from '../commons/LoadingModal'

class Info extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: '',
      currentContact: {
        tel: '', email: '', facebook: '', zalo: '', whatsapp: '', viber: ''
      },
      updateContact: {
        tel: '', email: '', facebook: '', zalo: '',  whatsapp: '', viber: ''
      }
    };
  }
  HandleSetState() {
    this.setState({
      updateContact: this.state.currentContact
    });
  }

  UpdateTextNew() {
    this.props.edit({ ...this.state.updateContact, id: this.state.id })
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.text) {
      this.setState({
        currentContact: nextProps.text.contact,
        id: nextProps.text.contact.id
      })
    }
  }
  render() {
    return (
      <div>
        <div className="modal fade" id="InfoModal" role="dialog">
          <div className="modal-dialog">
            {/* Modal content*/}
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title text-center" style={{width:"100vw"}}>Cập nhật thông tin liên lạc</h4>
                <button type="button" className="close" data-dismiss="modal">×</button>
              </div>
              <div className="modal-body">
                <h6>Số điện thoại</h6>
                <input
                  onChange={(e) =>
                    this.setState({ updateContact: { ...this.state.updateContact, tel: e.target.value } })
                  }
                  value={this.state.updateContact.tel} />
                <h6>Địa chỉ email</h6>
                <input
                  onChange={(e) =>
                    this.setState({ updateContact: { ...this.state.updateContact, email: e.target.value } })
                  }
                  value={this.state.updateContact.email} />
                <h6>Đường dẫn đến fanpage facebook</h6>
                <input
                  onChange={(e) =>
                    this.setState({ updateContact: { ...this.state.updateContact, facebook: e.target.value } })
                  }
                  value={this.state.updateContact.facebook} />
                <h6>Zalo (SĐT sử dụng để đăng ký zalo)</h6>
                <input
                  onChange={(e) =>
                    this.setState({ updateContact: { ...this.state.updateContact, zalo: e.target.value } })
                  }
                  value={this.state.updateContact.zalo} />
                  <h6>Whatsapp (SĐT sử dụng để đăng ký whatsapp)</h6>
                  <p style={{marginBottom: "0.5rem"}}>Mẫu: 843538095</p>
                <input
                  type="number"
                  onChange={(e) =>
                    this.setState({ updateContact: { ...this.state.updateContact, whatsapp: e.target.value } })
                  }
                  value={this.state.updateContact.whatsapp} />
                  <h6>Viber (Tên hiển thị trong Viber)</h6>
                <input
                  onChange={(e) =>
                    this.setState({ updateContact: { ...this.state.updateContact, viber: e.target.value } })
                  }
                  value={this.state.updateContact.viber} />
              </div>
              <div className="modal-footer">
              <button type="button" className="btn btn-danger" data-dismiss="modal">Hủy</button>
                <button type="button" className="btn btn-success" data-dismiss="modal" onClick={() => this.UpdateTextNew()}>Xác nhận</button>
              </div>
            </div>
          </div>
        </div>
        {/* table */}
        {(!this.props.isLoadingText && this.state.currentContact) ?
          <table className="table table-striped">
            <thead>
              <tr className="table-dark text-dark">
                <th>Điện thoại</th>
                <th>Email</th>
                <th>Facebook</th>
                <th>Zalo</th>
                <th>Whatsapp</th>
                <th>Viber</th>
                <th colSpan="2" >Thao tác</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{this.state.currentContact.tel}</td>
                <td>{this.state.currentContact.email}</td>
                <td>{this.state.currentContact.facebook}</td>
                <td>{this.state.currentContact.zalo}</td>
                <td>{this.state.currentContact.whatsapp}</td>
                <td>{this.state.currentContact.viber}</td>
                <td><button type="button" className="btn btn-primary" data-toggle="modal" data-target="#InfoModal" onClick={() => this.HandleSetState()}>Sửa</button></td>
              </tr>
            </tbody>
          </table>
          : <LoadingModal />}
      </div>
    );
  }
}

export default Info;