import React from 'react';
import { Route, Switch, Router } from 'react-router-dom';
import * as page from './pages';
import history from './history'

const Routes = () => (
  <div className="content">
    <Router history={history}>
      <Switch>
        <Route exact path="/" component={page.HomePage} />
        <Route path="/home"
          component={page.HomePage}
        />
        <Route path="/popular-tour"
          component={page.PopularTourPage}
        />
        <Route path="/request"
          component={page.PriceRequestPage}
        />
        <Route path="/tour-schedule"
          component={page.TourSchedulePage}
        />
        <Route path="/beauty"
          component={page.USBeautyPage}
        />
        <Route path="/information"
          component={page.USInformationPage}
        />
        <Route path="/useful-information"
          component={page.UsefulInformationPage}
        />
        <Route path="/event"
          component={page.EventPage}
        />
        <Route path="/contact"
          component={page.ContactPage}
        />
        <Route path="/about"
          component={page.AboutPage}
        />
        <Route path="/event-form"
          component={page.EventFormPage}
        />
        <Route path="/post-form"
          component={page.PostFormPage}
        />
      </Switch>
    </Router>
  </div>
);

export default Routes;