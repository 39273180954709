import * as types from '../constant';
import getEventAPI from '../fetchAPI/EventAPI/GetEvent';
import addEventAPI from '../fetchAPI/EventAPI/AddEvent';
import searchEventAPI from '../fetchAPI/EventAPI/SearchEvent';
import updateEventAPI from '../fetchAPI/EventAPI/UpdateEvent';
import deleteEventAPI from '../fetchAPI/EventAPI/DeleteEvent'
import { put, takeEvery } from 'redux-saga/effects';

function* getEvent(action) {
    try {
        const response = yield getEventAPI(action.payload);
        let convertObj = response.events.map(evt => {
            return {
                ...evt,
                coverImg: evt.cover_img,
                id: evt._id
            };
        });
        yield put({
            type: types.GET_EVENTS_SUCCESS,
            payload: { events: convertObj, totalPages: response.total_pages, activePage: action.payload }
        });
    } catch (error) {
        yield put({
            type: types.GET_EVENTS_FAILED,
            payload: {
                errorMessage: error.message
            }
        });
    }
}

function* searchEvent(action) {
    try {
        const response = yield searchEventAPI(
            action.payload.textSearch, action.payload.page
        );
        let convertObj = response.events.map(evt => {
            return {
                ...evt,
                coverImg: evt.cover_img,
                id: evt._id
            };
        });
        yield put({
            type: types.SEARCH_EVENT_SUCCESS,
            payload: { events: convertObj, totalPages: response.total_pages, activePage: action.payload.page }
        });
    } catch (error) {
        yield put({
            type: types.SHOW_NOTIFY_MODAL,
            payload: "Không tìm kiếm được event. Lỗi: " + error.message
        });
    }
}

function* addEvent(action) {
    try {
        const response = yield addEventAPI(action.payload);
        yield put({
            type: types.ADD_EVENT_SUCCESS,
            payload: response
        });
        const textSearch = sessionStorage.getItem("event.textSearch")
        const page = Number(sessionStorage.getItem("event.activePage")) || 1
        if(textSearch){
            yield put({
                type: types.SEARCH_EVENT_REQUEST,
                payload: { textSearch, page }
            });
        } else {
            yield put({
                type: types.GET_EVENTS_REQUEST,
                payload: page
            });
        }
        
    } catch (error) {
        yield put({
            type: types.SHOW_NOTIFY_MODAL,
            payload: "Không tạo được event. Lỗi: " + error.message
        });
    }
}

function* updateEvent(action) {
    try {
        const response = yield updateEventAPI(action.payload);
        yield put({
            type: types.UPDATE_EVENT_SUCCESS,
            payload: response
        });
        const textSearch = sessionStorage.getItem("event.textSearch")
        const page = Number(sessionStorage.getItem("event.activePage")) || 1
        if(textSearch){
            yield put({
                type: types.SEARCH_EVENT_REQUEST,
                payload: { textSearch, page }
            });
        } else {
            yield put({
                type: types.GET_EVENTS_REQUEST,
                payload: page
            });
        }
    } catch (error) {
        yield put({
            type: types.SHOW_NOTIFY_MODAL,
            payload: "Không cập nhật được event. Lỗi: " + error.message
        });
    }
}

function* deleteEvent(action) {
    const { id, totalPages, length } = action.payload
    try {
        const response = yield deleteEventAPI(id);
        yield put({
            type: types.DELETE_EVENT_SUCCESS,
            payload: response
        });
        const textSearch = sessionStorage.getItem("event.textSearch")
        let page = Number(sessionStorage.getItem("event.activePage")) || 1
        // If item delete is the first item at last page => Get the previous page if success
        if(page === totalPages && length === 1)
            page -= 1
        if(textSearch){
            yield put({
                type: types.SEARCH_EVENT_REQUEST,
                payload: { textSearch, page }
            });
        } else {
            yield put({
                type: types.GET_EVENTS_REQUEST,
                payload: page
            });
        }
    } catch (error) {
        yield put({
            type: types.DELETE_EVENT_FAILED,
            payload: {
                errorMessage: error.message
            }
        });
    }
}

export const EventSaga = [
    takeEvery(types.GET_EVENTS_REQUEST, getEvent),
    takeEvery(types.SEARCH_EVENT_REQUEST, searchEvent),
    takeEvery(types.ADD_EVENT_REQUEST, addEvent),
    takeEvery(types.UPDATE_EVENT_REQUEST, updateEvent),
    takeEvery(types.DELETE_EVENT_REQUEST, deleteEvent)
]