import React, { Component } from 'react'
import * as actions from '../actions/ContactAction';
import { connect } from 'react-redux';
import ContactComponent from "../components/ContactComponent/ContactComponent"
import PaginationCommon from "../components/commons/PaginationCommon"
class ContactContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isSearching: false,
            textSearch: ""
        };
    }

    componentDidMount() {
        this.props.onPaginate(1, this.state.isSearching);
    }

    setSearchingStatus(status, textSearch) {
        this.setState({ isSearching: status, textSearch: textSearch });
    }

    render() {
        return (
            <div>
                <ContactComponent setSearchingStatus={(s, txt) => this.setSearchingStatus(s, txt)} {...this.props} />
                <PaginationCommon
                    activePage={this.props.activePage}
                    totalPage={this.props.totalPages}
                    onPaginate={
                        (data) => this.props.onPaginate(data, this.state.isSearching, this.state.textSearch)
                    } />
            </div>
        )
    }
}
const mapStateToProps = (Store) => {
    return {
        dataListContact: Store.contact.listContact,
        isLoading: Store.contact.isFetching,
        activePage: Store.contact.activePagesContact,
        totalPages: Store.contact.totalPages
    }

}
const mapDispatchToProps = (dispatch) => {
    return {
        onPaginate: (page, isSearching, textSearch) => {
            if (isSearching) { dispatch(actions.SearchContact({ textSearch, page })); }
            else { dispatch(actions.getListContact(page)); }
        },
        deleteContact: (id) => {
            dispatch(actions.deleteContact(id))
        },
        searchContacts: (textSearch, page) => {
            dispatch(actions.SearchContact({ textSearch, page }))
        }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(ContactContainer)