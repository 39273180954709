import React from 'react';
import EventFormContainer from '../containers/EventFormContainer';

class EventFormPage extends React.Component {
  render() {
    return (
      <div className="EventPage">
        <div className="card">
            <div className="card-header">
                <h1>
                  {"Danh Sách Lịch Trình > " 
                  + (
                    window.sessionStorage.getItem("event.id") 
                      ? "Cập Nhật Lịch Trình"
                      : "Thêm Lịch Trình"
                  )}
                </h1>
            </div>
            <div className="card-body">
                <EventFormContainer />
            </div>
        </div>
      </div>
    );
  }
}

export default EventFormPage;