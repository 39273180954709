import { connect } from 'react-redux';
import React, { Component } from 'react'
import * as actions from '../actions/EventActions';
import EventComponent from "../components/EventComponent/EventComponent"
import PaginationCommon from "../components/commons/PaginationCommon"

class EventContainer extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isSearching: !!sessionStorage.getItem("event.textSearch"),
			textSearch: sessionStorage.getItem("event.textSearch") || ""
		};
	}
	
    componentDidMount() {
        // Get all events depending on the previous user action on this page
        // Or just get events from first page
        let page = Number(sessionStorage.getItem("event.activePage")) || 1
        this.props.onEventPaginate(page, this.state.isSearching, this.state.textSearch);
    }
	
	setSearchingStatus(status, textSearch) {
        if(status)
            sessionStorage.setItem("event.textSearch", textSearch)
        else
            sessionStorage.removeItem("event.textSearch")
        this.setState(
            { isSearching: status, textSearch: textSearch }
        );
	}

    render() {
        return (
            <div>
                <EventComponent 
                    setSearchingStatus={(stt, txt) => this.setSearchingStatus(stt, txt)}
                    {...this.props} />
                <PaginationCommon 
					activePage={this.props.activePage}
                    totalPage={this.props.totalPages}
                    onPaginate={
                        (page) => {this.props.onEventPaginate(page, this.state.isSearching, this.state.textSearch)}
                    } />
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        events: state.event.listEvent,
        activePage: state.event.activePage,
        totalPages: state.event.totalPages,
        isLoadingData: state.event.isLoading
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onEventPaginate: (page, isSearching, textSearch) => {
            // Save active page to storage
            sessionStorage.setItem("event.activePage", page)
			if(isSearching) { dispatch(actions.searchEvent({textSearch, page})); }
			else { dispatch(actions.getEvent(page)); }
        },
        deleteEvent: (id) => {
            dispatch(actions.deleteAnEvent(id))
        },
        updateEvent: (payload) => {
            dispatch(actions.updateAnEvent(payload))
        },
        addEvent: (payload) => {
            dispatch(actions.addAnEvent(payload))
        }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(EventContainer)