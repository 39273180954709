import { domain } from "../../../../constant";

export default function updateTextIntro(data) {
    return new Promise((resolve, reject) => {
      const url = domain + "/update-contact-us-text";
      fetch(url, {
        method: 'PUT',
        headers:{
            "Content-Type":"application/json"
        },
        body:JSON.stringify(data)
      })
        .then((response) => response.json())
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
    