import React from 'react';
import '../styles/Home.css';
// import { domain } from '../../constant'
import SlideDelete from './SlideDelete'
import LoadingModal from '../commons/LoadingModal'
class Slide extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id: '',
            text: '',
            title: '',
            link: '',
            selectedFile: null,
            alt: '',
            imgUrl: '',
            titleChange: '',
            textChange: ''
        };
    }

    handleSelectedFile(e) {
        // Get user's selected file
        let file = e.target.files[0];
        // Check extension of file
        // To make sure it is an image
        if (file && file.name.match(/.(jpg|jpeg|png)$/gi)) {
            let url = URL.createObjectURL(file);
            this.setState({ selectedFile: file, imgUrl: url });
        }
        // Reset value => Make onChange active everytime we browse file 
        e.target.value = "";
    }
    HandleSetState() {
        this.setState({
            id: '',
            selectedFile: null,
            alt: '',
            title: '',
            link: '',
            text: '',
            imgUrl: '',
            titleChange: '',
            textChange: ''
        });
    }
    saveChange() {
        // Package all the data
        if (this.state.alt !== "" || this.state.title !== "" || this.state.text !== "") {
            if (this.state.textChange === 'Sửa') {
                const data = new FormData();
                if (this.state.selectedFile) {
                    data.append("image", this.state.selectedFile, this.state.selectedFile.name);
                }
                data.append('alt', this.state.alt);
                data.append('id', this.state.id);
                data.append('title', this.state.title);
                data.append('link', this.state.link);
                // Call request to server
                this.props.uploadImg(data);
                this.HandleSetState();
            }
            if (this.state.textChange === 'Thêm') {
                const data = new FormData();
                if (this.state.selectedFile) {
                    data.append("image", this.state.selectedFile, this.state.selectedFile.name);
                }
                data.append('alt', this.state.alt);
                data.append('title', this.state.title);
                data.append('link', this.state.link);
                // Call request to server
                this.props.addImg(data);
                this.HandleSetState();
            }
        }else{
           alert("Mời bạn nhập đủ các trường !")
        }
    }
    render() {
        // Get Slides
        let slides;
        if (this.props.images.slides) {
            // if (this.props.images.slides.length < 11) {
            //     document.getElementById("addSlide").disabled = false;
            //     document.getElementById("addSlide").style = "opacity:0";
            // } else {
            //     document.getElementById("addSlide").disabled = true;
            //     document.getElementById("addSlide").style = "";

            // }
            slides = this.props.images.slides.map((item, key) => {
                let deleteData = "delete" + key
                return (
                    <tr key={key}>
                        <td>
                            <img className="img-slide-background" src={item.src} alt="khong tai dc" />
                            </td>
                        <td>{item.title}</td>
                        <td>{item.link}</td>
                        <td style={{ width: "15%" }}>
                            <button type="button" className="btn btn-primary" data-toggle="modal" data-target="#myModalSua"
                                style={{ marginRight: 2 }}
                                onClick={() => {
                                    this.setState({ id: item._id, alt: item.alt, imgUrl: item.src, title: item.title, link: item.link, titleChange: 'ảnh slide', textChange: 'Sửa' })
                                }}>Sửa</button>
                            <SlideDelete item={item} deleteData={deleteData} {...this.props} />
                        </td>
                    </tr >
                )
            })
        }
        // Get Background
        let backgrounds;
        if (this.props.images.backgrounds) {
            backgrounds = this.props.images.backgrounds.map((item, key) => {
                return (
                    <tr key={key}>
                        <td><img className="img-slide-background" src={item.src} alt="khong tai dc" /></td>

                        <td>{item.title}</td>
                        <td>{item.link}</td>
                        <td>
                            <button type="button" className="btn btn-primary" data-toggle="modal" data-target="#myModalSua"
                                onClick={() => {
                                    this.setState({ id: item._id, alt: item.alt, imgUrl: item.src, title: item.title, link: item.link, titleChange: 'ảnh background', textChange: 'Sửa' })
                                }}>Sửa</button>
                        </td>
                    </tr >
                )
            })
        }
        return (
            <div>
                {/*--------------------------------------------- Modal Sua* --------------------------------*/}
                <div className="modal fade" id="myModalSua" role="dialog">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title text-center font-weight-bold" style={{ width: "100vh", color: "#0069D9" }}>{this.state.textChange}&nbsp;{this.state.titleChange}</h4>
                            </div>
                            <div className="modal-body">
                                <h6> Ảnh :</h6>
                                <input 
                                    type="file" 
                                    className="myFile" 
                                    accept="image/*"
                                    multiple 
                                    onChange={(e) => this.handleSelectedFile(e)} />
                                <br /><br />
                                <div>
                                    {this.state.imgUrl ? <img className="imgUrl" src={this.state.imgUrl} alt="khong tai dc" /> : <div></div>}
                                </div>
                                <br /><br /><br /><br />
                                <div>
                                    <h6> Alt :</h6>
                                    <textarea placeholder="Alt" onChange={(e) => { this.setState({ alt: e.target.value }) }} value={this.state.alt} className="textarea" />
                                </div>
                                <div>
                                    <h6> Tiêu đề :</h6>
                                    <textarea placeholder="Tiêu đề" onChange={(e) => { this.setState({ title: e.target.value }) }} value={this.state.title} className="textarea" />
                                </div>
                                <div>
                                    <h6> Link bài viết :</h6>
                                    <textarea placeholder="Link" onChange={(e) => { this.setState({ link: e.target.value }) }} value={this.state.link} className="textarea" />
                                </div>
                            </div>
                            <div className="modal-footer">
                            <button type="button" className="btn btn-danger" data-dismiss="modal" onClick={(e) => this.HandleSetState()}>Hủy</button>
                                <button type="button" className="btn btn-success" data-dismiss="modal" onClick={(e) => this.saveChange()}>Xác nhận</button>
                            </div>
                        </div>
                    </div>
                </div>
                {/* table */}
                <h4>1. ẢNH SLIDE</h4>
                <div className="table-responsive">
                    {(!this.props.isLoadingText && slides) 
                      ? <table className="table table-hover">
                            <thead>
                                <tr className="table-dark text-dark">
                                    <th >Image(1920 x 1080)</th>
                                    <th>Tiêu đề</th>
                                    <th>Link</th>
                                    <th colSpan={2} >Thao tác</th>
                                </tr>
                            </thead>
                            <tbody>
                                {slides}
                            </tbody>
                        </table>
                        : <LoadingModal />}
                </div>
                {this.props.images.slides && this.props.images.slides.length < 10 ?
                <button
                    id="addSlide"
                    type="button"
                    className="btn btn-success"
                    data-toggle="modal" data-target="#myModalSua"
                    style={{ width: "100%" }}
                    onClick={() => {
                        this.setState({
                            titleChange: 'ảnh slide',
                            textChange: 'Thêm',
                            id: '',
                            selectedFile: null,
                            alt: '',
                            title: '',
                            link: '',
                            text: '',
                            imgUrl: '',
                        })
                    }}
                >Thêm</button>
                : <div></div>}
                <br />
                <br />
                <h4>2. ẢNH BACKGROUND</h4>
                <div className="table-responsive">
                    {(!this.props.isLoadingText && backgrounds) ?
                        <table className="table table-hover">
                            <thead>
                                <tr className="table-dark text-dark">
                                    <th>Image(1920 x 1080)</th>
                                    <th>Tiêu đề</th>
                                    <th>Link</th>
                                    <th colSpan={2}>Thao tác</th>
                                </tr>
                            </thead>
                            <tbody>
                                {backgrounds}
                            </tbody>
                        </table>
                        : <LoadingModal />}
                </div>
            </div>
        );
    }
}

export default Slide;