import { put, takeEvery } from 'redux-saga/effects';
import loginAPI from "../fetchAPI/loginAPI"
import uploadImageAPI from '../fetchAPI/common/UploadImageAPI'
import deleteImagesAPI from '../fetchAPI/common/DeleteImagesAPI'
import * as types from '../constant';

function* login(action) {
  try {
    const responseData = yield loginAPI(action.payload);
    if (responseData.is_success) {
      localStorage.setItem('isLogin', 'isLogin')
    } else {
      alert('Sai Username hoặc Password!')
    }
    yield put({
      type: types.LOGIN_SUCCESS,
      payload: responseData
    });
    window.location.reload();
  } catch (error) {
    yield put({
      type: types.LOGIN_FAILURE,
      payload: {
        errorMessage: error.message
      }
    });
  }
}

function* uploadSingleImage(action) {
  try {
    const response = yield uploadImageAPI(action.payload);
    if(response.url){
      yield put({ 
        type: types.UPLOAD_IMAGE_SUCCESS, 
        payload: response.url 
      })
    } else {
      yield put({ 
        type: types.UPLOAD_IMAGE_FAILURE, 
        payload: "Lỗi tải ảnh lên máy chủ: " + response.error 
      })
    }
  } catch (error) {
    yield put({
      type: types.UPLOAD_IMAGE_FAILURE,
      payload: "Lỗi tải ảnh lên máy chủ: " + error.message
    });
  }
}

function deleteImages(action) {
  try {
    deleteImagesAPI(action.payload);
  } catch (err) {
    console.log(err);
  }
}

export const CommonSaga = [
  takeEvery(types.LOGIN_REQUEST, login),
  takeEvery(types.UPLOAD_IMAGE_REQUEST, uploadSingleImage),
  takeEvery(types.DELETE_IMAGES_REQUEST, deleteImages),
]
