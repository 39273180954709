import React from 'react';
import EventContainer from '../containers/EventContainer';

class EventPage extends React.Component {
  render() {
    return (
      <div className="EventPage">
        <div className="card">
          <div className="card-header">
            <h1>Danh Sách Sự Kiện</h1>
           </div>
          <div className="card-body">
            <EventContainer />
          </div>
        </div>
      </div>
    );
  }
}

export default EventPage;