import React from 'react';
// import { domain } from '../../constant'
import { Button, Modal, Form, Row, Col, Image } from 'react-bootstrap'
import { library } from '@fortawesome/fontawesome-svg-core';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
library.add(faPlusCircle)

class UpdatePopularTourComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            file: "",
            alt: '',
            content: '',
            imgUrl: "",
            formValidate: "",
            showUpdateModal: false
        }
    }

    handleSelectedFile(file) {
        // Valid file extension
        if (file && file.name.match(/.(jpg|jpeg|png)$/gi)) {
            this.setState({ file: file, imgUrl: URL.createObjectURL(file) })
        }
    }

    updateTour() {
        let payload = new FormData()
        // Verify image
        if (this.state.file) {
            // Append file
            payload.append("image", this.state.file, this.state.file.name)
        }
        // Append string field
        payload.append("alt", this.state.alt.trim() ? this.state.alt.trim() : null)
        payload.append("description", this.state.content)
        payload.append("id", this.props.item.id)
        this.props.updateTour(payload)
    }

    render() {
        return (
            <div >
                <Button
                    variant="primary"
                    onClick={() => this.setState({
                        file: "",
                        imgUrl: this.props.item.name,
                        content: this.props.item.content,
                        alt: this.props.item.alt,
                        showUpdateModal: true
                    })}
                >Sửa</Button>

                <Modal
                    className="wrap-modal-popular-tour"
                    show={this.state.showUpdateModal}
                    onHide={() => this.setState({ showUpdateModal: false })}
                    size="lg"
                    aria-labelledby="example-custom-modal-styling-title"
                    style={{ width: "100%" }}>
                    <Modal.Header closeButton>
                        <Modal.Title id="example-custom-modal-styling-title" style={{ width: "100%", textAlign: "center" }}>
                            Sửa tour tiêu biểu
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        <Form.Group as={Row} style={{ textAlign: "center" }}>
                            <Form.Label column sm={4}>Ảnh</Form.Label>
                            <Col sm={8}>
                                {this.state.imgUrl &&
                                    <Image
                                        style={{ border: "1px solid #888", marginBottom: 5 }}
                                        src={this.state.imgUrl} alt="" fluid />
                                }
                                <div style={{ textAlign: "center" }}>
                                    <label style={{
                                        border: "1px solid #ccc",
                                        display: "inline-block",
                                        padding: "6px 12px",
                                        cursor: "pointer",
                                        borderRadius: 5,
                                        background: "#008F30",
                                        color: "white"
                                    }}>
                                        <input type="file"
                                            style={{ display: "none" }}
                                            onClick={(e) => {e.target.value = ""}}
                                            onChange={(e) => this.handleSelectedFile(e.target.files[0])}
                                        />
                                        <FontAwesomeIcon
                                            className="fa fa-plus-circle"
                                            icon={faPlusCircle} />
                                        {" Thay thế ảnh"}
                                    </label>
                                </div>

                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} style={{ textAlign: "center" }}>
                            <Form.Label column sm={4}>Alt</Form.Label>
                            <Col sm={8}>
                                <Form.Control
                                    className={this.state.formValidate}
                                    value={this.state.alt}
                                    placeholder="Mô tả về ảnh"
                                    onChange={(e) => this.setState({ alt: e.target.value.trimStart() })}
                                />
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} style={{ textAlign: "center" }}>
                            <Form.Label column sm={4}>Mô tả</Form.Label>
                            <Col sm={8}>
                                <Form.Control
                                    className={this.state.formValidate}
                                    as="textarea" rows="3"
                                    placeholder="Thêm mô tả"
                                    value={this.state.content}
                                    onChange={(e) => this.setState({ content: e.target.value.trimStart() })}
                                />
                            </Col>
                        </Form.Group>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="danger" onClick={() => 
                            this.setState({ showUpdateModal: false })}
                            >Hủy</Button>
                        <Button variant="success"
                            onClick={() => {
                                if ((this.state.content && this.state.imgUrl) &&
                                    (this.state.content && this.state.imgUrl) !== null) {
                                    this.updateTour();
                                    this.setState({ showUpdateModal: false });
                                } else {
                                    // this.setState({ formValidate: "invalid-input-popular-tour" });
                                    alert("Nhập thiếu các trường cần thiết");
                                }
                            }}>Xác nhận</Button>
                    </Modal.Footer>
                </Modal>

                {/* <button type="button" className="btn btn-primary"
                    data-toggle="modal" data-target={"#" + this.props.update}
                    onClick={() => {
                        this.setState({
                            file: "",
                            imgUrl: domain + "/" + this.props.item.name,
                            content: this.props.item.content,
                            alt: this.props.item.alt
                        })
                    }}>Sửa</button> */}

                {/* <div className="modal fade" id={this.props.update} role="dialog">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h3 className="modal-title" style={{ width: "100%", textAlign: "center" }} >Sửa thông tin hữu ích</h3>
                            </div>
                            <div className="modal-body">
                                <div>
                                    <div className="divActionUpdatePopuTour">
                                        <label className="name-label" >Mô tả</label>
                                        <textarea type="text" value={this.state.content} className="form1 textareaUpdatePopuTour" placeholder="Thêm mô tả"
                                            onChange={(e) => { this.setState({ content: e.target.value }) }} />
                                        <div className="clear" ></div>
                                    </div>

                                    <div>
                                        <label className="name-label" >Alt</label>
                                        <input type="text" value={this.state.alt} className="form1" placeholder="Alt"
                                            onChange={(e) => { this.setState({ alt: e.target.value }) }} />
                                        <div className="clear" ></div>
                                    </div>
                                    <div >
                                        <div>
                                            <label className="name-label" >Ảnh</label>
                                            <input type="file"
                                                className="form1"
                                                onChange={(e) => { this.handleSelectedFile(e.target.files[0]) }} />
                                            <div className="clear" ></div>
                                        </div>

                                    </div>
                                    {this.state.imgUrl &&
                                        <div >
                                            <div>
                                                <img alt="" src={this.state.imgUrl} style={{ width: "100%" }} />
                                                <div className="clear" ></div>
                                            </div>

                                        </div>
                                    }
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-primary"
                                            onClick={() =>
                                                this.setState({
                                                    file: "",
                                                    alt: '',
                                                    content: '',
                                                    imgUrl: ''
                                                })
                                            }>Clear
                                    </button>
                                        <button type="button"
                                            data-dismiss="modal"
                                            className="btn btn-danger"  >
                                            Hủy </button>
                                        <button
                                            type="button" data-dismiss="modal"

                                            onClick={() => { this.updateTour() }}
                                            className="btn btn-success"  >
                                            Xác nhận
                                    </button>


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
            </div>

        )
    }
}
export default UpdatePopularTourComponent;
