import React, { Component } from "react"
import { connect } from 'react-redux';
import * as actions from "../actions/PopularTourActions"
import PopularTourComponent from "../components/PopularTourComponents//PopularTourComponents"
class PopularTourContainer extends Component {
componentDidMount(){
    this.props.initLoad();
}
    render() {
        return (
            <div>
				<PopularTourComponent {...this.props}/>
            </div>
            
        );
    }
}

const mapStateToProps = (state) => {
    return {
        listPopularTour: state.popularTour.listTour
    }
}

const mapDispacthToProps = (dispatch) => {
    return { 
            initLoad: (data) => {
              dispatch(actions.getListTour(data));
            },
            updateTour: (payload) => {
                dispatch(actions.updateTour(payload))   
            },
            createTour: (payload) => {
                dispatch(actions.createTour(payload))
            },
            deleteTour: (payload) => {
                dispatch(actions.deleteTour(payload))
            },
            searchTour: (payload) => {
                dispatch ( actions.searchTour(payload))
            }


}}
export default connect(mapStateToProps, mapDispacthToProps)(PopularTourContainer);