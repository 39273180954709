import * as types from '../../constant'
export function getFAQ(payload){
    return({
        type:types.GET_FAQ_REQUEST,
        payload
    })
}

export function addFAQ(payload){
    return({
        type:types.ADD_FAQ_REQUEST,
        payload
    })
}

export function updateFAQ(payload){
    return({
        type:types.UPDATE_FAQ_REQUEST,
        payload
    })
}

export function deleteFAQ(payload){
    return({
        type:types.DELETE_FAQ_REQUEST,
        payload
    })
}