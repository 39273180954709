import React from 'react';
import PriceRequestContainer from "../containers/PriceRequestContainer"
class PriceRequestPage extends React.Component {
  render() {
    return (
      <div className="PriceRequestPage">
        <div className="card">
          <div className="card-header"><h1>Danh Sách Các Yêu Cầu Báo Giá</h1></div>
          <div className="card-body">
            <PriceRequestContainer />
          </div>
        </div>
      </div>
    );
  }
}

export default PriceRequestPage;