import { put, takeEvery } from 'redux-saga/effects';
import get from '../fetchAPI/useFulInforFetchAPI/getUsefulInfor'
import add from '../fetchAPI/useFulInforFetchAPI/addUsefulInfor'
import edit from '../fetchAPI/useFulInforFetchAPI/editUsefulInfor'
import deleteUseful from '../fetchAPI/useFulInforFetchAPI/deleteUSefulInfor'
import search from '../fetchAPI/useFulInforFetchAPI/searchUsefulInfor'
import * as types from '../constant';
import refreshAPI from '../fetchAPI/common/refreshAPI'

function* getListUsefulInfor(action) {
  try {
    const res = yield get(action.payload);
    yield put({
      type: types.GET_USEFULINFOR_SUCCESS,
      payload: res
    });
  } catch (error) {
    yield put({
      type: types.GET_USEFULINFOR_FAILURE,
      payload: {
        errorMessage: error.message
      }
    });
  }
}

function* addUsefulInfor(action) {
  try {
    const res = yield add(action.payload);
    yield put({
      type: types.ADD_USEFULINFOR_SUCCESS,
      payload: res
    });

    yield put({
      type: types.GET_USEFULINFOR_REQUEST,
      payload: 1
    })

  } catch (error) {
    yield put({
      type: types.ADD_USEFULINFOR_FAILURE,
      payload: {
        errorMessage: error.message
      }
    });
  }
}

function* editUsefulInfor(action) {
  try {
    const res = yield edit(action.payload.dataAPI);
    yield put({
      type: types.EDIT_USEFULINFOR_SUCCESS,
      payload: res
    });
    if (action.payload.textSearch) {
      yield put({
        type: types.SEARCH_USEFULINFOR_REQUEST,
        payload: {
          offset: action.payload.activePage,
          text: action.payload.textSearch
        }
      })
    } else {
      yield put({
        type: types.GET_USEFULINFOR_REQUEST,
        payload: action.payload.activePage
      })
    }
  } catch (error) {
    yield put({
      type: types.EDIT_USEFULINFOR_FAILURE,
      payload: {
        errorMessage: error.message
      }
    });
  }
}

function* refresh(action) {
  try {
      const response = yield refreshAPI(action.payload.dataAPI)
      yield put({
          type: types.REFRESH_USEFULINFOR_SUCCESS,
          payload: response
      })
      if (action.payload.textSearch) {
        yield put({
          type: types.SEARCH_USEFULINFOR_REQUEST,
          payload: {
            offset: action.payload.activePage,
            text: action.payload.textSearch
          }
        })
      } else {
        yield put({
          type: types.GET_USEFULINFOR_REQUEST,
          payload: action.payload.activePage
        })
      }
  } catch (error) {
      yield put({
          type: types.REFRESH_USEFULINFOR_FAILURE,
          payload: {
              errorMessage: error.message
          }
      })
  }
}

function* deleteUsefulInfor(action) {
  try {
    const res = yield deleteUseful(action.payload.dataAPI);
    yield put({
      type: types.DELETE_USEFULINFOR_SUCCESS,
      payload: res
    });
    let offset = action.payload.activePage
    if (action.payload.length === 1 && action.payload.totalPage > 1) {
      offset -= 1
    }
    if (action.payload.textSearch) {
      yield put({
        type: types.SEARCH_USEFULINFOR_REQUEST,
        payload: {
          offset: offset,
          text: action.payload.textSearch
        }
      })
    } else {
      yield put({
        type: types.GET_USEFULINFOR_REQUEST,
        payload: offset
      })
    }
  } catch (error) {
    yield put({
      type: types.DELETE_USEFULINFOR_FAILURE,
      payload: {
        errorMessage: error.message
      }
    });
  }
}
function* searchUsefulInfor(action) {
  try {
    const response = yield search(action.payload);
    yield put({
      type: types.SEARCH_USEFULINFOR_SUCCESS,
      payload: response
    })
  } catch (error) {
    yield put({
      type: types.SEARCH_USEFULINFOR_FAILURE,
      payload: { errorMessage: error.message }
    })
  }
}

export const UsefulInformationSaga = [
  takeEvery(types.GET_USEFULINFOR_REQUEST, getListUsefulInfor),
  takeEvery(types.ADD_USEFULINFOR_REQUEST, addUsefulInfor),
  takeEvery(types.EDIT_USEFULINFOR_REQUEST, editUsefulInfor),
  takeEvery(types.DELETE_USEFULINFOR_REQUEST, deleteUsefulInfor),
  takeEvery(types.SEARCH_USEFULINFOR_REQUEST, searchUsefulInfor),
  takeEvery(types.REFRESH_USEFULINFOR_REQUEST, refresh)

];
