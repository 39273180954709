import * as types from '../../constant'
export default function callApi(id) {
    return new Promise((resolve, reject) => {
        const url = types.domain +'/update-post-date'
        fetch(url, {
          method: "PUT",
          headers: {"Content-type":"application/json"},
          body: JSON.stringify({id:id})
        })
          .then((response) => response.json())
          .then((res) => {
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      });
}