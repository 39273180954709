import React, { Component } from 'react'
import '../../styles/abouts/FAQ.css'
import LoadingModal from '../../commons/LoadingModal'

class FAQ extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: '',
            question: '',
            answer: ''
        }
    }
    validated() {
        if (this.state.question === '' || this.state.answer === '') {
            return false
        } else {
            alert('Cập Nhật Câu Hỏi Thành Công!')
            return true
        }
    }
    handleUpdateFAQ(data) {
        if (this.validated()) {
            let faqsEdit = {
                question: this.state.question,
                answer: this.state.answer,
                id: data._id
            }
            this.props.updateFAQ(faqsEdit);
            this.handleReset();
        } else {
            alert('Thiếu Câu Hỏi Hoặc Câu Trả Lời!')
        }
    }
    handleDeleteFAQ(id) {
        this.props.deleteFAQ(id);
        alert('Xoá thành công!')
    }
    handleReset() {
        this.setState({
            id: '',
            question: '',
            answer: ''
        })
    }
    render() {
        let listFAQs
        if (this.props.faqs) {
            listFAQs = this.props.faqs.map((item, key) => {
                let questItem = item.question.split("\n").map((queChild, idx) => {
                    return (
                        <p key={idx}>{queChild}</p>
                    )
                })
                let answerItem = item.answer.split("\n").map((ansChild, idx) => {
                    return (
                        <p key={idx} className="pFAQs">{ansChild}</p>
                    )
                })
                return (
                    <tr key={key}>
                        <td className="tdFAQsSTT">{key + 1}</td>
                        <td className="tdFQAsQues">
                            {questItem}
                        </td>
                        <td className="tdAnsFAQs">
                            {answerItem}
                        </td>
                        <td className="tdFAQsAction">
                            <button type="button"
                                className="btn btn-primary buttonUpdateFAQs"
                                data-toggle="modal"
                                data-target={"#editFAQ" + key}
                                onClick={() => {
                                    this.setState({
                                        question: item.question,
                                        answer: item.answer,
                                    })
                                }}>Sửa</button>
                            <div className="modal fade" id={'editFAQ' + key} role="dialog">
                                <div className="modal-dialog">
                                    <div className="modal-content modalUpdateFAQs">
                                        <div className="modal-header">
                                            <h4 className="center-modal-title">Sửa nội dung câu hỏi</h4>
                                        </div>
                                        <div className="modal-body">
                                            <div className="divUpdateFAQs">
                                                <div>
                                                    <label className="labelUpdateFAQs">Câu hỏi:</label>
                                                </div>
                                                <div className="textUpdateFAQs">
                                                    <input className="inputUpdateFAQ"
                                                        value={this.state.question}
                                                        onChange={(e) => this.setState({ question: e.target.value })}
                                                        placeholder="Nhập câu hỏi" />
                                                </div>
                                            </div>
                                            <div className="divUpdateFAQs">
                                                <div>
                                                    <label className="labelUpdateFAQs">Câu trả lời:</label>
                                                </div>
                                                <div className="textUpdateFAQs">
                                                    <textarea
                                                        className="resizeUpdateFAQ"
                                                        value={this.state.answer}
                                                        onChange={(e) => this.setState({ answer: e.target.value })}
                                                        placeholder="Nhập câu trả lời" />
                                                </div>
                                            </div>
                                            <div className="btnUpdateFAQs">
                                                <button className="btn btn-danger buttonCloseFAQs"
                                                    data-dismiss="modal"
                                                    style={{ marginRight: 5 }}
                                                    onClick={() => this.handleReset()}>Hủy</button>
                                                <button className="btn btn-success"
                                                    data-dismiss="modal"
                                                    onClick={() => this.handleUpdateFAQ(item)}>Xác nhận</button>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <button type="button"
                                className="btn btn-danger"
                                data-toggle="modal"
                                data-target={"#deleteFAQs" + key}>Xóa</button>
                            <div className="modal fade" id={'deleteFAQs' + key} role="dialog">
                                <div className="modal-dialog">
                                    <div className="modal-content">
                                        <div className="modal-body">
                                            <div className="divDelFAQs">
                                                <h5>Bạn Có Chắc Chắn Xóa Câu Hỏi</h5>
                                                <div className="btnDelFAQs">
                                                    <button type="button"
                                                        className="btn btn-danger btnCancelFAQs"
                                                        style={{ marginRight: 5 }}
                                                        data-dismiss="modal">Huỷ</button>
                                                    <button type="button" className="btn btn-primary"
                                                        data-dismiss="modal"
                                                        onClick={() => this.handleDeleteFAQ(item._id)}>Xác Nhận</button>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </td>
                    </tr>
                )
            })
        }
        return (
            <div>
                <h3>Quản lý nội dung câu hỏi</h3>
                <div className="tableFAQsData">
                    {(!this.props.isLoading && listFAQs) ?
                        <table className="table table-striped">
                            <thead>
                                <tr className="table-dark text-dark">
                                    <th className="thFAQsSTT">STT</th>
                                    <th>Câu hỏi</th>
                                    <th className="thAnsFAQs">Câu trả lời</th>
                                    <th className="thFAQsAction">Tùy chọn</th>
                                </tr>
                            </thead>
                            <tbody>
                                {listFAQs}
                            </tbody>

                        </table>
                        : <LoadingModal />}
                </div>
            </div>
        )
    }
}

export default FAQ