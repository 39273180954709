import React from 'react';
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
library.add(faSearch)
class SearchUSBeautyComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            inputState: ''
        };
    }
    handleClick() {
        if (this.state.inputState) {
            this.props.search(this.state.inputState, 1);
        }
    }
    search(evt) {
        this.setState({ inputState: evt.target.value });
    }

    render() {
        return (
            <div className="search-container row clearfix reset-row-margin-both">
                <input 
                    className="col-10 col-sm-11"
                onChange={(e) => { 
                    if (!e.target.value) {
                        this.props.onPaginate(1);    // Get all events on first page
                    }
                    this.search(e) }}
                    onKeyPress={(e) => {
                        // If user hit enter and search input has value
                        if (e.key === "Enter" && this.state.inputState) {
                            this.props.search(this.state.inputState, 1);
                        }

                    }}
                    type="text" placeholder="Tìm kiếm..." name="search" />
                <button className="btn-search btn-search col-2 col-sm-1"  onClick={(e) => { this.handleClick() }}>
                    <FontAwesomeIcon className="icon-search" icon={faSearch} /></button>
            </div>
        )
    }
}

export default SearchUSBeautyComponent;