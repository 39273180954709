import * as types from '../constant';
const DEFAULT_STATE = {
  listTopic: [],
  dataFetched: false,
  isFetching: false,
  error: false,
  errorMessage: null
}

export default (state = DEFAULT_STATE, action) => {
    switch (action.type) {
      case types.GET_USBEAUTYTOPIC_REQUEST:
        return {
          ...state,
          isFetching: true,
        }
      case types.GET_USBEAUTYTOPIC_SUCCESS:
        return {
          ...state,
          isFetching: false,
          dataFetched: true,
          error: false,
          errorMessage: null,
          listTopic: action.payload.types
        }
      case types.GET_USBEAUTYTOPIC_FAILURE:
        return {
          ...state,
          isFetching: false,
          error: true,
          errorMessage: action.payload.errorMessage
        }
      case types.ADD_USBEAUTYTOPIC_REQUEST:
        return {
          ...state,
          isFetching: true
        }
      case types.ADD_USBEAUTYTOPIC_SUCCESS:
        return {
          ...state,
          isFetching: false
        }
      case types.ADD_USBEAUTYTOPIC_FAILURE:
        return {
          ...state,
          isFetching: false,
          error: true,
          errorMessage: action.payload.errorMessage
        }
      case types.EDIT_USBEAUTYTOPIC_REQUEST:
        return {
          ...state,
          isFetching: true
        }
      case types.EDIT_USBEAUTYTOPIC_SUCCESS:
        return {
          ...state,
          isFetching: false
        }
      case types.EDIT_USBEAUTYTOPIC_FAILURE:
        return {
          ...state,
          isFetching: false,
          error: true,
          errorMessage: action.payload.errorMessage
        }
      case types.DELETE_USBEAUTYTOPIC_REQUEST:
        return {
          ...state,
          isFetching: true
        }
      case types.DELETE_USBEAUTYTOPIC_SUCCESS:
        return {
          ...state,
          isFetching: false
        }
      case types.DELETE_USBEAUTYTOPIC_FAILURE:
        return {
          ...state,
          isFetching: false,
          error: true,
          errorMessage: action.payload.errorMessage
        }
      
      default:
        return state;
    }
  };