import { domain } from '../../constant'

export default function searchListRequest(text, activePage) {
  return new Promise((resolve, reject) => {
    const url = domain + `/search-order/5/${activePage}/${text}`;
    fetch(url, {
      method: "GET"
    })
      .then((response) => response.json())
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}