import React from 'react';
import '../styles/PopularTours.css'
import { library } from '@fortawesome/fontawesome-svg-core';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import UpdatePopularTourComponent from "../PopularTourComponents/UpdatePopularTourComponent"
import DeletePopularTourComponent from "../PopularTourComponents/DeletePopularTourComponent"
// import { domain } from '../../constant'
import { Table, Button, Modal, Form, Row, Col, Image } from 'react-bootstrap'
library.add(faPlusCircle)

class PopularTourComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "",
      imgUrl: "",
      file: "",
      alt: '',
      content: '',
      inputText: '',
      formValidate: "",
      textValidate: "text",
      showCreateModal: false
    }
  }

  handleSelected(file) {
    if (file && file.name.match(/.(jpg|jpeg|png)$/gi)) {
      this.setState({ file: file, imgUrl: URL.createObjectURL(file) })
    } else {
      this.setState({ imgUrl: '' })
    }
  }
  createTour() {
    let payload = new FormData()
    if (this.state.file) {
      payload.append("image", this.state.file, this.state.file.name)
      payload.append("alt", this.state.alt.trim() ? this.state.alt.trim() : null)
      payload.append("description", this.state.content)
      this.props.createTour(payload)
      this.setState({
        id: "",
        imgUrl: "",
        file: "",
        alt: '',
        content: '',
        inputText: '',
        formValidate: "",
        textValidate: "text"
      })
    }
  }
  render() {
    let show;

    if (this.props.listPopularTour) {
      show = this.props.listPopularTour.map((item, key) => {
        let update = "update" + key;
        let deleteData = "delete" + key;
        let contentP = item.content.split("\n").map((contentChild, idx) => {
          return (<p key={idx}>{contentChild}</p>);
        });
        return (
          <tr className="wrap-table-data" key={key}>
            <td>
              <img src={item.name} style={{
                width: "80%",
                height: "200px",
                objectFit: "contain"
              }}
                alt="khong tai dc"
              />
            </td>
            <td>{item.alt}</td>
            <td style={{ width: "30%" }}>{contentP}</td>
            <td className="center-actions-td">
              <div className="actions-parent-div">
                <UpdatePopularTourComponent item={item} update={update} {...this.props} />
                <DeletePopularTourComponent item={item} deleteData={deleteData} {...this.props} />
              </div>
            </td >
          </tr >
        )
      })
    }

    return (
      <div className="wrap-popular-tour" >
        <Button
            block={!this.state.isUpdateComponent}
            variant="success"
            onClick={() => this.setState({ file:"", alt: "", content: "", imgUrl: "", showCreateModal: true })}
          >Thêm
          </Button>
        <Table striped bordered hover className="fixed-table-event">
          <thead>
            <tr className="table-header-row wrap-header-table">
              <th> Ảnh </th>
              <th> Alt </th>
              <th> Mô tả</th>
              <th> Thao tác</th>
            </tr>
          </thead>
          <tbody>
            {show}
          </tbody>
        </Table>
        <div>
          <Modal
            show={this.state.showCreateModal}
            onHide={() => this.setState({ showCreateModal: false })}
            size="lg"
            aria-labelledby="example-custom-modal-styling-title"
            style={{width:"100%"}}
          >
            <Modal.Header closeButton>
              <Modal.Title id="example-custom-modal-styling-title" style={{width:"100%",textAlign:"center"}}>
                Thêm các tour tiêu biểu
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>

              <Form.Group as={Row} style={{ textAlign: "center" }}>
                <Form.Label column sm={4}>
                  Ảnh
                </Form.Label>
                <Col sm={8}>
                  {this.state.imgUrl && 
                  <Image 
                    style={{border: "1px solid #888", marginBottom: 5}} 
                    src={this.state.imgUrl} alt="" fluid />
                  }
                  <div style={{ textAlign: "center" }}>
                    <label style={{
                      border: "1px solid #ccc",
                      display: "inline-block",
                      padding: "6px 12px",
                      cursor: "pointer",
                      borderRadius: 5,
                      background: "#008F30",
                      color: "white"
                    }}>
                      <input type="file"
                        style={{ display: "none" }}
                        onChange={(e) => this.handleSelected(e.target.files[0])}
                      />
                      <FontAwesomeIcon
                        className="fa fa-plus-circle"
                        icon={faPlusCircle} />
                      {this.state.file ? " Thay thế ảnh" : " Chọn ảnh"}
                    </label>
                  </div>
                  
                </Col>
              </Form.Group>

              <Form.Group as={Row} style={{ textAlign: "center" }}>
                <Form.Label column sm={4}>
                  Alt
                </Form.Label>
                <Col sm={8}>
                  <Form.Control
                    className={this.state.formValidate}
                    value={this.state.alt}
                    placeholder="Mô tả về ảnh"
                    onChange={(e) => this.setState({ alt: e.target.value.trimStart() })}
                  />
                </Col>
              </Form.Group>

              <Form.Group as={Row} style={{ textAlign: "center" }}>
                <Form.Label column sm={4}>
                  Mô tả
                </Form.Label>
                <Col sm={8}>
                  <Form.Control
                    className={this.state.formValidate}
                    as="textarea" rows="3"
                    placeholder="Thêm mô tả"
                    value={this.state.content}
                    onChange={(e) => this.setState({ content: e.target.value.trimStart() })}
                  />
                </Col>
              </Form.Group>

            </Modal.Body>

            <Modal.Footer>
              <Button variant="danger" onClick={() => this.setState({showCreateModal: false})}>Hủy</Button>
              <Button variant="success"
                onClick={() => {
                  if ((this.state.content && this.state.imgUrl) &&
                    (this.state.content && this.state.imgUrl) !== null) { 
                      this.createTour();
                      this.setState({showCreateModal: false});
                  } else {
                    this.setState({ formValidate: "fromValidated" })
                    alert("Nhập thiếu các trường cần thiết");
                  }
                }}>Xác nhận</Button>
            </Modal.Footer>
          </Modal>

        </div>

      </div>

    );
  }
}




export default PopularTourComponent;