import * as types from "../constant"
export function GetListRequest(payload){
    return({
        type: types.GET_LIST_REQUEST,
        payload
    });
}

export function DeleteRequest(payload){
    return ({
        type: types.DELETE_REQUEST_REQUEST,
        payload
    })
}

export function SearchRequest(payload) {
    return ({
        type: types.SEARCH_LIST_REQUEST,
        payload
    })
}