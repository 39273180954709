import { put, takeEvery } from 'redux-saga/effects';
import getListTour from '../fetchAPI/PopularTourAPI/GetPopularTours';
import deleteTours from "../fetchAPI/PopularTourAPI/deletePopularTour"
import updateListTour from "../fetchAPI/PopularTourAPI/UpdatePopularTour"
import createTours from "../fetchAPI/PopularTourAPI/CreatePopularTour"
import * as types from '../constant';
function* getPopularTour() {
  try {
    const res = yield getListTour();
    let tours = res.tours.map(
      tour => {
        return {
          ...tour,
          id: tour._id,
          file: tour.name,
          alt: tour.alt,
          content: tour.description
        }
      }
    )
    yield put({
      type: types.GET_POPULAR_TOUR_SUCCESS,
      payload: tours
    });
  } catch (error) {
    yield put({
      type: types.GET_POPULAR_TOUR_FAILURE,
      payload: {
        errorMessage: error.message
      }
    });
  }
}

function* updatePopularTour(action) {
  try {
    const res = yield updateListTour(action.payload);
    yield put({
      type: types.UPDATE_POPULAR_TOUR_SUCCESS,
      payload: res
    });
    yield put({
      type: types.GET_POPULAR_TOUR_REQUEST,
    })

  } catch (error) {
    yield put({
      type: types.UPDATE_POPULAR_TOUR_FAILURE,
      payload: {
        errorMessage: error.message
      }
    });
  }
}

function* createTour(action) {
  try {
    const res = yield createTours(action.payload);
    yield put({
      type: types.CREATE_POPULAR_TOUR_SUCCESS,
      payload: res
    });

    yield put({
      type: types.GET_POPULAR_TOUR_REQUEST,
    })

  } catch (error) {
    yield put({
      type: types.CREATE_POPULAR_TOUR_FAILURE,
      payload: {
        errorMessage: error.message
      }
    });
  }
}

// function* searchTour(action) {
//   try {
//     const res = yield searchTours(action.payload);
//     let tours = res.tours.map(tour => {
//       return {
//         ...tour,
//         id: tour._id,
//         file: tour.name,
//         alt: tour.alt,
//         content: tour.description
//       }
//       })
        
//     yield put({
//       type: types.SEARCH_POPULAR_TOUR_SUCCESS,
//       payload: tours
//     });

//   } catch (error) {
//     yield put({
//       type: types.SEARCH_POPULAR_TOUR_FAILURE,
//       payload: {
//         errorMessage: error.message
//       }
//     });
//   }
// }

function* deleteTour(action) {
  try {
    const res = yield deleteTours(action.payload);
    yield put({
      type: types.DELETE_POPULAR_TOUR_SUCCESS,
      payload: res
    });
    yield put({
      type: types.GET_POPULAR_TOUR_REQUEST
    })
  } catch (error) {
    yield put({
      type: types.DELETE_POPULAR_TOUR_FAILURE,
      payload: {
        errorMessage: error.message
      }
    });
  }
}

export const popularTourSaga = [
  takeEvery(types.UPDATE_POPULAR_TOUR_REQUEST, updatePopularTour),
  takeEvery(types.CREATE_POPULAR_TOUR_REQUEST, createTour),
  takeEvery(types.GET_POPULAR_TOUR_REQUEST, getPopularTour),
  // takeEvery(types.SEARCH_POPULAR_TOUR_REQUEST, searchTour),
  takeEvery(types.DELETE_POPULAR_TOUR_REQUEST, deleteTour)


];
