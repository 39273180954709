import * as types from '../../constant'
export function getText(payload){
    return({
        type:types.GET_TEXT_INTRO_REQUEST,
        payload
    })
} 
export function updateText(payload){
    return({
        type: types.UPDATE_TEXT_INTRO_REQUEST,
        payload
    })
}
export function getMem(payload){
    return({
        type:types.GET_MEM_INTRO_REQUEST,
        payload
    })
}
export function addMem(payload){
    return({
        type:types.ADD_MEM_INTRO_REQUEST,
        payload
    })
}
export function updateMem(payload){
    return({
        type:types.UPDATE_MEM_INTRO_REQUEST,
        payload
    })
}
export function deleteMem(payload){
    return({
        type:types.DELETE_MEM_INTRO_REQUEST,
        payload
    })
}