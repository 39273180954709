import { connect } from 'react-redux'
import React, { Component } from 'react'
import PostForm from "../components/AdditionOrEditionPost"
import {
    POST_TYPE_FLAG,
    SHOW_NOTIFY_MODAL,
    DELETE_IMAGES_REQUEST,
    GET_USBEAUTYTOPIC_REQUEST,
    ADD_USBEAUTY_REQUEST,
    ADD_USAINFOR_REQUEST,
    ADD_USEFULINFOR_REQUEST,
    EDIT_USAINFOR_REQUEST,
    EDIT_USEFULINFOR_REQUEST,
    EDIT_USBEAUTY_REQUEST
} from '../constant'

class PostFormContainer extends Component {
    componentDidMount = () => {
        if(sessionStorage.getItem('post.type') === POST_TYPE_FLAG.USA_BEAUTY)
            this.props.getTopic()
    }

    render() {
        return (
            <div>
                <PostForm {...this.props} />
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        topics: state.type.listTopic
        // urlCoverImg: state.common.urlCoverImg
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        showNotifyModal: (msg) => dispatch({ type: SHOW_NOTIFY_MODAL, payload: msg }),
        deleteUnnecesaryImage: (payload) => dispatch({ type: DELETE_IMAGES_REQUEST, payload }),
        getTopic: () => dispatch({ type: GET_USBEAUTYTOPIC_REQUEST }),
        addUSBeautyPost: (payload) => dispatch({ type: ADD_USBEAUTY_REQUEST, payload }),
        updateUSBeautyPost: (payload) => dispatch({ type: EDIT_USBEAUTY_REQUEST, payload }),
        addUSInfo: (payload) => dispatch({ type: ADD_USAINFOR_REQUEST, payload }),
        updateUSInfo: (payload) => dispatch({ type: EDIT_USAINFOR_REQUEST, payload }),
        addUsefulInfo: (payload) => dispatch({ type: ADD_USEFULINFOR_REQUEST, payload }),
        updateUsefulInfo: (payload) => dispatch({ type: EDIT_USEFULINFOR_REQUEST, payload }),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(PostFormContainer)