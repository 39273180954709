import { domain } from '../../constant'

export default function updateListTour(payload) {
  return new Promise((resolve, reject) => {
    const url = domain + "/update-representative-tour"
    // const url = " http://localhost:3001/popularTour" 
    fetch(url, {
      method: 'PUT',
      body: payload
    })
      .then((response) => response.json())
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
