import { domain } from "../../../constant";

export default function addTermAPI(data) {

  return new Promise((resolve, reject) => {
    const url = domain + '/add-new-term';
    fetch(url, {
      method: 'POST',
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
