import { put, takeEvery } from 'redux-saga/effects'
import DeleteImages from '../../fetchAPI/HomeAdmin/DeleteImage'
import * as types from '../../constant';
function* deleteSlide(action) {
    try {
        const response = yield DeleteImages(action.payload)
        yield put({
            type: types.DELETE_IMAGE_SUCCESS,
            payload: response
        })
        yield put({
            type: types.GET_IMAGE_REQUEST
        })

    } catch (error) {
        yield put({
            type: types.DELETE_IMAGE_FAILURE,
            payload: {
                errorMessage: error.message
            }
        })
    }
}
export const DeleteImage = [
    takeEvery(types.DELETE_IMAGE_REQUEST, deleteSlide)
]