import { domain } from "../../../constant";

export default function addFAQsAPI(data) {
  return new Promise((resolve, reject) => {
    const url = domain + "/add-faq";

    fetch(url, {
      method: 'POST',
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
