import * as types from "../constant"

const State_Default = {
    listRequest: [],
    activePagesRequest: 1,
    isFetching: false,
    text: [],
    totalPages: 0
}
export default (state = State_Default, action) => {
    switch (action.type) {
        case types.GET_LIST_REQUEST:
            return {
                ...state,
                isFetching: true,
                activePagesRequest: action.payload
            }
        case types.GET_LIST_SUCCESS:
            return {
                ...state,
                isFetching: false,
                listRequest: action.payload.listRequest,
                totalPages: action.payload.totalPages
            }
        case types.GET_LIST_FAILURE:
            return {
                ...state,
                errorMessage: action.payload.errorMessage,
                isFetching: false,
                totalPages: 0,
                listRequest: [],
                activePagesRequest: 0
            }

        case types.SEARCH_LIST_REQUEST:
            return {
                ...state,
                isFetching: true,
                activePagesRequest: action.payload.page
            }
        case types.SEARCH_LIST_SUCCESS:
            return {
                ...state,
                isFetching: false,
                listRequest: action.payload.listRequest,
                totalPages: action.payload.totalPages
            }

        case types.SEARCH_LIST_FAILURE:
            return {
                ...state,
                isFetching: false,
                errorMessage: action.payload.errorMessage,
                totalPages: 0,
                listRequest: [],
                activePagesRequest: 0
            }
        default:
            return state;
    }
}


