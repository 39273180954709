import { domain } from '../../constant'

export default function getListTours() {

  return new Promise((resolve, reject) => {
    const url = domain + "/get-representative-tours"
    // const url = " http://localhost:3001/popularTour" 
    fetch(url, {
      method: 'GET'
    })
      .then((response) => response.json())
      .then((res) => {
        resolve(res);
      }
      )
      .catch((error) => {
        reject(error);
      });
  });
}
