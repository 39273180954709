import {put, takeEvery} from 'redux-saga/effects'
import EditText from '../../fetchAPI/HomeAdmin/EditTextAPI'
import * as types from '../../constant';
function* editList(action){
    try{
        const response = yield EditText(action.payload)
        yield put({
            type: types.EDIT_TEXT_SUCCESS,
            payload: response
        })
        yield put({
            type:types.GET_TEXT_REQUEST
        })

    }catch (error){
        yield put({
            type:types.EDIT_TEXT_FAILURE,
            payload:{
                errorMessage : error.message
            }
        })
    }
}
export const EditTextSaga = [
    takeEvery(types.EDIT_TEXT_REQUEST,editList)
]