import React, { Component } from 'react';
import Routes from './routes';
import Menu from './components/commons/Menu'
import './App.css'
import * as pages from './pages'
import CommonContainer from './containers/CommonContainer'

class App extends Component {
  render() {
    return (
      <div className="App">
        <Menu />
        <CommonContainer />
        {
          localStorage.getItem('isLogin') === 'isLogin' 
          ? <Routes />
          : <pages.LoginPage />
        }
      </div>
    );
  }
}

export default App;
