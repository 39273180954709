import { put, takeEvery } from 'redux-saga/effects';
import get from '../fetchAPI/USBeautyFetchAPI/getUSBeauty'
import add from '../fetchAPI/USBeautyFetchAPI/addUSBeauty'
import edit from '../fetchAPI/USBeautyFetchAPI/editUSBeauty'
import deleteUseful from '../fetchAPI/USBeautyFetchAPI/deleteUSBeauty'
import search from '../fetchAPI/USBeautyFetchAPI/SearchUS'
import * as types from '../constant';
import refreshAPI from '../fetchAPI/common/refreshAPI'

function* getListUSBeauty(action) {
  try {
    const res = yield get(action.payload);
    yield put({
      type: types.GET_USBEAUTY_SUCCESS,
      payload: res
    });
  } catch (error) {
    yield put({
      type: types.GET_USBEAUTY_FAILURE,
      payload: {
        errorMessage: error.message
      }
    });
  }
}

function* addUSBeauty(action) {
  try {
    const res = yield add(action.payload);
    yield put({
      type: types.ADD_USBEAUTY_SUCCESS,
      payload: res
    });

    yield put({
      type: types.GET_USBEAUTY_REQUEST,
      payload: 1
    })

  } catch (error) {
    yield put({
      type: types.ADD_USBEAUTY_FAILURE,
      payload: {
        errorMessage: error.message
      }
    });
  }
}

function* editUSBeauty(action) {
  try {
    const res = yield edit(action.payload.dataAPI);
    yield put({
      type: types.EDIT_USBEAUTY_SUCCESS,
      payload: res
    });
    if (action.payload.textSearch) {
      yield put({
        type: types.SEARCH_USBEAUTY_REQUEST,
        payload: {
          offset: action.payload.activePage,
          text: action.payload.textSearch
        }
      })
    } else {
      yield put({
        type: types.GET_USBEAUTY_REQUEST,
        payload: action.payload.activePage
      })
    }
  } catch (error) {
    yield put({
      type: types.EDIT_USBEAUTY_FAILURE,
      payload: {
        errorMessage: error.message
      }
    });
  }
}

function* refresh(action) {
  try {
      const response = yield refreshAPI(action.payload.dataAPI)
      yield put({
          type: types.REFRESH_USBEAUTY_SUCCESS,
          payload: response
      })
      if (action.payload.textSearch) {
        yield put({
          type: types.SEARCH_USBEAUTY_REQUEST,
          payload: {
            offset: action.payload.activePage,
            text: action.payload.textSearch
          }
        })
      } else {
        yield put({
          type: types.GET_USBEAUTY_REQUEST,
          payload: action.payload.activePage
        })
      }
  } catch (error) {
      yield put({
          type: types.REFRESH_USBEAUTY_FAILURE,
          payload: {
              errorMessage: error.message
          }
      })
  }
}

function* deleteUSBeauty(action) {
  try {
    const res = yield deleteUseful(action.payload.dataAPI);
    yield put({
      type: types.DELETE_USBEAUTY_SUCCESS,
      payload: res
    });
    let offset = action.payload.activePage
    if (action.payload.length === 1 && action.payload.totalPage > 1) {
      offset -= 1
    }
    if (action.payload.textSearch) {
      yield put({
        type: types.SEARCH_USBEAUTY_REQUEST,
        payload: {
          offset: offset,
          text: action.payload.textSearch
        }
      })
    } else {
      yield put({
        type: types.GET_USBEAUTY_REQUEST,
        payload: offset
      })
    }

  } catch (error) {
    yield put({
      type: types.DELETE_USBEAUTY_FAILURE,
      payload: {
        errorMessage: error.message
      }
    });
  }
}

function* searchUSBeauty(action) {
  try {
    const res = yield search(action.payload);
    yield put({
      type: types.SEARCH_USBEAUTY_SUCCESS,
      payload: res
    })
  } catch (error) {
    yield put({
      type: types.SEARCH_USBEAUTY_FAILURE,
      payload: {
        errorMessage: error.message
      }
    })
  }
}

export const USBeautySaga = [
  takeEvery(types.GET_USBEAUTY_REQUEST, getListUSBeauty),
  takeEvery(types.ADD_USBEAUTY_REQUEST, addUSBeauty),
  takeEvery(types.EDIT_USBEAUTY_REQUEST, editUSBeauty),
  takeEvery(types.DELETE_USBEAUTY_REQUEST, deleteUSBeauty),
  takeEvery(types.SEARCH_USBEAUTY_REQUEST, searchUSBeauty),
  takeEvery(types.REFRESH_USBEAUTY_REQUEST, refresh)
];
