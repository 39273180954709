import * as types from '../constant';
const DEFAULT_STATE = {
  listUsefullInfor: [],
  dataFetched: false,
  isFetching: false,
  error: false,
  errorMessage: null,
  activePageUsefulInfor: 1,
  textSearch: '',
  totalPage: 1
}

export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case types.GET_USEFULINFOR_REQUEST:
      return {
        ...state,
        isFetching: true,
        activePageUsefulInfor: action.payload,
        textSearch: ''
      }
    case types.GET_USEFULINFOR_SUCCESS:
      return {
        ...state,
        isFetching: false,
        dataFetched: true,
        error: false,
        errorMessage: null,
        listUsefullInfor: action.payload.posts,
        totalPage: action.payload.total_pages
      }
    case types.GET_USEFULINFOR_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: true,
        errorMessage: action.payload.errorMessage
      }
    case types.ADD_USEFULINFOR_REQUEST:
      return {
        ...state,
        isFetching: true
      }
    case types.ADD_USEFULINFOR_SUCCESS:
      return {
        ...state,
        isFetching: false
      }
    case types.ADD_USEFULINFOR_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: true,
        errorMessage: action.payload.errorMessage
      }
    case types.EDIT_USEFULINFOR_REQUEST:
      return {
        ...state,
        isFetching: true
      }
    case types.EDIT_USEFULINFOR_SUCCESS:
      return {
        ...state,
        isFetching: false
      }
    case types.EDIT_USEFULINFOR_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: true,
        errorMessage: action.payload.errorMessage
      }
    case types.DELETE_USEFULINFOR_REQUEST:
      return {
        ...state,
        isFetching: true
      }
    case types.DELETE_USEFULINFOR_SUCCESS:
      return {
        ...state,
        isFetching: false
      }
    case types.DELETE_USEFULINFOR_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: true,
        errorMessage: action.payload.errorMessage
      }
    case types.SEARCH_USEFULINFOR_REQUEST:
      return {
        ...state,
        isFetching: true,
        activePageUsefulInfor: action.payload.offset,
        textSearch: action.payload.text
      }
    case types.SEARCH_USEFULINFOR_SUCCESS:
      return {
        ...state,
        isFetching: false,
        dataFetched: true,
        error: false,
        errorMessage: null,
        listUsefullInfor: action.payload.posts,
        totalPage: action.payload.total_pages
      }
    case types.SEARCH_USEFULINFOR_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: true,
        errorMessage: action.payload.errorMessage
      }
    case types.REFRESH_USEFULINFOR_REQUEST:
      return {
        ...state,
        isFetching: true
      }
    case types.REFRESH_USEFULINFOR_SUCCESS:
      return {
        ...state,
        isFetching: false,
        error: false,
        errorMessage: null
      }
    case types.REFRESH_USEFULINFOR_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: true,
        errorMessage: action.payload.errorMessage
      }
    default:
      return state;
  }
};