import * as types from '../constant';
export function getTour(payload) {
  return ({
    type: types.GET_TOUR_REQUEST,
    payload
  });
}
export function addTour(payload) {
  return ({
    type: types.ADD_TOUR_REQUEST,
    payload
  })
}

export function editTour(payload) {
  return ({
    type: types.EDIT_TOUR_REQUEST,
    payload
  })
}

export function deleteTour(payload) {
  return ({
    type: types.DELETE_TOUR_REQUEST,
    payload
  })
}

export function copyTour(payload) {
  return ({
    type: types.COPY_TOUR_REQUEST,
    payload
  })
}

export function getTourTopic(payload) {
  return ({
    type: types.GET_TOPIC_REQUEST,
    payload
  })
}
export function addTourTopic(payload) {
  return ({
    type: types.ADD_TOPIC_REQUEST,
    payload
  })
}
export function updateTourTopic(payload) {
  return ({
    type: types.UPDATE_TOPIC_REQUEST,
    payload
  })
}
export function deleteTourTopic(payload) {
  return ({
    type: types.DELETE_TOPIC_REQUEST,
    payload
  })
}

export function search(payload) {
  return ({
    type: types.SEARCH_TOUR_REQUEST,
    payload
  })
}