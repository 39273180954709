import { domain } from '../../constant'

export default (payload) => {
    return new Promise((resolve, reject) => {
        const url = domain +'/upload'
        fetch(url, {
          method: "POST",
          body: payload
        })
          .then((response) => response.json())
          .then((res) => {
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      });
}