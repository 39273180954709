import { domain } from '../../constant'

export default function getContactRequest(activePage) {
  return new Promise((resolve, reject) => {
    let url = domain + `/get-contacts/5/${activePage}`;
    fetch(url, {
      method: 'GET'
    })
      .then((response) => response.json())
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
