import * as types from '../constant';
const DEFAULT_STATE = {
  listTour: [],
  dataFetched: false,
  isFetchingTopic: false,
  isFetchingTour:false,
  error: false,
  errorMessage: null,
  actiPageTour: 1,
  totalPage: 1,
  topics:[],
  textSearch: ''
};
export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case types.DELETE_TOUR_REQUEST:
    case types.COPY_TOUR_REQUEST:
    return {
      ...state,
      isFetchingTour: true,
      actiPageTour: action.payload.activePage
    };
    case types.GET_TOUR_REQUEST:
      return {
        ...state,
        isFetchingTour: true,
        actiPageTour: action.payload
      };
    case types.GET_TOUR_SUCCESS:
      return {
        ...state,
        isFetchingTour: false,
        dataFetched: true,
        error: false,
        errorMessage: null,
        listTour: action.payload.tours,
        totalPage: action.payload.totalPage
      };
    case types.GET_TOUR_FAILURE:
      return {
        ...state,
        isFetchingTour: false,
        error: true,
        errorMessage: action.payload.errorMessage
      };

    case types.ADD_TOUR_REQUEST:
      return {
        ...state,
        isFetchingTour: true
      };
    case types.ADD_TOUR_SUCCESS:
      return {
        ...state,
        isFetchingTour: false,
        dataFetched: true,
        error: false,
        errorMessage: null,
        
      };
    case types.ADD_TOUR_FAILURE:
      return {
        ...state,
        isFetchingTour: false,
        error: true,
        errorMessage: action.payload.errorMessage
      };
    case types.EDIT_TOUR_REQUEST:
      return {
        ...state,
        isFetchingTour: true
      };
    case types.EDIT_TOUR_SUCCESS:
      return {
        ...state,
        isFetchingTour: false,
        dataFetched: true,
        error: false,
        errorMessage: null,
       
      };
    case types.EDIT_TOUR_FAILURE:
      return {
        ...state,
        isFetchingTour: false,
        error: true,
        errorMessage: action.payload.errorMessage
      };
    case types.GET_TOPIC_REQUEST:
      return {
        ...state,
        isFetchingTopic: true
      };
    case types.GET_TOPIC_SUCCESS:
      return {
        ...state,
        isFetchingTopic: false,
        dataFetched: true,
        error: false,
        topics: action.payload.types,
        errorMessage: null,
       
      };
    case types.GET_TOPIC_FAILURE:
      return {
        ...state,
        isFetchingTopic: false,
        error: true,
        errorMessage: action.payload.errorMessage
      };
    case types.ADD_TOPIC_REQUEST:
      return {
        ...state,
        isFetchingTopic: true
      };
    case types.ADD_TOPIC_SUCCESS:
      return {
        ...state,
        isFetchingTopic: false,
        dataFetched: true,
        error: false,
        errorMessage: null,
       
      };
    case types.ADD_TOPIC_FAILURE:
      return {
        ...state,
        isFetchingTopic: false,
        error: true,
        errorMessage: action.payload.errorMessage
      };
    case types.UPDATE_TOPIC_REQUEST:
      return {
        ...state,
        isFetchingTopic: true
      };
    case types.UPDATE_TOPIC_SUCCESS:
      return {
        ...state,
        isFetchingTopic: false,
        dataFetched: true,
        error: false,
        errorMessage: null,
       
      };
    case types.UPDATE_TOPIC_FAILURE:
      return {
        ...state,
        isFetchingTopic: false,
        error: true,
        errorMessage: action.payload.errorMessage
      };
    case types.DELETE_TOPIC_REQUEST:
      return {
        ...state,
        isFetchingTopic: true
      };
    case types.DELETE_TOPIC_SUCCESS:
      return {
        ...state,
        isFetchingTopic: false,
        dataFetched: true,
        error: false,
        errorMessage: null,
       
      };
    case types.DELETE_TOPIC_FAILURE:
      return {
        ...state,
        isFetchingTopic: false,
        error: true,
        errorMessage: action.payload.errorMessage
      };
    case types.SEARCH_TOUR_REQUEST:
      return {
        ...state,
        isFetchingTour: true,
        actiPageTour: action.payload.offset,
        textSearch: action.payload.text
      };
    case types.SEARCH_TOUR_SUCCESS:
      return {
        ...state,
        isFetchingTour: false,
        dataFetched: true,
        error: false,
        errorMessage: null,
        listTour: action.payload.tours,
        totalPage: action.payload.totalPage
      };
    case types.SEARCH_TOUR_FAILURE:
      return {
        ...state,
        isFetchingTour: false,
        error: true,
        errorMessage: action.payload.errorMessage
      };
    default:
      return state;
  }
};