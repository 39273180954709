import * as types from '../constant';
const DEFAULT_STATE = {
  listText: null,
  listImage: [],
  listImageEvent: null,
  listImageTour: null,
  dataFetched: false,
  isFetching: false,
  error: false,
  errorMessage: null,
};

export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case types.ADD_IMAGE_REQUEST:
    case types.GET_TEXT_REQUEST:
      return {
        ...state,
        isFetching: true
      };
    case types.GET_TEXT_SUCCESS:
      return {
        ...state,
        isFetching: false,
        dataFetched: true,
        error: false,
        errorMessage: null,
        listText: action.payload
      };
    case types.GET_TEXT_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: true,
        errorMessage: action.payload.errorMessage
      };
    //Get slides and backgrounds
    case types.GET_IMAGE_REQUEST:
      return {
        ...state,
        isFetching: true
      };
    case types.GET_IMAGE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        dataFetched: true,
        error: false,
        errorMessage: null,
        listImage: action.payload.images
      };
    case types.GET_IMAGE_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: true,
        errorMessage: action.payload.errorMessage
      };
    // Get image events
    case types.GET_IMAGE_EVENT_REQUEST:
      return {
        ...state,
        isFetching: true
      };
    case types.GET_IMAGE_EVENT_SUCCESS:
      return {
        ...state,
        isFetching: false,
        dataFetched: true,
        error: false,
        errorMessage: null,
        listImageEvent: action.payload
      };
    case types.GET_IMAGE_EVENT_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: true,
        errorMessage: action.payload.errorMessage
      };
    //get image tour
    case types.GET_IMAGE_TOUR_REQUEST:
      return {
        ...state,
        isFetching: true
      };
    case types.GET_IMAGE_TOUR_SUCCESS:
      return {
        ...state,
        isFetching: false,
        dataFetched: true,
        error: false,
        errorMessage: null,
        listImageTour: action.payload

      };
    case types.GET_IMAGE_TOUR_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: true,
        errorMessage: action.payload.errorMessage
      };
    case types.EDIT_IMAGE_EVENT_REQUEST:
      return {
        ...state,
        isFetching: true
      };
    case types.EDIT_IMAGE_EVENT_SUCCESS:
      return {
        ...state,
        isFetching: false,
        dataFetched: true,
        error: false,
        errorMessage: null,
      };
    case types.EDIT_IMAGE_EVENT_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: true,
        errorMessage: action.payload.errorMessage
      };
    case types.EDIT_IMAGE_TOUR_REQUEST:
      return {
        ...state,
        isFetching: true
      };
    case types.EDIT_IMAGE_TOUR_SUCCESS:
      return {
        ...state,
        isFetching: false,
        dataFetched: true,
        error: false,
        errorMessage: null,
      };
    case types.EDIT_IMAGE_TOUR_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: true,
        errorMessage: action.payload.errorMessage
      };
    default:
      return state;
  }
};