import { domain } from '../../constant'

export default function deleteEventRequest(id) {
    return new Promise((resolve, reject) => {
        const url = domain +  `/delete-event/${id}`;
        fetch(url, {
            method: 'DELETE'
        })
            .then((response) => response.json())
            .then((res) => {
                resolve(res);
            })
            .catch((error) => {
                reject(error);
            });
    });
}
