import * as types from "../../../../constant";

export default function updateMemberIntro(data) {
    return new Promise((resolve, reject) => {
      const url = types.domain + `/update-member`;
      fetch(url, {
        method: 'PUT',
        body: data
      })
        .then((response) => response.json())
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
    