export function isContainImages(htmlText) {
    if (!htmlText) return false
    // Return true if text contains at least one source
    return htmlText.match(/src="[^"]+/)
}

export function getAllSourceImages(htmlText) {
    let srcImages = []
    // Find all source images in text
    const matcher = htmlText.match(/src="[^"]+/g)

    // Remove prefix (src=") and return list source
    if (htmlText && matcher) {
        srcImages = matcher.map(m => m.toString().substring(5))
    }

    // console.log("QUAN", srcImages)

    return srcImages
}