import * as types from '../constant';
const DEFAULT_STATE = {
  listUsaInfor: [],
  dataFetched: false,
  isFetching: false,
  error: false,
  errorMessage: null,
  activePageUsaInfor: 1,
  textSearch: '',
  totalPage: 1
}

export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case types.GET_USAINFOR_REQUEST:
      return {
        ...state,
        isFetching: true,
        //Save activePage
        activePageUsaInfor: action.payload,
        //SearchText
        textSearch: ''
      }
    case types.GET_USAINFOR_SUCCESS:
      return {
        ...state,
        isFetching: false,
        dataFetched: true,
        error: false,
        errorMessage: null,
        listUsaInfor: action.payload.posts,
        totalPage: action.payload.total_pages
      }
    case types.GET_USAINFOR_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: true,
        errorMessage: action.payload.errorMessage
      }
    case types.ADD_USAINFOR_REQUEST:
      return {
        ...state,
        isFetching: true
      }
    case types.ADD_USAINFOR_SUCCESS:
      return {
        ...state,
        isFetching: false
      }
    case types.ADD_USAINFOR_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: true,
        errorMessage: action.payload.errorMessage
      }
    case types.EDIT_USAINFOR_REQUEST:
      return {
        ...state,
        isFetching: true
      }
    case types.EDIT_USAINFOR_SUCCESS:
      return {
        ...state,
        isFetching: false
      }
    case types.EDIT_USAINFOR_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: true,
        errorMessage: action.payload.errorMessage
      }
    case types.DELETE_USAINFOR_REQUEST:
      return {
        ...state,
        isFetching: true

      }
    case types.DELETE_USAINFOR_SUCCESS:
      return {
        ...state,
        isFetching: false,
      }
    case types.DELETE_USAINFOR_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: true,
        errorMessage: action.payload.errorMessage
      }
    case types.SEARCH_USAINFOR_REQUEST:
      return {
        ...state,
        isFetching: true,
        activePageUsaInfor: action.payload.offset,
        textSearch: action.payload.text
      }
    case types.SEARCH_USAINFOR_SUCCESS:
      return {
        ...state,
        isFetching: false,
        dataFetched: true,
        error: false,
        errorMessage: null,
        listUsaInfor: action.payload.posts,
        totalPage: action.payload.total_pages
      }
    case types.SEARCH_USAINFOR_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: true,
        errorMessage: action.payload.errorMessage
      }
    case types.REFRESH_USAINFOR_REQUEST:
      return {
        ...state,
        isFetching: true
      }
    case types.REFRESH_USAINFOR_SUCCESS:
      return {
        ...state,
        isFetching: false,
        error: false,
        errorMessage: null
      }
    case types.REFRESH_USAINFOR_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: true,
        errorMessage: action.payload.errorMessage
      }

    default:
      return state;
  }
};