import React, { Component } from 'react'
import '../../styles/abouts/FAQ.css'
class addFAQ extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: '',
            question: '',
            answer: ''
        }
    }
    validated() {
        if (this.state.question === '' || this.state.answer === '') {
            return false
        } else {
            alert('Thêm Câu Hỏi thành công!')
            return true
        }
    }
    handleAddFAQ() {
        if (this.validated()) {
            const textFAQsObj = {
                question: this.state.question,
                answer: this.state.answer
            }
            this.props.addFAQ(textFAQsObj)
            this.resetAddFAQs()
        } else {
            alert('Thiếu Câu Hỏi Hoặc Câu Trả Lời!')
        }
    }
    resetAddFAQs() {
        this.setState({
            id: '',
            question: '',
            answer: ''
        })
    }
    render() {
        return (
            <div>
                <div className="addFAQ">
                    <button type="button"
                        className="btn btn-success"
                        data-toggle="modal"
                        data-target="#addFAQS"
                        onClick={() => this.resetAddFAQs()}>Thêm</button>
                    <div className="modal fade" id="addFAQS" role="dialog">
                        <div className="modal-dialog">
                            <div className="modal-content modalAddFAQs">
                            <div className="modal-header">
                                        <h4 className="center-modal-title">Thêm nội dung câu hỏi</h4>
                                    </div>
                                <div className="modal-body">
                                    <div>
                                        <div className="divAddFAQs">
                                            <div>
                                                <label className="titleAddFAQ">Câu hỏi:</label>
                                            </div>
                                            <div className="textAddFAQ">
                                                <input value={this.state.question}
                                                    className="inputAddFAQ"
                                                    onChange={(e) => this.setState({ question: e.target.value })}
                                                    placeholder="Nhập câu hỏi" />
                                            </div>
                                        </div>
                                        <div className="divAddFAQs">
                                            <div>
                                                <label className="titleAddFAQ">Câu trả lời:</label>
                                            </div>
                                            <div className="textAddFAQ">
                                                <textarea value={this.state.answer}
                                                    className="resizeTextareaFAQ"
                                                    onChange={(e) => this.setState({ answer: e.target.value })}
                                                    placeholder="Nhập câu trả lời" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="btnFAQAction">
                                        <button type="button"
                                            className="btn btn-danger buttonCloseAddFAQs"
                                            style={{marginRight:5}}
                                            data-dismiss="modal"
                                            onClick={() => this.resetAddFAQs()}>Hủy</button>
                                        <button type="button"
                                            className="btn btn-success"
                                            data-dismiss="modal"
                                            onClick={() => this.handleAddFAQ()}>Xác nhận</button>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        )
    }
}
export default addFAQ