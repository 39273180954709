import React from 'react';
import UsaInforContainer from '../containers/UsaInforContainer'
class USInfomationPage extends React.Component {
  render() {
    return (
      <div className="USInfomationPage">
        <div className="card">
          <div className="card-header"> <h1>Thông tin nước Mỹ</h1></div>
          <div className="card-body">
            <UsaInforContainer />
          </div>
        </div>
      </div>
    );
  }
}

export default USInfomationPage;