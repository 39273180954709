import {put, takeEvery} from 'redux-saga/effects'
import EditImageTours from '../../fetchAPI/HomeAdmin/EditImageTours'
import * as types from '../../constant';
function* upLoadImageNew(action){
    try{
        const response = yield EditImageTours(action.payload)
        yield put({
            type: types.EDIT_IMAGE_TOUR_SUCCESS,
            payload: response
        })
        yield put({
            type:types.GET_IMAGE_TOUR_REQUEST
        })

    }catch (error){
        yield put({
            type:types.EDIT_IMAGE_TOUR_FAILURE,
            payload:{
                errorMessage : error.message
            }
        })
    }
}
export const EditImageTour = [
    takeEvery(types.EDIT_IMAGE_TOUR_REQUEST,upLoadImageNew)
]