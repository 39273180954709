import React, { Component } from 'react';
import TourSchedule from '../components/TourScheduleComponent/TourSchedule';
import { connect } from 'react-redux';
import * as actions from '../actions/TourScheduleAction';
import PaginationCommon from '../components/commons/PaginationCommon';
import TourTopic from '../components/TourScheduleComponent/TourTopic';
import { Tabs, Tab } from 'react-bootstrap';
class TourScheduleContainer extends Component {
    componentDidMount() {
        this.props.onPaginate(1);
        this.props.getTourTopic();
    }
    render() {
        let flagSearch = false;
        if (this.props.textSearch !== '') {
            flagSearch = true
        }
        return (
            <div>
                <Tabs defaultActiveKey="tour-topic" transition={false} id="noanim-tab-example">
                    <Tab eventKey="tour-topic" title="Chủ đề tour">
                        <TourTopic {...this.props} />
                    </Tab>
                    <Tab eventKey="detail-tour-schedule" title="Danh sách lịch trình tour">
                        <TourSchedule {...this.props} />
                        <PaginationCommon activePage={this.props.activePage} totalPage={this.props.totalPage} onPaginate={(data) => {
                            if (flagSearch === false) {
                                this.props.onPaginate(data)
                            } else {
                                this.props.search(this.props.textSearch, data)
                            }
                        }} />
                    </Tab>
                </Tabs>
            </div>
        );
    }

}
const mapStateToProps = (state) => {
    return {
        isLoadingTopic: state.tours.isFetchingTopic,
        isLoadingTour: state.tours.isFetchingTour,
        topics: state.tours.topics,
        tourSchedule: state.tours.listTour,
        activePage: state.tours.actiPageTour,
        textSearch: state.tours.textSearch,
        totalPage: state.tours.totalPage
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onPaginate: (data) => {
            dispatch(actions.getTour(data));
        },
        addTour: (data) => {
            dispatch(actions.addTour(data));
        },
        edit: (data) => {
            dispatch(actions.editTour(data));
        },
        deleteTour: (id) => {
            dispatch(actions.deleteTour(id));
        },
        copyTour: (data) => {
            dispatch(actions.copyTour(data));
        },
        getTourTopic: () => {
            dispatch(actions.getTourTopic());
        },
        addTopic: (data) => {
            dispatch(actions.addTourTopic(data))
        },
        editTopic: (data) => {
            dispatch(actions.updateTourTopic(data))
        },
        deleteTopic: (data) => {
            dispatch(actions.deleteTourTopic(data))
        },
        search: (inputValue, offset) => {
            dispatch(actions.search({
                offset: offset,
                text: inputValue
            }))
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(TourScheduleContainer);