import React from 'react';
import USBeautiContainer from "../containers/USBeautyContainer"
class USBeautyPage extends React.Component {
  render() {
    return (
      <div className="USBeautyPage">
        <div className="card">
          <div className="card-header"><h1>Vẻ đẹp Mỹ</h1></div>
          <div className="card-body">
            <USBeautiContainer />
          </div>
        </div>
      </div>
    );
  }
}

export default USBeautyPage;