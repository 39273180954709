import React, { Component } from 'react';
import { domain } from '../constant'
import LoadingModal from './commons/LoadingModal'

export default class TinyEditor extends Component {

  initTinyMCE(){
    // Get content of parent
    const { content, addSourceImageToParent } = this.props;

    // Initialize the TinyMCE editor
    window.tinymce.init({
      selector: '#tiny',
      // Min height of editor
      min_height: 400,
      // Set width equal to parent
      width: "100%",
      plugins: ["image", "table", "paste", "link", "help", "media"],
      menubar: "file edit insert view format table tools help",
      toolbar: 'undo redo | fontselect fontsizeselect | styleselect | bold italic | link image media | alignleft aligncenter alignright',
      // List image on inserting image popup
      // image_list: [
      //   {title: 'My image 1', value: 'https://www.tinymce.com/my1.gif'},
      //   {title: 'My image 2', value: 'http://www.moxiecode.com/my2.gif'}
      // ],
      image_caption: true,

      // Image uploading zone
      images_upload_url: domain + '/upload',
      images_upload_handler: function (blobInfo, success, failure) {
        var xhr, formData;

        xhr = new XMLHttpRequest();
        xhr.withCredentials = false;
        xhr.open('POST', domain + '/upload');

        xhr.onload = function() {
          var json;

          if (xhr.status !== 200) {
            failure('HTTP Error: ' + xhr.status);
            return;
          }

          json = JSON.parse(xhr.responseText);

          // Error uploading
          if (!json || typeof json.url != 'string') {
            failure('Invalid JSON: ' + xhr.responseText);
            return;
          }
          addSourceImageToParent(json.url);
          // Success uploading
          success(json.url);
        };

        formData = new FormData();
        formData.append('upload', blobInfo.blob(), blobInfo.filename());

        xhr.send(formData);
      },

      branding: false,
      mobile: {
        theme: 'mobile'
      },
      // Set initial value for editor
      setup: function(editor){
        // Listen for init event (Done setup event)
        editor.on("init",
              function() {
                window.tinymce.get('tiny').setContent(content);
                // Re-render
                window.tinymce.execCommand('mceRepaint');
              }
        );
      }
    });
  }

  componentDidMount() {
      this.initTinyMCE()
  }

  componentDidUpdate() {
    if(!window.tinymce.get('tiny')) {
      window.tinymce.execCommand('mceRepaint');
    }
  }

  componentWillUnmount() {
    if(window.tinymce.get('tiny'))
      window.tinymce.get('tiny').remove();
  }

  render() {
    return (
      <div id="tiny" style={{width: "100%"}}>
        <LoadingModal />
      </div>
      /* <Editor
        id="tiny"
        apiKey='30z9md8tzraxtli5rgu4h3hps3noi20vjpr2br93fab4469z'
        cloudChannel='dev' 
        initialValue={this.props.content}
        init={{
          plugins: 'link image code',
          toolbar: 'undo redo | bold italic | alignleft aligncenter alignright | code'
        }}
        onChange={this.handleEditorChange}
      /> */
    );
  }

}