import React from 'react';
import ContactContainer from "../containers/ContactContainer";

class ContactPage extends React.Component {
  render() {
    return (
      <div className="ContactPage">
        <div className="card">
          <div className="card-header"><h1>Danh Sách Người Dùng Đã Liên Hệ</h1></div>
          <div className="card-body">
            <ContactContainer />
          </div>
        </div>
      </div>
    );
  }
}

export default ContactPage;