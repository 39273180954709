import {put, takeEvery} from 'redux-saga/effects'
import EditText from '../../fetchAPI/HomeAdmin/EditTextNewApi'
import * as types from '../../constant';
function* editList(action){
    try{
        const response = yield EditText (action.payload)
        yield put({
            type: types.EDIT_TEXT_NEW_SUCCESS,
            payload: response
        })
        yield put({
            type:types.GET_TEXT_REQUEST
        })

    }catch (error){
        yield put({
            type:types.EDIT_TEXT_NEW_FAILURE,
            payload:{
                errorMessage : error.message
            }
        })
    }
}
export const EditTextNew = [
    takeEvery(types.EDIT_TEXT_NEW_REQUEST,editList)
]