import { put, takeEvery } from 'redux-saga/effects';
import getImage from '../../fetchAPI/HomeAdmin/GetImage';
import * as types from '../../constant';
function* getListImage() {
    try {
      const res = yield getImage();
      yield put({
        type: types.GET_IMAGE_SUCCESS,
        payload: res
      });
    } catch (error) {
      yield put({
        type: types.GET_IMAGE_FAILURE,
        payload: {
          errorMessage: error.message
        }
      });
    }
  }
  
  export const GetImage = [
    takeEvery(types.GET_IMAGE_REQUEST, getListImage)
  ];
  
  