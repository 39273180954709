import * as types from '../../constant'
export default function callApi(usefulInfor){
    return new Promise((resolve,reject)=>{
        const url = types.domain+"/add-new-us-useful"
        fetch(url,{
            method: "POST",
            body: JSON.stringify(usefulInfor),
            headers:{"Content-type":"application/json"},
        })
        .then((response) => response.json())
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
    })
})
}