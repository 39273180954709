import { put, takeEvery } from 'redux-saga/effects'
import AddImages from '../../fetchAPI/HomeAdmin/AddImage'
import * as types from '../../constant';
function* addSlide(action) {
    try {
        const response = yield AddImages(action.payload)
        yield put({
            type: types.ADD_IMAGE_SUCCESS,
            payload: response
        })
        yield put({
            type: types.GET_IMAGE_REQUEST
        })

    } catch (error) {
        yield put({
            type: types.ADD_IMAGE_FAILURE,
            payload: {
                errorMessage: error.message
            }
        })
    }
}
export const AddImage = [
    takeEvery(types.ADD_IMAGE_REQUEST, addSlide)
]