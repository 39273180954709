import * as types from "../constant"

export function getEvent(payload) {
    return {
        type: types.GET_EVENTS_REQUEST,
        payload
    };
}

export function addAnEvent(payload) {
    return {
        type: types.ADD_EVENT_REQUEST,
        payload
    };
}

export function updateAnEvent(event) {
    return {
        type: types.UPDATE_EVENT_REQUEST,
        payload: event
    };
}

export function searchEvent(payload) {
    return {
        type: types.SEARCH_EVENT_REQUEST,
        payload
    };
}

export function deleteAnEvent(payload) {
    return {
        type: types.DELETE_EVENT_REQUEST,
        payload
    };
}