import {domain} from '../../constant'
export default function deleteSlide(id) {
    return new Promise((resolve, reject) => {
        const url = domain +
        '/del-bgr-img/'
         +id;
        fetch(url, {
            method: "DELETE",

        })
            .then((response) => response.json())
            .then((res) => {
                resolve(res);
            })
            .catch((error) => {
                reject(error);
            });
    });
}
