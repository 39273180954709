import React from 'react';
import LoadingModal from '../commons/LoadingModal'

class TextTour extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      type: '',
      title: '',
      content: '',
      id: ''
    };
  }
  handleUpdate(data) {
    this.setState({
      type: data.key,
      title: data.text.title,
      content: data.text.content,
      id: data._id
    })
  }
  updateTextNew() {
    this.props.editTextNew(this.state)
  }
  render() {
    let listData
    if (this.props.text) {
      listData = Object.values(this.props.text.bodyText).map((item, key) => {
        return (
          <tr key={key} className="colum-4">
            <td style={{width: "25%"}} className="text-uppercase">{item.key.toUpperCase()}</td>
            <td style={{width: "25%"}}>{item.text.title}</td>
            <td style={{width: "35%"}}>{item.text.content}</td>
            <td style={{width: "10%"}}>
              <button 
                type="button" 
                className="btn btn-primary" 
                data-toggle="modal" 
                data-target="#Text" 
                onClick={() => this.handleUpdate(item, key)}>
                  Sửa
                </button>
            </td>
          </tr>
        )
      })
    }
    return (
      <div>
        <div className="modal fade" id="Text" role="dialog">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title text-uppercase text-center" style={{width:"100%"}}>CẬP NHẬT {this.state.type}</h4>

              </div>
              <div className="modal-body">
                <h6> Tiêu Đề :</h6>
                <textarea className="textarea" onChange={(e) => { this.setState({ title: e.target.value }) }} value={this.state.title} />
                <h6> Nội dung :</h6>
                <textarea className="textarea larger-textarea" onChange={(e) => { this.setState({ content: e.target.value }) }} value={this.state.content} />
              </div>
              <div className="modal-footer">
              <button type="button" className="btn btn-danger" data-dismiss="modal">Hủy</button>
                <button type="button" className="btn btn-success" data-dismiss="modal" onClick={() => this.updateTextNew()}>Xác nhận</button>
              </div>
            </div>
          </div>
        </div>
        {/* table */}
        {(!this.props.isLoadingText && listData) ?
          <table className="table table-hover">
            <thead>
              <tr className="table-dark text-dark">
                <th>Vị Trí</th>
                <th>Tiêu đề</th>
                <th>Nội dung</th>
                <th>Thao tác</th>
              </tr>
            </thead>
            <tbody>
              {listData}
            </tbody>
          </table>
          : <LoadingModal />}
      </div>
    );
  }
}
export default TextTour;
