import React from 'react'
import DeleteUsefulInfor from './DeleteUsefulInfor'
import LoadingModal from '../commons/LoadingModal'
import history from '../../history'
import { Button } from 'react-bootstrap'
import { POST_TYPE_FLAG } from '../../constant'

class UsefulInforComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      title: '',
      author: '',
      reference: '',
      images: [
        {
          file: null,
          imgUrl: '',
          alt: '',
          content: '',
          isDeleted: false
        }
      ]
    }
  }

  navigateToAddForm = () => {
    // Reset id in storage
    window.sessionStorage.setItem("post.id", "");
    window.sessionStorage.setItem("post.type", POST_TYPE_FLAG.USEFUL_INFO);
    // Navigate to post form page
    history.push('/post-form')
  }

  navigateToEditForm(post) {
    // Set post's value to storage
    window.sessionStorage.setItem("post.id", post._id);
    window.sessionStorage.setItem("post.type", POST_TYPE_FLAG.USEFUL_INFO);
    window.sessionStorage.setItem("post.title", post.title);
    window.sessionStorage.setItem("post.topic", post.topic);
    window.sessionStorage.setItem("post.author", post.author);
    window.sessionStorage.setItem("post.description", post.description);

    // Navigate to postform page
    history.push('/post-form');
  }

  render() {
    let userFulInfor = this.props.dataContainer
    let listUsefulInfor
    if (userFulInfor) {
      listUsefulInfor = userFulInfor.map((item, key) => {
        let tempDT = new Date(item.last_update_time)
        let deleteData = "delete" + item._id
        let refreshData = "refresh" + item._id
        return (
          <tr className="colum2" key={key}>
            <td>{key + 1}</td>
            <td>{item.title}</td>
            <td>{item.author}</td>
            <td>{tempDT.getDate() + "/" + (tempDT.getMonth() + 1) + "/" + tempDT.getFullYear()}</td>
            <td colSpan={2} style={{ width: "13%" }}>
              <button
                type="button"
                className="btn btn-primary"
                style={{ marginRight: 5 }}
                onClick={() => this.navigateToEditForm(item)}>Sửa</button>

              <DeleteUsefulInfor item={item} deleteData={deleteData} {...this.props} />
              <button type="button" className="btn btn-info" data-toggle="modal"
                data-target={"#" + refreshData} >Làm mới</button>
              <div className="modal fade" id={refreshData} role="dialog">
                <div className="modal-dialog modal-sm">
                  <div className="modal-content">
                    <div className="modal-header">

                      <h4 className="modal-title" style={{ marginLeft: "30px" }} >Bạn có chắc chắn muốn làm mới không ?</h4>
                      <button type="button" className="close" data-dismiss="modal">×</button>
                    </div>
                    <div className="modal-footer">
                      <button type="button" className="btn btn-danger"
                        data-dismiss="modal">Hủy</button>
                      <button type="button" className="btn btn-success" data-dismiss="modal"
                        onClick={() => this.props.refresh(
                          {
                            dataAPI: item._id,
                            textSearch: this.props.textSearch,
                            activePage: this.props.activePage
                          }
                        )}  >Xác nhận</button>

                    </div>
                  </div>
                </div>
              </div>
            </td>
          </tr>
        )
      })
    }

    return (
      <div>
        {(listUsefulInfor && !this.props.isLoadingData) ?
          <div className="table-responsive">
            <Button
              variant="success"
              block
              onClick={() => this.navigateToAddForm()}>Thêm</Button>
            <table className="table table-striped">
              <thead>
                <tr className="table-dark text-dark">
                  <th>STT</th>
                  <th>Tiêu đề</th>
                  <th>Người đăng</th>
                  <th>Thời gian</th>
                  <th colSpan={2} style={{ width: "13%" }}>Thao tác</th>
                </tr>
              </thead>
              <tbody>
                {listUsefulInfor}
              </tbody>
            </table>
          </div>
          : <LoadingModal />
        }
      </div>
    )
  }

}

export default UsefulInforComponent;