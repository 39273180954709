import React from 'react';
import PopularTourContainer from "../containers/PopularTourContainer"

class PopularTourPage extends React.Component {
  render() {
    return (
      <div className="PopularTourPage">
        <div className="card">
          <div className="card-header"><h1>Tour tiêu biểu</h1></div>
          <div className="card-body">
            <PopularTourContainer />
          </div>
        </div>
      </div>
    );
  }
}

export default PopularTourPage;