import React, { Component } from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import "../styles/PriceRequest.css"
import LoadingModal from '../commons/LoadingModal'
class PriceRequestComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: "",
            name: "",
            email: "",
            tel: "",
            differentContact: "",
            note: "",
            date_begin: "",
            num: "",
            location: "",
            localGuide: "",
            vietNamGuide: "",
            inputText: "",
        };
    }

    handleDelete(dataDelete) {
        let payload = {
          dataAPI: dataDelete,
          textSearch: this.state.inputText,
          activePage: this.props.activePage,
          length: this.props.listPriceRequest.length,
          totalPage: this.props.totalPages
        }
        this.props.deleteRequest(payload);
      }

    render() {

        let show;
        if (this.props.listPriceRequest) {
            show = this.props.listPriceRequest.map((item, key) => {
                let noteP = item.note.split("\n").map((item, idx) => (<p key={idx}>{item}</p>))
                return (
                    <tr className="" key={item.id}>
                        <td>{item.name}</td>
                        <td>{item.email}</td>
                        <td>{item.tel}</td>
                        <td>{item.date_begin}</td>
                        <td>{item.location}</td>
                        <td>{item.num}</td>

                        <td>
                            <button type="button"
                                className="btn btn-success"
                                data-toggle="modal"
                                data-target={"#choose" + key}
                                onClick={(e) => {
                                }}
                            >Chi tiết</button>
                            <div className="modal fade" id={"choose" + key} role="dialog">
                                <div className="modal-dialog">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h4 className="center-modal-title">Thông tin yêu cầu</h4>
                                        </div>
                                        <div className="modal-body">
                                            <form className="form-horizontal">
                                                <div className="form-group top-content-border">
                                                    <div className="form-group row reset-row-margin-both parent-label-border">
                                                        <label className="col-sm-4 " style={{ fontWeight: "bold" }}>Lịch trình / Điểm đến</label>
                                                        <label className="col-sm-8 ">
                                                            {item.location}
                                                        </label>
                                                    </div>

                                                    <div className="form-group row reset-row-margin-both parent-label-border">
                                                        <label className="col-sm-4 " style={{ fontWeight: "bold" }}>Ngày dự kiến đến Mỹ</label>
                                                        <label className="col-sm-8 ">
                                                            {item.date_begin}
                                                        </label>
                                                    </div>

                                                    <div className="form-group row reset-row-margin-both parent-label-border">
                                                        <label className="col-sm-4 " style={{ fontWeight: "bold" }}>Lượng người</label>
                                                        <label className="col-sm-8 ">
                                                            {item.num}
                                                        </label>
                                                    </div>

                                                    <div className="form-group row reset-row-margin-both parent-label-border">
                                                        <label className="col-sm-4 " style={{ fontWeight: "bold" }}>Hướng dẫn viên</label>
                                                        <label className="col-sm-8 ">
                                                            {item.localGuide}
                                                        </label>
                                                    </div>

                                                    <div className="form-group row reset-row-margin-both parent-label-border">
                                                        <label className="col-sm-4 " style={{ fontWeight: "bold" }}>HDV có thể nói tiếng Việt</label>
                                                        <label className="col-sm-8 ">
                                                            {item.vietNamGuide}
                                                        </label>
                                                    </div>

                                                    <div className="form-group row reset-row-margin-both parent-label-border">
                                                        <label className="col-sm-4 " style={{ fontWeight: "bold" }}>Họ tên khách hàng</label>
                                                        <label className="col-sm-8 ">
                                                            {item.name}
                                                        </label>
                                                    </div>

                                                    <div className="form-group row reset-row-margin-both parent-label-border">
                                                        <label className="col-sm-4 " style={{ fontWeight: "bold" }}>Thư điện tử</label>
                                                        <label className="col-sm-8 ">
                                                            {item.email}
                                                        </label>
                                                    </div>

                                                    <div className="form-group row reset-row-margin-both parent-label-border">
                                                        <label className="col-sm-4 " style={{ fontWeight: "bold" }}>Số điện thoại</label>
                                                        <label className="col-sm-8 ">
                                                            {item.tel}
                                                        </label>
                                                    </div>

                                                    <div className="form-group row reset-row-margin-both parent-label-border">
                                                        <label className="col-sm-4 " style={{ fontWeight: "bold" }}>Công ty / website</label>
                                                        <label className="col-sm-8 ">
                                                            {item.differentContact}
                                                        </label>
                                                    </div>

                                                    <div className="form-group row reset-row-margin-both parent-label-border">
                                                        <label className="col-sm-4 " style={{ fontWeight: "bold" }}>Yêu cầu riêng</label>
                                                        <label className="col-sm-8 ">
                                                            {noteP}
                                                        </label>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <button type="button"
                                style={{marginLeft:2}}
                                className="btn btn-danger"
                                data-toggle="modal"
                                data-target={"#delete" + key}>
                                Xóa
							</button>
                            <div className="modal fade" id={"delete" + key} role="dialog">
                                <div className="modal-dialog">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h4 className="modal-title">Bạn có chắc chắn muốn xóa {item.name} không ?</h4>
                                        </div>
                                        <div className="modal-footer">
                                        <button type="button"
                                                className="btn btn-danger"
                                                data-dismiss="modal"
                                            >Hủy</button>
                                            <button type="button"
                                                className="btn btn-success"
                                                data-dismiss="modal"
                                                onClick={() => {
                                                    this.handleDelete(item.id);
                                                    this.props.setSearchingStatus(false);
                                                    this.setState({ inputText: "" });
                                                }}
                                            >Xác nhận</button>

                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </td>
                    </tr>
                )
            })
        }
        return (
            <div>
                <div className="row clearfix reset-row-margin-both">
                    <input
                        className="col-10 col-sm-11"
                        type="text" placeholder="Tìm kiếm..." name="search"
                        value={this.state.inputText}
                        onChange={(e) => {
                            if (!e.target.value) {
                                this.props.setSearchingStatus(false);
                                this.props.onPaginate(1, false);
                            }
                            this.setState({ inputText: e.target.value })
                        }}
                        onKeyPress={(e) => {
                            if (e.key === "Enter" && this.state.inputText) {
                                this.props.searchRequest(this.state.inputText, 1);
                                this.props.setSearchingStatus(true, this.state.inputText);
                            }
                        }}
                    />
                    <button className="btn-search col-2 col-sm-1"
                        onClick={() => {
                            if (this.state.inputText) {
                                this.props.setSearchingStatus(true, this.state.inputText);
                                this.props.searchRequest(this.state.inputText, 1);
                            }

                        }}>
                        <FontAwesomeIcon className="icon-search" icon={faSearch} />
                    </button>
                </div>
                {(!this.props.isLoading && show) ?
                <table className="table table-striped">
                    <thead>
                        <tr className="table-dark text-dark">
                            <th>Tên người dùng</th>
                            <th>Thư điện tử</th>
                            <th>Số điện thoại</th>
                            <th>Ngày dự kiến đến</th>
                            <th>Địa điểm</th>
                            <th>Lượng người</th>
                            <th>Thao tác</th>
                        </tr>
                    </thead>
                    
                    <tbody>
                        {show}
                    </tbody>
                        
                </table>
                : <LoadingModal />}
            </div>
        )
    }
}
export default PriceRequestComponent;