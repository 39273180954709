import React from 'react'
import HomeUSBeauty from '../components/USBeautyComponent/USBeautyComponent'
import * as actions from '../actions/USBeautyAction'
import PaginationCommon from '../components/commons/PaginationCommon'
import USBeautyTopic from '../components/USBeautyComponent/USBeautyTopic'
import { connect } from 'react-redux'
import { Tabs, Tab } from 'react-bootstrap'


class USBEAUTYContainer extends React.Component {
  componentDidMount() {
    this.props.getTopic()
    this.props.onPaginate(1);
  }

  render() {
    let flagSearch = false;
    if (this.props.textSearch !== '') {
      flagSearch = true
    }
    return (
      <div>
        <Tabs defaultActiveKey="tour-topic" transition={false} id="noanim-tab-example">
          <Tab eventKey="tour-topic" title="Danh sách chủ đề">
            <USBeautyTopic {...this.props} />
          </Tab>
          <Tab eventKey="detail-tour-schedule" title="Danh sách bài đăng">

            <HomeUSBeauty {...this.props} />
            {/* Separate table and pagination component */}
            <div style={{ marginTop: 10 }}></div>
            <PaginationCommon
              activePage={this.props.dataActivePage} 
              totalPage={this.props.total_pages} onPaginate={(data) => {
              if (flagSearch === false) {
                this.props.onPaginate(data)
              } else {
                this.props.search(this.props.textSearch, data)
              }
            }} />
          </Tab>
        </Tabs>
        {/* <div>
          <HomeUSBeauty {...this.props} />
          <PaginationCommon activePage={this.props.dataActivePage} totalPage={this.props.total_pages} onPaginate={(data) => {
            if (flagSearch === false) {
              this.props.onPaginate(data)
            } else {
              this.props.search(this.props.textSearch, data)
            }
          }} />
        </div> */}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    dataContainer: state.usBeauty.listUSBeauty,
    dataActivePage: state.usBeauty.activePageUS,
    total_pages: state.usBeauty.total_pages,
    textSearch: state.usBeauty.textSearch,
    isLoadingData: state.usBeauty.isFetching,
    dataTopic: state.type.listTopic,
    isLoadingTopic: state.type.isFetching

  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onPaginate: (data) => {
      dispatch(actions.getListUSBEAUTY(data))
    },
    addUSBEAUTY: (dataAdd) => {
      dispatch(actions.addUSBEAUTY(dataAdd))
    },
    editUSBEAUTY: (dataEdit) => {
      dispatch(actions.editUSBEAUTY(dataEdit))
    },
    deleteUSBEAUTY: (dataDelete) => {
      dispatch(actions.deleteUSBEAUTY(dataDelete))
    },
    search: (inputValue, offset) => {
      dispatch(actions.search({
        offset: offset,
        text: inputValue
      }))
    },
    getTopic: (data) => {
      dispatch(actions.getTopic(data))
    },
    addTopic: (dataAdd) => {
      dispatch(actions.addTopic(dataAdd))
    },
    editTopic: (dataEdit) => {
      dispatch(actions.editTopic(dataEdit))
    },
    deleteTopic: (dataDelete) => {
      dispatch(actions.deleteTopic(dataDelete))
    },
    refresh: (data) => {
      dispatch(actions.refresh(data))
    }
  }
};


export default connect(mapStateToProps, mapDispatchToProps)(USBEAUTYContainer);