import React, { Component } from "react"

export default class DeletionModal extends Component {
    render() {
        return (
            <div id={"delModal" + this.props.event.id} className="modal fade" role="dialog">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="center-modal-title">Xóa sự kiện</h4>
                        </div>
                        <div className="modal-body">
                            <p>Bạn có chắc chắn muốn xóa sự kiện
                                <span className="highlight-evt-name"> {this.props.event.name.toLowerCase()} </span>
                                chứ ?
                            </p>
                        </div>
                        <div className="modal-footer">
                        <button className="btn btn-danger" data-dismiss="modal">Hủy</button>
                            <button
                                className="btn btn-success" data-dismiss="modal"
                                onClick={() => this.props.handleDelete(this.props.event.id)}
                            >Xác nhận</button>

                        </div>
                    </div>
                </div>
            </div>
        )
    }
}