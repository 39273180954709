import * as types from '../constant';
export function getListImage(payload){
  return({
      type: types.GET_IMAGE_REQUEST,
      payload
  })
}
export function editSlide(payload){
  return ({
    type: types.EDIT_IMAGE_REQUEST,
    payload
  });
}
export function addSlide(payload){
  return ({
    type: types.ADD_IMAGE_REQUEST,
    payload
  });
}
export function deleteSlide(payload){
  return ({
    type: types.DELETE_IMAGE_REQUEST,
    payload
  });
}
export function getTextTour(payload){
  return ({
    type: types.GET_TEXT_REQUEST,
    payload
  });
}
export function edit(payload){
  return({
      type: types.EDIT_TEXT_REQUEST,
      payload
  })
}
export function editTextNew(payload){
  return({
      type: types.EDIT_TEXT_NEW_REQUEST,
      payload
  })
}
export function getImageEvent(payload){
  return({
      type: types.GET_IMAGE_EVENT_REQUEST,
      payload
  })
}
export function getImageTour(payload){
  return({
      type: types.GET_IMAGE_TOUR_REQUEST,
      payload
  })
}
export function upLoadImageNewEvent(payload){
  return({
      type: types.EDIT_IMAGE_EVENT_REQUEST,
      payload
  })
}

export function upLoadImageNewTour(payload){
  return({
      type: types.EDIT_IMAGE_TOUR_REQUEST,
      payload
  })
}