import { put, takeEvery } from 'redux-saga/effects';
import getRequest from '../fetchAPI/PriceRequestAPI/GetPriceRequest';
import DeletePriceRequest from "../fetchAPI/PriceRequestAPI/DeletePriceRequest"
import searchListRequest from "../fetchAPI/PriceRequestAPI/SearchPriceRequest"
import * as types from '../constant';

function* getListRequest(action) {
	try {
		const response = yield getRequest(action.payload);
		let orders = response.orders.map(order => {
			let dt = new Date(order.exp_arr_date);
			return {
				id: order._id,
				name: order.full_name,
				tel: order.tel,
				email: order.email,
				date_begin: `${dt.getDate()}/${dt.getMonth() + 1}/${dt.getFullYear()}`,
				location: order.schedule,
				num: order.numb_of_people,
				localGuide: order.has_tour_guide ? "Có" : "Không",
				vietNamGuide: order.can_speak_vie ? "Có" : "Không",
				note: order.other_services,
				differentContact: order.company
			};
		});
		yield put({
			type: types.GET_LIST_SUCCESS,
			payload: { listRequest: orders, totalPages: response.total_pages }
		});
	} catch (error) {
		yield put({
			type: types.GET_LIST_FAILURE,
			payload: {
				errorMessage: error.message
			}
		});
	}
}

function* deleteRequest(action) {
	try {
		const response = yield DeletePriceRequest(action.payload.dataAPI);
		yield put({
			type: types.DELETE_REQUEST_SUCCESS,
			payload: {
				errorMessage: response
			}
		});
		let offset = action.payload.activePage
		if (action.payload.length === 1 && action.payload.totalPage > 1) {
			offset -= 1
		}
		if (action.payload.textSearch) {
			yield put({
				type: types.SEARCH_LIST_REQUEST,
				payload: {
					page: offset,
					textSearch: action.payload.textSearch
				}
			})
		} else {
			yield put({
				type: types.GET_LIST_REQUEST,
				payload: offset
			})
		}
	} catch (error) {
		yield put({
			type: types.DELETE_REQUEST_FAILURE,
			payload: {
				errorMessage: error.message
			}
		})
	}
}

function* searchData(action) {
	try {
		const response = yield searchListRequest(action.payload.textSearch, action.payload.page);
		let orders = response.orders.map(order => {
			let dt = new Date(order.exp_arr_date);
			return {
				id: order._id,
				name: order.full_name,
				tel: order.tel,
				email: order.email,
				date_begin: `${dt.getDate()}/${dt.getMonth() + 1}/${dt.getFullYear()}`,
				location: order.schedule,
				num: order.numb_of_people,
				localGuide: order.has_tour_guide ? "Có" : "Không",
				vietNamGuide: order.can_speak_vie ? "Có" : "Không",
				note: order.other_services,
				differentContact: order.company
			};
		});
		yield put({
			type: types.SEARCH_LIST_SUCCESS,
			payload: { listRequest: orders, totalPages: response.total_pages }
		})
	} catch (error) {
		yield put({
			type: types.SEARCH_LIST_FAILURE,
			payload: {
				errorMessage: error.message
			}
		})
	}
}

export const RequestSaga = [
	takeEvery(types.GET_LIST_REQUEST, getListRequest),
	takeEvery(types.DELETE_REQUEST_REQUEST, deleteRequest),
	takeEvery(types.SEARCH_LIST_REQUEST, searchData),
];