import {domain} from '../../../constant'
export default function callApi(topic){
    return new Promise((resolve,reject)=>{
        const url = domain+"/add-us-beauty-post-type"
        fetch(url,{
            method: "POST",
            headers: { "Content-type": "application/json" },
            body: JSON.stringify(topic)
        })
        .then((response) => response.json())
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
    })
})
}