import { put, takeEvery } from 'redux-saga/effects';
import getContactRequest from '../fetchAPI/ContactAPI/GetContact';
import DeleteContact from "../fetchAPI/ContactAPI/DeleteContact"
import searchListContact from "../fetchAPI/ContactAPI/SearchContacts"
import * as types from '../constant';

function* getlistContact(action) {
  try {
    // Call API
    const responseData = yield getContactRequest(action.payload);

    // Convert fields
    let contacts = responseData.contacts.map(contact => {
      return {
        id: contact._id,
        name: contact.full_name,
        email: contact.email,
        differentContact: contact.other_contact,
        note: contact.description,
        tel: contact.tel
      }
    });

    yield put({
      type: types.GET_LIST_CONTACT_SUCCESS,
      payload: {
        totalPages: responseData.total_pages,
        contacts: contacts,
        // httpStatus: response.status // Set HTTP status to json object
      }
    });
  } catch (error) {
    yield put({
      type: types.GET_LIST_CONTACT_FAILURE,
      payload: {
        errorMessage: error.message
      }
    });
  }
}

function* deleteContact(action) {
  try {
    const responseData = yield DeleteContact(action.payload.dataAPI);
    // Convert response object to json data
    // let responseData = yield response.json();

    yield put({
      type: types.DELETE_CONTACT_SUCCESS,
      payload: {
        errorMessage: responseData,
        // httpStatus: response.status
      }
    })
    let offset = action.payload.activePage
    if(action.payload.length === 1 && action.payload.totalPage > 1){
      offset -= 1
    }
    if (action.payload.textSearch) {
      yield put({
        type: types.SEARCH_CONTACT_REQUEST,
        payload: {
          page: offset,
          textSearch: action.payload.textSearch
        }
      })
    } else {
      yield put({
        type: types.GET_LIST_CONTACT_REQUEST,
        payload: offset
      })
    }
  } catch (error) {
    yield put({
      type: types.DELETE_CONTACT_FAILURE,
      payload: {
        errorMessage: error.message
      }
    })
  }
}

function* searchContact(action) {
  try {
    const responseData = yield searchListContact(action.payload.textSearch, action.payload.page);
    // Convert response object to json data
    // let responseData = yield response.json();
    let contacts = responseData.contacts.map(contact => {
      return {
        id: contact._id,
        name: contact.full_name,
        email: contact.email,
        differentContact: contact.other_contact,
        note: contact.description,
        tel: contact.tel
      }
    })
    yield put(
      {
        type: types.SEARCH_CONTACT_SUCCESS,
        payload: { contacts: contacts, totalPages: responseData.total_pages }
      })
  } catch (error) {
    yield put({
      type: types.SEARCH_CONTACT_FAILURE,
      payload: {
        errorMessage: error.message
      }
    })
  }
}
export const ContactSaga = [
  takeEvery(types.GET_LIST_CONTACT_REQUEST, getlistContact),
  takeEvery(types.DELETE_CONTACT_REQUEST, deleteContact),
  takeEvery(types.SEARCH_CONTACT_REQUEST, searchContact),

]
