import React from 'react';
import HomePageContainer from '../containers/HomePageContainer';

class HomePage extends React.Component {
  render() {
    return (
      <div className="HomePage">
        <div className="card">
          <div className="card-header"> <h1>Trang Chủ</h1></div>
          <div className="card-body">
            <HomePageContainer />
          </div>
        </div>
      </div>
    );
  }
}

export default HomePage;