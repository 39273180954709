import * as types from '../constant'

const DEFAULT_STATE = {
    showModal: false,
    content: "",
    urlCoverImg: ""
}

export default (state = DEFAULT_STATE, action) => {
    switch (action.type) {
        case types.UPLOAD_IMAGE_REQUEST:
            return { ...state, urlCoverImg: "" }
        case types.SHOW_NOTIFY_MODAL:
        case types.UPLOAD_IMAGE_FAILURE:
            return { ...state, showModal: true, content: action.payload };
        case types.TURN_OFF_NOTIFY_MODAL:
            return { ...state, showModal: false, content: "" };
        case types.UPLOAD_IMAGE_SUCCESS:
            return {...state, urlCoverImg: action.payload};
        default:
            return state;
    }
}