import {domain, POST_ITEMS_PER_PAGE} from '../../constant'

export default function callApi(activePage) {
    return new Promise((resolve, reject) => {
      const url = `${domain}/get-us-beauty/${POST_ITEMS_PER_PAGE}/${activePage}`;
      fetch(url, {
        method: 'GET'
      })
        .then((response) => response.json())
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }