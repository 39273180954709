import React, { Component } from 'react';
import AddTopicComponent from './AddTopicComponent'
import LoadingModal from '../commons/LoadingModal'
import { Table } from 'react-bootstrap'

class TourTopic extends Component {
    constructor(props) {
        super(props);
        this.state = {
            topicName: ''
        };
    }
    addTopic(data) {
        this.props.addTopic(data)
    }
    editTopic(data) {
        if (this.state.topicName !== '' && data !== this.state.topicName) {
            this.props.editTopic({
                curr_type_name: data,
                new_type_name: this.state.topicName.trim()
            })
        }
    }
    deleteTopic(data) {
        this.props.deleteTopic(data)
    }
    render() {

        let listTopic = [];
        if (this.props.topics) {
            listTopic = this.props.topics.map((item, key) => {
                let edit = "edit" + key
                let deleteData = "delete" + key
                return (
                    <tr className="colum2" key={key}>
                        <td>{key + 1}</td>
                        <td>
                            <div>
                                {item.name + " ("}
                                <span style={{fontWeight: "bold", color: "red"}}>
                                    {item.count}
                                </span>
                                {" tour)"}
                            </div>
                        </td>
                        <td className="center-actions-td">
                            <button type="button" className="btn btn-primary"
                                data-toggle="modal" data-target={"#" + edit}
                                onClick={() => {
                                    this.setState({
                                        topicName: item.name
                                    })
                                }}>Sửa</button>
                            <div className="modal fade" id={edit} role="dialog">
                                <div className="modal-dialog">
                                    {/* Modal content*/}
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h3 className="modal-title text-center" style={{ color: "#007bff", width: "100%" }} >Sửa Tên Chủ Đề</h3>
                                            <button type="button" className="close" data-dismiss="modal">×</button>
                                        </div>
                                        <div className="modal-body">
                                            <div>
                                                <label className="name-label" >Chủ Đề:</label>
                                                <input type="text" value={this.state.topicName} className="form1" placeholder="Tên Chủ Đề"
                                                    onChange={(e) => { this.setState({ topicName: e.target.value }) }} />
                                                <div className="clear" ></div>
                                            </div>
                                            <div className="modal-footer">
                                                <button type="button" className="btn btn-danger"
                                                    data-dismiss="modal">Hủy</button>
                                                <button type="button" className="btn btn-success"
                                                    style={{ marginRight: 5 }}
                                                    data-dismiss="modal"
                                                    onClick={() => this.editTopic(item.name)}>Xác nhận</button>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <button type="button" className="btn btn-danger" data-toggle="modal"
                                data-target={"#" + deleteData} style={{ marginLeft: 5 }}>Xóa</button>
                            <div className="modal fade" id={deleteData} role="dialog">
                                <div className="modal-dialog modal-sm">
                                    <div className="modal-content">
                                        <div className="modal-header" style={{ color: "red" }}>

                                            <h4 className="modal-title" style={{ marginLeft: "30px" }} >
                                                Thao tác này sẽ xóa những tour có chủ đề này.
                                            Bạn có chắc chắn muốn xóa không ?</h4>
                                            <button type="button" className="close" data-dismiss="modal">×</button>
                                        </div>
                                        <div className="modal-footer">

                                            <button type="button" className="btn btn-danger"
                                                data-dismiss="modal" >Hủy</button>
                                            <button type="button" className="btn btn-success" data-dismiss="modal"
                                                onClick={() => this.deleteTopic(item.name)}  >Xác nhận</button>
                                        </div>
                                        <div className="modal-footer"></div>
                                    </div>
                                </div>
                            </div>
                        </td>
                    </tr>
                )
            })
        }

        return (
            <div className="table-responsive wrap-tour-schedule">

                {this.props.isLoadingTopic ? <LoadingModal /> :
                    <div>
                        <AddTopicComponent addTopic={(data) => {
                            this.addTopic(data)
                        }} />
                        <Table striped bordered hover style={{ marginTop: 5 }}>
                            <thead>
                                <tr className="table-header-row">
                                    <th>STT</th>
                                    <th>Tên Chủ Đề</th>
                                    <th>Thao tác</th>
                                </tr>
                            </thead>
                            <tbody>
                                {listTopic}
                            </tbody>
                        </Table>
                    </div>
                }
            </div>
        );
    }
}

export default TourTopic;