import React, { Component } from "react"
import { Modal, Button } from 'react-bootstrap';

export default class NotifyModal extends Component {

    closeModal() {
        this.props.turnOffModal();
    }

    render() {
        return (
			<div>
                <Modal
                    show={this.props.showModal}
                    onHide={() => this.closeModal()}
                    size="lg"
                    aria-labelledby="example-custom-modal-styling-title">
                    <Modal.Header closeButton>
                        <Modal.Title 
                            id="example-custom-modal-styling-title" 
                            style={{textAlign:"center",width:"100%"}}>
                            Thông báo
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ margin: "0 auto" }}>
                        <h5>{this.props.content}</h5>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="danger" onClick={() => this.closeModal()}>Đóng</Button>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }
}