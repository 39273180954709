import {domain} from '../../constant'
export default function copyTour(id) {

    return new Promise((resolve, reject) => {
        const url = domain +'/copy-tour/' + id
        fetch(url, {
            method: "GET",
        })
            .then((response) => response.json())
            .then((res) => {
                resolve(res);
            })
            .catch((error) => {
                reject(error);
            });
    });
}
