import React from 'react'
import UsaInfor from '../components/UsaInforComponent/UsaInforComponent'
import Search from '../components/UsaInforComponent/searchUsaInfor'
import * as actions from '../actions/UsaInforActions'
import { connect } from 'react-redux'
import PaginationCommon from '../components/commons/PaginationCommon'

class UsaInforContainer extends React.Component {

  componentDidMount() {
    this.props.onPaginate(1);
  }

  render() {
    let flagSearch = false;
    if (this.props.textSearch !== '') {
      flagSearch = true
    }
    return (
      <div>
        <Search {...this.props} />
        <UsaInfor {...this.props} />
        {/* Separate table and pagination component */}
        <div style={{marginTop: 10}}></div>
        <PaginationCommon activePage={this.props.activePage} totalPage={this.props.totalPage} onPaginate={(data) => {
          if (flagSearch === false) {
            this.props.onPaginate(data)
          } else {
            this.props.searchUsaInfor(this.props.textSearch, data)
          }
        }} />

      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    dataContainer: state.usaInfor.listUsaInfor,
    //Store save activePag
    activePage: state.usaInfor.activePageUsaInfor,
    textSearch: state.usaInfor.textSearch,
    totalPage: state.usaInfor.totalPage,
    isLoadingData:  state.usaInfor.isFetching
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onPaginate: (data) => {
      dispatch(actions.getListUsaInfor(data))
    },
    addUsaInfor: (dataAdd) => {
      dispatch(actions.addUsaInfor(dataAdd))
    },

    editUsaInfor: (dataEdit) => {
      dispatch(actions.editUsaInfor(dataEdit))
    },
    deleteUsaInfor: (dataDelete) => {
      dispatch(actions.deleteUsaInfor(dataDelete))
    },
    searchUsaInfor: (inputValue, offset) => {
      dispatch(actions.searchUsaInfor({
        offset: offset,
        text: inputValue
      }))
    },
    refresh: (data) => {
      dispatch(actions.refresh(data))
    }
  };
};


export default connect(mapStateToProps, mapDispatchToProps)(UsaInforContainer);