import * as types from '../../constant'
export function getTerm(payload){
    return({
        type:types.GET_TERMS_REQUEST,
        payload
    })
}
export function addTerm(payload){
    return({
        type:types.ADD_TERMS_REQUEST,
        payload
    })
}
export function updateTerm(payload){
    return({
        type: types.UPDATE_TERMS_REQUEST,
        payload
    })
}
export function deleteTerm(payload){
    return({
        type:types.DELETE_TERMS_REQUEST,
        payload
    })
}
